import { Component, Inject, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Config } from 'src/app/config/config';
import { SessionService } from 'src/app/services/session-service.service';
import { head } from 'underscore';
@Component({
  selector: 'app-ranking-question-type1',
  templateUrl: './ranking-question-type1.component.html',
  styleUrls: ['./ranking-question-type1.component.css']
})
export class RankingQuestionType1Component implements OnInit, OnDestroy {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public duration_time;
  public todo = [];
  public done = [];
  public response = [];
  constructor(
    private _session: SessionService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
    const duration = this._session.api.local.get(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL);
    if (this.document.getElementById('nextBtn')) {
      this.document.getElementById('nextBtn').disabled = true;
        this.duration_time = setTimeout(function () {
          this.document.getElementById('nextBtn').disabled = false;
        }, duration * 1000);
    }
    console.log('Type 1 and drag')
    this.todo = Object.assign([], this.questionDetails.Options.rows);;
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      this.mappingResponse();
    } else {
      this.response = this.response ? this.response : [];
      this.dataEmitter();
    }
  }

  /**
   * Emit back the response to live survey to get saved
   */
  dataEmitter() {
    const data = {"res": this.response, "id": this.questionDetails.questionId}
    this.emitData.emit(data);
  }

  /**
   * Triggered on drop of an option
   * @param event dropped event
   */
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.prepareRespData();
  }

  /**
   * Triggered on click of the option
   * @param value - option value
   * @param ind - option index
   */
  clickRanking(value, ind) {
    this.done.push(this.todo[ind]);
    this.todo.splice(ind, 1);
    this.prepareRespData();
  }
  prepareRespData() {
    const selected_header = this.questionDetails.Options.columns[2]['columnId'];
    this.response = []
    let respInd;
    this.done.forEach((el, Ind) => {
      //   respInd = this.response.findIndex((ele) => ele['selected_row'] == el[0]['value'] && ele['selected_header'] == selected_header);
      //   if (respInd > -1) {
      //     this.response[respInd]['selected_value'] = Ind+1;
      //   } else {
      //     this.response[respInd]['selected_value'] = null;
      //   }
      this.response.push({ selected_item: el[1]['value'], option_type: 'Ranking', selected_header: selected_header, selected_row: el[0]['value'], selected_value: Ind + 1 })
    });
    this.dataEmitter();
  }

  /**
* To create response object for all the existing rows in live survey
*/
  createResponseJson() {
    this.response = [];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId']) {
          this.response.push({ selected_value: '', selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: row[colInd]['value'] })
        }
      });
    });
    this.dataEmitter();
  }

  mappingResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row'] && resp['selected_value']) {
          this.done[resp['selected_value'] - 1] = row;
          this.todo.splice(this.todo.findIndex(el => el[0]['value'] == row[0]['value']), 1)
        }
      });
    });
  }

  /**
   * To find selected item
   * @param row - row 
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.duration_time)
  }

}