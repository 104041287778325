import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})

export class LayoutSharedService {
  private sideNavSubject = new Subject<any>();
  public sideNavInitialization = new Subject<any>();

  private isSideNavCollapse: boolean;

  private waveSubject = new Subject<any>();
  private routeSubject = new Subject<any>();
  private routeString = '';
  private refreshReports: any[] = [];
  private refreshReportsSub = new Subject<string>();

  /** List of pages which have actions in progress */
  private pagesActionInProgress: string[] = [];
  /** Actions-in-progress Subject */
  private actionInProgress = new Subject<string[]>();

  /** Action-in-progress reference object */
  private refreshActionInProgress = new Subject<string>();

  /** Report progress project details */
  private reportProgressProject = new Subject<string>();
  private reportProjectMetadata: object;

  /**
   * Set side nav collapse value
   */
  setCollapseValue(value: boolean) {
    this.isSideNavCollapse = value;
    this.sideNavSubject.next(value);
  }

  /**
   * Get side nav collapse value
   */
  getCollapseValue(): Observable<any> {
    return this.sideNavSubject.asObservable();
  }

  /**
   * Toggle side nav collapse value
   */
  toggleSideMenu() {
    this.isSideNavCollapse = !this.isSideNavCollapse;
    this.sideNavSubject.next(this.isSideNavCollapse);
  }

  /**
   * Set selected wave metadata
   * @param wave wave metadata
   */
  setWaveInfo(wave) {
    this.waveSubject.next(wave);
  }

  /**
   * Get selected wave metadata
   */
  getWaveInfo() {
    return this.waveSubject.asObservable();
  }

  /**
   * Set current page route
   * @param route page route
   */
  setCurrentRoute(route) {
    this.routeSubject.next(route);
    this.routeString = route;
  }

  /**
   * Get current page route
   */
  getCurrentRoute() {
    return this.routeSubject.asObservable();
  }

  /**
   * Get current route as string
   */
  getRouteString() {
    return this.routeString;
  }

  /**
   * Add project details to refresh chart array
   * @param project project metadata
   */
  setRefreshChart(project: object) {
    if (this.checkRefreshChart(project) === -1) {
      this.refreshReports.push(project);
      this.refreshReportsSub.next();
    }
  }

  /**
   * Remove project from refresh chart
   * @param project project metadata
   */
  removeRefreshChart(project: object) {
    const index = this.checkRefreshChart(project);
    if (index >= 0) {
      this.refreshReports.splice(index, 1);
    }
  }

  /**
   * Return the index if the project exist; otherwise return -1.
   * @param project project metadata
   */
  checkRefreshChart(project: object): number {
    let foundIndex = -1, index = 0;
    for (const el of this.refreshReports) {
      if (el['surveyKey'] === project['surveyKey']) {
        foundIndex = index;
        break;
      }
      index++;
    }
    return foundIndex;
  }

  /**
   * Get refresh chart as observable
   */
  getRefreshChart() {
    return this.refreshReportsSub.asObservable();
  }

  /**
   * Insert string data to Clipboard
   * @param data data which need to be copied
   */
  setCopyContent(data: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /**
   * Copy windows copy clipboard content
   */
  getCopiedContent() {
    return new Promise((resolve, reject) => {
      navigator['clipboard'].readText()
      .then(text => {
        const result = [];
        text.split('\n').forEach(row => {
          const rowArray = row.split('\t');
          let lastEl = rowArray.pop();
          if (lastEl.charCodeAt(lastEl.length - 1) === 13) {
            lastEl = lastEl.slice(0, -1);
          }
          rowArray.push(lastEl);
          result.push(rowArray);
        });
        /** Remove additional new-line character from the last row */
        if (!result[result.length - 1][0]) {
          result.pop();
        }
        resolve(result);
      })
      .catch(err => {
        reject();
        console.error('Failed to read clipboard contents: ', err);
      });
    });
  }


  /**
   * Add a single page into action page list
   * @param page - page name
   */
  pushPageToAction(page: string) {
    let isUpdated = false;
    isUpdated = !this.pagesActionInProgress.includes(page);
    if (isUpdated) {
      this.pagesActionInProgress.push(page);
      /** Trigger only if any pages got added or removed */
      this.actionInProgress.next();
    }
  }
  

  /**
   * Get refresh progress reference
   */
  getRefreshProgressFlag() {
    return this.refreshActionInProgress.asObservable();
  }

  /**
   * Fetch and actions-in-progress and refresh
   */
  refreshProgressItems() {
    this.refreshActionInProgress.next();
  }
  
  /**
   * Get(subscribe) metadata of the project which report export is in-progress
   */
  getReportProgressProject() {
    return this.reportProgressProject.asObservable();
  }

  /**
   * Get the project metadata of the report progress project
   */
  getReportProjectMetadata() {
    return this.reportProjectMetadata;
  }

  setSidenav(data){
    this.sideNavInitialization.next(data);
  }

  getSidenav(){
    return this.sideNavInitialization.asObservable();
  }
}
