import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionService } from '../../services/session-service.service';

@Injectable()
export class Logout implements CanActivate {
    constructor(
        private _session: SessionService,
        private _router: Router
    ) {}
    canActivate() {
        this._session.api.local.clear();
        this._session.api.session.clear();
        this._router.navigate(['login']);
        return true;
    }
}
