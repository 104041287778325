import { Component, OnInit } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Survey Automation Tool';
  constructor() { }
  // constructor(public translate: TranslateService) {
  //   translate.addLangs(['en', 'nl']);
  //   translate.setDefaultLang('en');
  // }

  ngOnInit() { }
}
