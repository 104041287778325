import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import { NzNotificationService } from 'ng-zorro-antd';
import { Config } from '../config/config';
import { SessionService } from './session-service.service';
import { LoaderService } from '../shared/loader/loader-service.service';
import { LayoutSharedService } from '../layout/layout-shared.service';

@Injectable()
export class HttpLayerService {
  private monitoring = {
    pendingRequestsNumber: 0
  };
  private userSession: object;
  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private loaderService: LoaderService,
    private _layoutService: LayoutSharedService,
    private notification: NzNotificationService
  ) { }

  /**
   * Fetch user session from browser local session
   */
  getUserSession() {
    try {
      this.userSession = this._session.api.local.get(Config.CONSTANTS.USER_SESSION);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Http get method
   * @param url Service URL
   * @param loader Loader should be shown or not
   */
  get(url: string, loader: boolean = true): Observable<any> {
    this.getUserSession();
    try {
      if (loader) {
        this.showLoader();
      }
      return this.handleResponse(this._http.get(url)).finally(() => {
        if (loader) {
          this.monitoring.pendingRequestsNumber--;
          this.hideLoader();
        }
      });
    } catch (error) {
      this.notification.error('Service Not Found', '');
      console.log(error);
    }
  }

  /**
   * Http post method
   * @param url Service URL
   * @param data Input data
   * @param loader Loader should be shown or not
   */
  post(url: string, data: any, loader: boolean = true): Observable<any> {
    this.getUserSession();
    const headerOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'})
    };
    try {
      if (loader) {
        this.showLoader();
      }
      const inputData = {};
      inputData['data'] = data;
      if(this.userSession) {
        inputData['userId'] = this.userSession[Config.CONSTANTS.USER_IDENTIFIER];
        inputData['userName'] = this.userSession[Config.CONSTANTS.USER_NAME];
        inputData['userRole'] = this.userSession[Config.CONSTANTS.USER_ROLE];
        inputData['toolVersion'] = Config.CONSTANTS.VERSION;
      }
      const body = JSON.stringify(inputData);
      return this.handleResponse(this._http.post(url, body, headerOptions)).finally(() => {
        if (loader) {
          this.monitoring.pendingRequestsNumber--;
          this.hideLoader();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Http post method with multi-part form data
   * @param url Service URL
   * @param formData Input form data
   * @param data Other input data
   * @param loader Loader should be shown or not
   */
  postFile(url: string, formData: FormData, data: object, loader: boolean = true): Observable<any> {
    this.getUserSession();
    /**
     * With header 'Content-Type': 'multipart/form-data' file was not getting in BE
     */
    const headerOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    try {
      if (loader) {
        this.showLoader();
      }
      formData.append('data', JSON.stringify(data));
      formData.append('userId', this.userSession[Config.CONSTANTS.USER_IDENTIFIER]);
      formData.append('userName', this.userSession[Config.CONSTANTS.USER_NAME]);
      formData.append('userRole', this.userSession[Config.CONSTANTS.USER_ROLE]);
      formData.append('toolVersion', Config.CONSTANTS.VERSION);
      return this.handleResponse(this._http.post(url, formData, headerOptions)).finally(() => {
        if (loader) {
          this.monitoring.pendingRequestsNumber--;
          this.hideLoader();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handle http response
   * @param observable http response as Observable
   */
  handleResponse(observable: Observable<any>): Observable<any> {
    return observable.catch((err, source) => {
        this.notification.error('Service Not Found', err.message);
        return throwError(err);
    });
  }

  /**
   * Show loader that block the page
   * @param isService Does this for a service?
   */
  showLoader(isService = true): void {
    if (isService) {
      this.monitoring.pendingRequestsNumber++;
    }
    this.loaderService.show();
  }

  /**
   * Hide the loader
   */
  hideLoader(): void {
    let reportProgress, currentProject, reportProject, route;
    currentProject = this._session.api.local.get(Config.CONSTANTS.SELECTED_PROJECT);
    reportProject = this._layoutService.getReportProjectMetadata();
    route = this._layoutService.getRouteString();

    if (currentProject && reportProject && currentProject['surveyKey'] === reportProject['surveyKey'] && currentProject['version'] === reportProject['version'] && !['summary', 'versionSummary'].includes(route)) {
      reportProgress = true;
    }

    if (this.monitoring.pendingRequestsNumber === 0 && !reportProgress) {
      this.loaderService.hide();
    }
  }
}
