import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './shared';
import { SessionService } from './services/session-service.service';
import { HttpLayerService } from './services/http-layer.service';
import { LoaderCommonComponent } from './shared/loader/loader-common.component';
import { LoaderService } from './shared/loader/loader-service.service';
import { CommonServices } from './services/common-services.service';
import { LiveSurveyModule } from './live-survey/live-survey.module';
import { DateFormatPipe } from './shared/date-format/date-format.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(en);


// AOT compilation support
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderCommonComponent,
    DateFormatPipe
  ],
  imports: [ 
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    LiveSurveyModule,
    DragDropModule,
    NgxSliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [LoaderCommonComponent],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    AuthGuard,
    SessionService,
    HttpLayerService,
    CommonServices,
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
