import { Component, Inject, Input, OnInit, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { QuestionsPreviewService } from '../questions-preview.service';
import { SessionService } from 'src/app/services/session-service.service';
import { Config } from 'src/app/config/config';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-ranking-question-type2',
  templateUrl: './ranking-question-type2.component.html',
  styleUrls: ['./ranking-question-type2.component.css']
})
export class RankingQuestionType2Component implements OnInit,OnChanges, OnDestroy {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public duration_time;
  public specifyText='';
  public todo = [];
  public rankingOrder = [];
  public done = [];
  public response = [];
  public optionSelected = [];
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _session: SessionService,
    public commonQstnService: QuestionsPreviewService,
    private notification: NzNotificationService,
  ) { }


  ngOnChanges() {
    const duration = this._session.api.local.get(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL);
    if (this.document.getElementById('nextBtn')) {
      this.document.getElementById('nextBtn').disabled = true;
        this.duration_time = setTimeout(function () {
          this.document.getElementById('nextBtn').disabled = false;
        }, duration * 1000);
    }
    this.todo = this.questionDetails.Options.rows;
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.optionSelected[el['selected_row']] = true;
        } else {
          this.mappingResponse();
        }
      });
    } else {
      this.createResponseJson();
    }
  }

  ngOnInit() {
    const duration = this._session.api.local.get(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL);
    if (this.document.getElementById('nextBtn')) {
      this.document.getElementById('nextBtn').disabled = true;
        this.duration_time = setTimeout(function () {
          this.document.getElementById('nextBtn').disabled = false;
        }, duration * 1000);
    }
    this.todo = this.questionDetails.Options.rows;
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.optionSelected[el['selected_row']] = true;
        } else {
          this.mappingResponse();
        }
      });
    } else {
      this.createResponseJson();
    }
  }

  dataEmitter() {
    const data = {"res": this.response, "id": this.questionDetails.questionId}
    this.emitData.emit(data);
  }

  rankingType2(item, ind, optionId) {
    if (item && (item[2]['value'] && item[2]['value'] == 'Single choice') || item[2].disable) {
      return;
    }
    if (!item || this.rankingOrder[ind]) {
      return;
    }
    let maxVal;
    /**Find max value in ranking */
    if (this.rankingOrder.length <= 0) {
      maxVal = 1
    } else {
      /**Find the first missing value in an array */
      let arrayCount = this.questionDetails.Options.rows.length, missingArray = [];
      if (this.questionDetails['questionType'] == 'Combination') {
        arrayCount = arrayCount - 1;
      }
      for (var i = 1; i <= arrayCount; i++) {
        if (this.rankingOrder.indexOf(i) == -1) {
          missingArray.push(i);
        }
      }
      maxVal = missingArray[0];
    }
    /**Assign max value to the object if ranking is not present previously */
    if (!this.rankingOrder[ind] && maxVal <= this.questionDetails.Options.rows.length) {
      this.rankingOrder[ind] = maxVal;
    }
    this.changeInRanking(item, maxVal, ind, optionId);
  }

  mappingResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row'] && resp['option_type'] !== 'Single choice') {
          this.rankingOrder[rowInd] = resp['selected_value'];
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
      });
    });
  }

  changeInRanking(item, value, ind, optionId, enter?) {
    if (item && item[2]['value'] && item[2]['value'] == 'Single choice' || value == null || item[2].disable) {
      if (value == null || '') {
        this.response[ind]['selected_value'] = null;
      }
      return;
    }
    /** Negative and decimal values check */
    if (value && value <= 0 || !Number.isInteger(value)) {
      this.rankingOrder[ind] = ''
      this.notification.error('Error', 'Negative and decimal values are not allowed');
      return;
    }
    this.optionSelected = [];
    /** If the value is higher than num of rows - dont allow */
    if (enter && value > this.questionDetails.Options.rows.length) {
      this.rankingOrder[ind] = '';
      this.notification.warning('Warning', 'Entered rank value is greater than number of options provided ');
      return;
    }
    /**Find duplicates are present or not */
    const duplicateInd = this.rankingOrder.findIndex((el, elInd) => el == value && elInd != ind);
    if (value && duplicateInd > -1 && duplicateInd != ind) {
      this.notification.warning('Warning', 'Duplicate value found');
      this.rankingOrder[ind] = ''
      return;
    }
    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == item[0]['value']);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = value;
    }
    const otherspecify_Ind=this.response.findIndex((ele)=>ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
    if(otherspecify_Ind > -1 && this.specifyText){
      this.response[otherspecify_Ind]['otherSpecify_value']=this.specifyText;
      this.response[otherspecify_Ind]['other_specify']=true;
    }else if(otherspecify_Ind > -1 && !this.specifyText){
      this.response[otherspecify_Ind]['other_specify']=false;
    }
    this.dataEmitter();
  }
  chooseNone(rowIndex, OptionId, item, eachTd) {

    if(item && item[2]['value'] !== 'Single choice' || eachTd.disable) { return }

    this.optionSelected = [];
    this.optionSelected[OptionId] = true;
    this.createResponseJson();
    this.rankingOrder = [];
    const Ind = this.response.findIndex((ele) => ele['option_type'] == 'Single choice' && ele['selected_row'] == OptionId);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
    }
    this.dataEmitter();
  }

  /**
 * To create response object for all the existing rows in live survey
 */
  createResponseJson() {
    this.response = [];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId']) {
          let selectedValue;
          if (row[colInd]['value'] == 'Single choice') {
            selectedValue = 0;
          }
          this.response.push({ selected_value: selectedValue || '', selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: row[colInd]['value'] })
        }
      });
    });
  }
  /**
* To find selected item
* @param row - row 
* @returns - selected item value
*/
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }

  changeText(){
    const otherspecify_Ind=this.response.findIndex((ele)=>ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
    if(otherspecify_Ind > -1 && this.response[otherspecify_Ind]['selected_value'] && this.specifyText){
      this.response[otherspecify_Ind]['otherSpecify_value']=this.specifyText;
      this.response[otherspecify_Ind]['other_specify']=true;
    }else if(!this.specifyText){
      this.response[otherspecify_Ind]['otherSpecify_value']=this.specifyText;
      this.response[otherspecify_Ind]['other_specify']=false;
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.duration_time);
  }

}
