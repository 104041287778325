import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session-service.service';
import { Config } from '../../config/config';
import { NzNotificationService } from 'ng-zorro-antd';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _session: SessionService,
    private notification: NzNotificationService
  ) { }

  canActivate() {
    let userSession;
    try {
      userSession = this._session.api.session.get(Config.CONSTANTS.USER_SESSION);
      if (userSession && userSession[Config.CONSTANTS.USER_IDENTIFIER]) {
        return true;
      }
    } catch (e) {
      this.notification.error('Invalid Session', 'Please login again');
      console.log(e);
    }
    this._session.api.local.clear();
    this._session.api.session.clear();
    this.router.navigate(['login']);
    return false;
  }
}
