import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ElementRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { SessionService } from 'src/app/services/session-service.service';
import { HttpLayerService } from 'src/app/services/http-layer.service';
import { Config } from 'src/app/config/config';
@Component({
  selector: 'app-questions-preview',
  templateUrl: './questions-preview.component.html',
  styleUrls: ['./questions-preview.component.css']
})
export class QuestionsPreviewComponent implements OnInit, OnChanges, AfterViewChecked {
  public SINGLE_SELECT = 'Single choice';
  public MULTI_SELECT = 'Select all that apply';
  public COMBINATION = 'Combination';
  public isError = false;
  public errorData: any;
  public imageTable;
  public image_name;
  public BASE_INPUT;
  public height_px;
  public width_px;
  public imageTitle;
  public myImage;
  public atmostEnable = undefined;
  public atleastEnable = undefined;
  public chooseEnable = undefined;
  public imgWidth;
  public imgHeight;
  public isVisibleImagePopup = false;
  @Input() isLiveMessage: boolean;
  @Input() messageQuestions: any;
  @Input() popUpContent: any;
  @Input() isVisible: any;
  @Input() questionDetails: any;
  @Input() errorMessage: any;
  @Input() messageScreenText: any;
  @Output('onDataEmit') dataToParent = new EventEmitter();
  @Output('onDataToLiveSurvey') dataToQuestionPreview = new EventEmitter();
  @Output('isOkOrCancel') isOkOrCancel = new EventEmitter();
  clickedInsideThePage = false;
  messageQuestionIndex: any;
  messageQuestionTotalCount: any;
  messageQuestionBreakCount: any;
  @Input() multipleErrorMessage: any;
  @Input() index: any;
  public image_details: any = {};
  constructor(private _session: SessionService,
    private elementRef: ElementRef,
    private httpLayer: HttpLayerService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('quest-->', this.questionDetails);
    setTimeout(() => {
      this.atmostEnable = this._session.api.local.get('ATMOSTENABLE');
      this.atleastEnable = this._session.api.local.get('ATLEASTENABLE');
      this.chooseEnable = this._session.api.local.get('CHOOSEENABLE');
    }, 100);
    if (this.index != null || this.index !== undefined) {
      if (changes['multipleErrorMessage'] && this.index > -1 && (this.multipleErrorMessage.length > 0) && (this.multipleErrorMessage.find(el => el.id === this.questionDetails.questionId) && this.questionDetails.questionId == this.multipleErrorMessage.find(el => el.id === this.questionDetails.questionId).id)) {
        this.isError = true;
        this.errorData = this.multipleErrorMessage.find(el => el.id === this.questionDetails.questionId).msg;
      }
    } else if (changes['errorMessage']) {
      this.isError = true;
      this.errorData = this.errorMessage;
    }

  }

  ngOnInit() {
    this.BASE_INPUT = JSON.stringify(
      this._session.api.local.get(Config.CONSTANTS.SELECTED_PROJECT)
    );
    if (this.messageQuestions) {
      this.messageQuestionIndex = this.messageQuestions.current_message_index + 1;
      this.messageQuestionTotalCount = this.messageQuestions.total_message_count;
      this.messageQuestionBreakCount = this.messageQuestions.break_count && (this.messageQuestions.break_count == 0 || this.messageQuestions.break_count > this.messageQuestions.total_message_count) ? this.messageQuestions.total_message_count : this.messageQuestions.break_count;
      if (this.messageQuestionIndex > this.messageQuestionBreakCount && this.messageQuestionBreakCount < this.messageQuestionTotalCount) {
        this.messageQuestionIndex = this.messageQuestionIndex - this.messageQuestionBreakCount;
        this.messageQuestionBreakCount = this.messageQuestionTotalCount - this.messageQuestionBreakCount;
      }

    }

  }

  imagePopup(data) {
    if (Object.keys(data.target.dataset).length <= 0) {
      if (this.questionDetails.image_data.length > 0) {
        const index = this.questionDetails.image_data.findIndex(el => el.image_name == data.target.text);
        if (index > -1) {
          const imageType = this.questionDetails.image_data[index].file_name.indexOf('.jpeg');
          const url = imageType > -1 ? this.questionDetails.image_data[index].base_url.replace('', 'data:image/jpeg;base64,') : this.questionDetails.image_data[index].base_url.replace('', 'data:image/png;base64,');
          data.target.dataset.linkurl = url;
          data.target.dataset.imgheight = this.questionDetails.image_data[index].height;
          data.target.dataset.imgwidth = this.questionDetails.image_data[index].width;
          data.target.dataset.imgtitle = this.questionDetails.image_data[index].image_name;
        }
      }
    }
    if (Object.keys(this.image_details).length > 0) {
      return;
    } else {
      if (data.target.dataset.linkurl) {
        this.image_details['linkUrl'] = data.target.dataset.linkurl;
        this.image_details['imgHeight'] = data.target.dataset.imgheight ? data.target.dataset.imgheight : 100;
        this.imgHeight = Number(this.image_details['imgHeight']);
        this.image_details['imgWidth'] = data.target.dataset.imgwidth ? data.target.dataset.imgwidth : 100;
        this.imgWidth = Number(this.image_details['imgWidth']);
        this.image_details['imgTitle'] = data.target.dataset.imgtitle;
        this.isVisibleImagePopup = true;
      }
    }
  }


  handleCancel(): void {
    this.isVisibleImagePopup = false;
    this.image_details = {};
  }



  ngAfterViewChecked() {
    if (this.elementRef.nativeElement.querySelector('.image-link-popup') && Object.keys(this.image_details).length == 0) {
      const query = this.elementRef.nativeElement.querySelectorAll('.image-link-popup');
      for (let i = 0; i < query.length; i++) {
        query[i].addEventListener('click', this.imagePopup.bind(this));
      }
    }
    // if (this.elementRef.nativeElement.querySelector('.image-link-popup') && Object.keys(this.image_details).length == 0) {
    //   this.elementRef.nativeElement.querySelector('.image-link-popup').addEventListener('click', this.imagePopup.bind(this));
    // }
    // if (document.querySelector('.image-link-popup')) {
    //   document.querySelector('.image-link-popup').addEventListener('click', this.imagePopup.bind(this));

    // }
  }

  /**
   * If clicks anywhere in the page, sending a var to date-question-component to handle date picker
   */
  clickedInside() {
    this.clickedInsideThePage = this.clickedInsideThePage == true ? false : true;
  }

  emitDataToParent(e) {
    if (this.index != null || undefined) {
      const resObj = {};
      if (e.id === this.questionDetails.questionId) {
        resObj[this.questionDetails.questionId] = e.res;
        this.dataToParent.emit(resObj);
      } else
        this.dataToParent.emit(e.id);
    } else {
      this.dataToParent.emit(e.res);
    }
  }

  questionBasedError(message) {
    this.errorData = message;
  }

  /**
   * Emit data to parent
   * @param message 
   */
  emitDataToQuestionPreview(message) {
    this.dataToQuestionPreview.emit(message)
  }

  /**
   * Fetch ok or cancel from message-popup and emiting it to live-survey
   * @param message 
   */
  emitIsOkOrCancelToPreview(message) {
    this.isOkOrCancel.emit(message)
  }



}
