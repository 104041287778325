import { Component, Inject, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { QuestionsPreviewService } from '../questions-preview.service';
import { SessionService } from '../../../services/session-service.service';
import { CommonServices } from 'src/app/services/common-services.service';
import { Config } from 'src/app/config/config';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-single-select-question',
  templateUrl: './single-select-question.component.html',
  styleUrls: ['./single-select-question.component.css']
})
export class SingleSelectQuestionComponent implements OnInit, OnDestroy {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public duration_time;
  public OTHER_OPTION = 'Other (please specify)'.toLowerCase();
  public OTHERS_OPTION = 'Others (please specify)'.toLowerCase();
  public specifyText = '';
  selectedOption;
  public response = [];
  public textBoxDisplay = false;
  otherSpecifyRowIndex: any;
  constructor(
    @Inject(DOCUMENT) private document: any,
    public commonQstnService: QuestionsPreviewService,
    private _session: SessionService,
    private _commonServices: CommonServices
  ) { }

  ngOnInit() {
    const duration = this._session.api.local.get(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL);
    if (this.document.getElementById('nextBtn')){
      this.document.getElementById('nextBtn').disabled = true;
        this.duration_time = setTimeout(function () {
          this.document.getElementById('nextBtn').disabled = false;
        }, duration * 1000);
    }
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      this.mappingResponse();
    } else {
      this.response = this.response ? this.response : [];
      this.dataEmitter();
    }
  }

  /**
    * Emit back the response to live survey to get saved
    */
  dataEmitter() {
    const data = { "res": this.response, "id": this.questionDetails.questionId }
    this.emitData.emit(data);
  }

  /**
   * Triggered on change on single select value
   * @param rowIndex - row index
   * @param i - index of single select row
   * @param eachRow - row valuie
   * @param value - value chosen
   */
  changeOption(i, eachTd, optionId, row, qnsId, specify?) {

    if (eachTd.valueType === 'Label' || eachTd.disable) { return }

    /** Radio button will select While clicking the td */
    this.questionDetails.Options.rows.forEach((resp, index) => {
      if (optionId == resp[0]['value']) {
        resp[i]['selectedValue'] = true;
      } else {
        resp[i]['selectedValue'] = false;
      }
    });

    const selectedItem = this.findSelectedItemIndex(row);
    const obj = {
      selected_item: selectedItem,
      selected_value: row[0]['value'],
      selected_row: row[0]['value'],
      option_type: 'Single choice',
      selected_header: this.questionDetails.Options.columns[i]['columnId']
    };

    /** Should not select same row - most & least */
    // this.response.forEach(resp => {
    //   if (obj['selected_row'] == resp['selected_row']) {
    //     eachTd['selectedValue'] = false;
    //   }
    // });
    const Ind = this.response.findIndex((ele) => ele['selected_header'] === obj['selected_header']);
    if (Ind > -1) {
      this.response.splice(Ind, 1);
    }
    // if ([this.OTHER_OPTION, this.OTHERS_OPTION].indexOf(selectedItem.toLowerCase()) > -1 || obj['selected_row'] == this.questionDetails['other_specify_index']) {
    if (selectedItem.match(this.commonQstnService.pleaseSpecifyMatch) || obj['selected_row'] == this.questionDetails['other_specify_index']) {
      obj['otherSpecify_value'] = this.specifyText;
      obj['other_specify'] = true;
    }
    //  else {
    //   this.specifyText = '';
    //   delete obj['otherSpecify_value'];
    //   delete obj['other_specify'];
    // }
    this.response.push(obj);
    this.dataEmitter();
  }

  changeText(i, eachTd, optionId, row, specify) {
    const selectedItem = this.findSelectedItemIndex(row);
    const Ind = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
    if (Ind > -1 && this.specifyText) {
      // this.response.forEach(element => {
      //   element['otherSpecify_value'] = this.specifyText;
      // });
      this.response[Ind]['otherSpecify_value'] = this.specifyText;
      // }
    }
    // this.dataEmitter();
  }

  /**
  * Mapping the responses if they are present already
  */
  mappingResponse() {
    this.questionDetails['response'].forEach(resp => {
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          row[colInd]['selectedValue'] = true;
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
      });
    });
  }

  /**
   * To find selected item
   * @param row - row
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }

  // checkRadio(row, index) {
  //   if (row.valueType !== 'Label') {
  //     console.log(row, index);
  //     row['selectedValue'] = true;
  //   } else {
  //     return;
  //   }
  // }

  ngOnDestroy(): void {
    clearTimeout(this.duration_time)
  }

}
