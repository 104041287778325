import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveSurveyComponent } from './live-survey.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { QuestionsPreviewModule } from '../shared/questions-preview/questions-preview.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SurveyHeaderComponent } from './survey-header/survey-header.component';
import { HeaderComponent } from '../layout/header/header.component';
import { SafeHtmlPipe } from './safehtml.pipe';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    QuestionsPreviewModule,
    NgZorroAntdModule,
    FormsModule,
    NgSelectModule,
    TranslateModule.forRoot()
  ],
  declarations: [LiveSurveyComponent, SurveyHeaderComponent, SafeHtmlPipe],
  exports: [LiveSurveyComponent]
})
export class LiveSurveyModule { }
