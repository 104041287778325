import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LiveSurveyComponent } from './live-survey/live-survey.component';
import { AuthGuard } from './shared';
import { Logout } from './shared/logout/logout';

const routes: Routes = [
    {
        path: '',
        loadChildren: './layout/layout.module#LayoutModule',
        canActivate: [AuthGuard]
    },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    {
        path: 'logout',
        loadChildren: './login/login.module#LoginModule',
        canActivate: [Logout],
    },
    {
        path: 'survey/live/:survey_uuid/:zs_uuid',
        component: LiveSurveyComponent
    },
    {
        path: 'survey/live/:actual_survey_key/:survey_uuid/:zs_uuid',
        component: LiveSurveyComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule],
    providers: [Logout]
})
export class AppRoutingModule { }
