import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutSharedService } from '../../layout/layout-shared.service';
import { HttpLayerService } from '../../services/http-layer.service';
import { Config } from '../../config/config';
import { SessionService } from '../../services/session-service.service';
import { ElectronService } from 'ngx-electron';
import { NzNotificationService } from 'ng-zorro-antd';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-survey-header',
  templateUrl: './survey-header.component.html',
  styleUrls: ['./survey-header.component.css']
})


export class SurveyHeaderComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

}