import { Injectable } from '@angular/core';

@Injectable()
export class CommonServices {

  constructor() { }

  /**
   * Deep copy of object
   * @param data Object which needs to be cloned (deep copy)
   */
  cloneObject(data: object): object {
    try {
      if (data) {
        return JSON.parse(JSON.stringify(data));
      }
    } catch (error) {
      console.warn(error);
      return {};
    }
  }

  /**
   * Deep copy of array
   * @param data array which needs to be cloned (deep copy)
   */
  cloneArray(data: any[]): any[] {
    try {
      if (data) {
        return JSON.parse(JSON.stringify(data));
      }
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  /**
   * Create numeric array with numbers
   * @param len length of array
   * @param start starting number
   */
  createNArray (len: number, start?: number): number[] {
    const array: number[] = [];
    if (start === undefined) {
      start = 0;
    }
    while (array.length < len) {
      array.push(start++);
    }
    return array;
  }

  /**
   * Get keys of an JSON object
   * @param obj Object of which the keys will be returned
   */
  getKeys(obj: object): string[] {
    let keys: string[] = [];
    try {
      keys = Object.keys(obj);
    } catch (e) {}
    return keys;
  }

  removeColumn(header,columnId){
    if (header.lastIndexOf(columnId) > 0) {
      return header.substring(0, header.lastIndexOf(columnId) - 1);
    }
  }
}
