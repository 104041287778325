import { Component, OnInit  } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { LoaderService } from './loader-service.service';
import { LoaderState } from './loader-state';
import { LayoutSharedService } from 'src/app/layout/layout-shared.service';
import { SessionService } from 'src/app/services/session-service.service';
import { Config } from '../../config/config';

@Component({
  selector: 'app-loader-common',
  templateUrl: './loader-common.component.html',
  styleUrls: ['./loader-common.component.scss']
})
export class LoaderCommonComponent implements OnInit {
  public show = false;
  public custom = false;
  public reportProject;

  constructor(
    private loaderService: LoaderService,
    private _session: SessionService,
    private _layoutService: LayoutSharedService
  ) { }

  ngOnInit() {
    this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
      this.show = state.show;
      const project = this.fetchProject();
      if (project && this.reportProject && project['surveyKey'] === this.reportProject['surveyKey'] && project['version'] === this.reportProject['version']) {
        this.custom = true;
      } else {
        this.custom = false;
      }
    });

    this.reportProject = this._layoutService.getReportProjectMetadata();
    this._layoutService.getReportProgressProject().subscribe(data => {
      this.reportProject = data;
    });
  }

  fetchProject() {
    try {
      return this._session.api.local.get(Config.CONSTANTS.SELECTED_PROJECT);
    } catch (e) {
      console.log(e);
      return {};
    }
  }

}
