import { Component, Inject, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { SessionService } from '../../../services/session-service.service';
import { Config } from '../../../config/config';
import { NzNotificationService } from 'ng-zorro-antd';
import { CommonServices } from 'src/app/services/common-services.service';
import { LiveSurveyComponent } from 'src/app/live-survey/live-survey.component';
import { QuestionsPreviewService } from '../questions-preview.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-percent-question',
  templateUrl: './percent-question.component.html',
  styleUrls: ['./percent-question.component.css']
})
export class PercentQuestionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  @Output('questionErrror') questionErrror = new EventEmitter<any>();

  public duration_time;
  public response = [];
  public storeValue = [];
  public selectNone;
  public optionSelected = [];
  public headerCol;
  public oldResponse = [];
  public specifyText = '';
  public OTHER_OPTION = 'Other (please specify)'.toLowerCase();
  public OTHERS_OPTION = 'Others (please specify)'.toLowerCase();
  constructor(
    @Inject(DOCUMENT) private document: any,
    public commonQstnService: QuestionsPreviewService,
    private _session: SessionService,
    private notification: NzNotificationService,
    private _commonServices: CommonServices,
    private questionsPreviewService: QuestionsPreviewService,
    // private livesurvey: LiveSurveyComponent,
    public translate: TranslateService,
    // private liveSurveyComponent: LiveSurveyComponent
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log('percentage ==>', this.questionDetails);
    const duration = this._session.api.local.get(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL);
    if (this.document.getElementById('nextBtn')) {
      this.document.getElementById('nextBtn').disabled = true;
        this.duration_time = setTimeout(function () {
          this.document.getElementById('nextBtn').disabled = false;
        }, duration * 1000);
    }
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        // tslint:disable-next-line: triple-equals
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.responseSingleSelectColumn(el)

          // this.optionSelected[el['selected_row']] = true;
        } else {
          this.mapResponse();
        }
      });
      this.dataEmitter();
      setTimeout(() => {
        this.totalAutoSum();
      }, 200);
    } else {
      setTimeout(() => {
        this.createResponseJson();
      }, 200);
    }
  }

  ngOnInit() {
    const duration = this._session.api.local.get(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL);
    if (this.document.getElementById('nextBtn')) {
      this.document.getElementById('nextBtn').disabled = true;
        this.duration_time = setTimeout(function () {
          this.document.getElementById('nextBtn').disabled = false;
        }, duration * 1000);
    }
    console.log('percentage ==>', this.questionDetails);
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        // tslint:disable-next-line: triple-equals
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.responseSingleSelectColumn(el)

          // this.optionSelected[el['selected_row']] = true;
        } else {
          this.mapResponse();
        }
      });
      this.dataEmitter();
      setTimeout(() => {
        this.totalAutoSum();
      }, 200);
    } else {
      setTimeout(() => {
        this.createResponseJson();
      }, 200);
    }
  }


  dataEmitter() {
    const data = { "res": this.response, "id": this.questionDetails.questionId }
    this.emitData.emit(data);
  }
  saveNumberJson(i, optionId, value, eachTd, eachTr, specify?, rowVal?) {
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    eachTd['columnName'] = selected_header;
    eachTd['rowName'] = rowVal;
    eachTd['invalid'] = false;
    this.deselectColumnRadio(selected_header);

    console.log(eachTd);
    if (specify) {
      // tslint:disable-next-line: triple-equals
      const specifyInd = this.response.findIndex((ele) => ele['selected_row'] == this.questionDetails['other_specify_index'] || ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
      if (specifyInd > -1 && this.specifyText) {
        this.response[specifyInd]['otherSpecify_value'] = this.specifyText;
      } else {
        delete this.response[specifyInd]['otherSpecify_value'];
      }
      return;
    }
    if (value && isNaN(value)) {
      value = this.commonQstnService.getNumberFromSurveyLanguage(value);
      if (isNaN(value)) {
        const temp = this.questionsPreviewService.getLanguageNotificationError();
        this.questionErrror.emit(temp['PlsEnterNumeric']);
        return;
      } else {
        value = +value;
      }
    } else {
      if (value !== '') {
        value = +value;
      }
    }

    if (value < 0) {
      eachTd['percent'] = '';
      eachTd['invalid'] = true;
      const temp = this.questionsPreviewService.getLanguageNotificationError();
      this.questionErrror.emit(temp['NegativeNotAllowed']);
    }

    /**get & Check precison */
    if (value) {
      const precision = this._session.api.local.get(Config.CONSTANTS.PRECISION);
      const Field_Precision = this._session.api.local.get('Field_precision');
      let itemPrecisionVal = this.countDecimals(value);
      if (Field_Precision && Field_Precision.length > 0) {
        Field_Precision.forEach(element => {
          if (element.column == eachTd['columnName'] && element.row == eachTd['rowName']) {
            eachTd['field_precision'] = element.precision;
            const rowHeader = this.response.filter(el => el.selected_header == element.column && el.selected_row == element.row)
            if (itemPrecisionVal > element.precision) {
              eachTd['invalid'] = true;
              eachTd['percent'] = '';
              this.response.forEach(el => {
                if (el.selected_header === element.column && el.selected_row === element.row) {
                  el['percent'] = '';
                }
              });
              this.questionErrror.emit(element.errMsg + ' for ' + rowHeader[0].selected_item);
            }
          } else if (eachTd['field_precision'] == undefined || null) {
            if (itemPrecisionVal > precision) {
              eachTd['invalid'] = true;
              eachTd['percent'] = '';
              const temp = this.questionsPreviewService.getLanguageNotificationError();
              this.questionErrror.emit(temp['PrecisionOutofrange'] + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(precision));

            }
          }
        });
      } else {
        if (itemPrecisionVal > precision) {
          eachTd['invalid'] = true;
          eachTd['percent'] = '';
          const temp = this.questionsPreviewService.getLanguageNotificationError();
          this.questionErrror.emit(temp['PrecisionOutofrange'] + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(precision));

        }
      }
    }
    // return precisionChk;
    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = value;
      /** Other specify add 2 extra keys */
      // if (([this.OTHER_OPTION, this.OTHERS_OPTION].indexOf(this.response[Ind]['selected_item'].toLowerCase()) > -1 || this.response[Ind]['selected_row'] == this.questionDetails['other_specify_index']) && (value || value == 0)) {
      if ((this.response[Ind]['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch) || this.response[Ind]['selected_row'] == this.questionDetails['other_specify_index']) && (value || value == 0)) {
        this.response[Ind]['other_specify'] = true;
        this.response[Ind]['otherSpecify_value'] = this.specifyText;
      }
      //  else  {
      //   this.specifyText = '';
      //   delete this.response[Ind]['otherSpecify_value'];
      //   delete this.response[Ind]['other_specify'];
      // }
    } else {
      this.response.push({ selected_value: value, selected_item: eachTr[1]['value'], selected_header: eachTd['columnName'], selected_row: eachTr[0]['value'], option_type: eachTd['value'], disable: eachTd['disable'] ? eachTd['disable'] : false })
    }
    this.dataEmitter();
    this.optionSelected = [];
  }

  /**
  * To create response object for all the existing rows in live survey
  */
  createResponseJson(type?) {
    this.response = []; let disable = false;
    let inlinePipe;
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        inlinePipe = false;
        if (col['columnId']) {
          row[colInd]['header'] = col['columnId'];

          if (type !== 'none') {
            row[colInd]['percent'] = row[colInd]['percent'] ? row[colInd]['percent'] : '';
            disable = row[colInd]['disable'] ? row[colInd]['disable'] : false;
            if (row[colInd]['parseStatus'] && row[colInd]['parseStatus'] == 'Success' && row[colInd]['parsedInstruction'].includes('pipe')) {
              inlinePipe = true;
              if (row[colInd]['value'].indexOf('%') > -1) {
                const repTemp = row[colInd]['value'].replace('%', '');
                row[colInd]['value'] = repTemp;
                row[colInd]['percent'] = Number(row[colInd]['value']);
                row[colInd]['value'] = row[colInd]['value'] + ' % ';
              } else {
                row[colInd]['percent'] = Number(row[colInd]['value']);
              }
            }
          } else {
            row[colInd]['percent'] = row[colInd]['value'] == 'Single choice' ? false : '';
          }
          row[colInd]['percent'] = row[colInd]['percent'] ? row[colInd]['percent'] : '';
          this.response.push({ selected_value: row[colInd]['percent'], selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: inlinePipe ? 'Percent' : row[colInd]['value'], disable: disable });
        }
      });
    });
    this.totalAutoSum();
    this.dataEmitter();
  }

  mapResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }

      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          row[colInd]['percent'] = resp['selected_value'];
          row[colInd]['header'] = resp['selected_header'];
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
        if (row[0]['value'] == resp['selected_row']) {
          resp['disable'] = row[colInd]['disable'] ? row[colInd]['disable'] : false;
        }
      });
    });
    console.log(this.questionDetails);
  }

  chooseNone(rowIndex, OptionId, colId, eachTd) {

    if (eachTd.valueType === 'Label' || eachTd.value !== 'Single choice') { return }

    this.questionDetails.Options.rows.forEach((element, index) => {
      if (OptionId == element[0]['value']) {
        element[colId]['radioSelect'] = element[colId]['radioSelect'] && element[colId]['radioSelect'] === true ? false : true;
      } else {
        element[colId]['radioSelect'] = false;
      }
    });

    const selected_header = this.questionDetails.Options.columns[colId]['columnId'];

    // this.optionSelected = [];
    // this.createResponseJson('none');
    this.deselectColumn(selected_header);
    const Ind = this.response.findIndex((ele) => ele['option_type'] == 'Single choice' && ele['selected_row'] == OptionId);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
    }
    this.dataEmitter();
    this.optionSelected[OptionId] = true;
  }


  /**
   * To find selected item
   * @param row - row
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }


  randomize() {
    const array = this.questionDetails.Options.rows;
    let currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
  countDecimals(value) {
    if (Math.floor(value) === value) { return 0; }
    return value.toString().split('.')[1].length || 0;
  }

  calculateAutoSum(index, row, currentIndex) {
    let sum = 0;
    row.forEach(el => {
      if (el.value === 'Percent') {
        sum += Number(el.percent);
      }
      if (el.value === '' && el.instruction === '[AUTOSUM]') {
        el.percent = Number(sum);
      }
    });
    // if (index === 4) {
    let columnSum = 0;
    const columLen = this.questionDetails.Options.rows.length;
    this.questionDetails.Options.rows.forEach(element => {
      // element[currentIndex]
      if (element[currentIndex].value === 'Percent') {
        columnSum += Number(element[currentIndex]['percent']);
      }
    });
    if (this.questionDetails.Options.rows[columLen - 1] && this.questionDetails.Options.rows[columLen - 1][currentIndex] && this.questionDetails.Options.rows[columLen - 1][currentIndex]['instruction'] === '[AUTOSUM]') {
      this.questionDetails.Options.rows[columLen - 1][currentIndex]['percent'] = Number(columnSum);
    }
  }

  totalAutoSum() {
    /** Autosum handles row wise and column */
    let rowsum = 0;
    let colSum = 0;
    this.questionDetails.Options.rows.forEach((el, index) => {
      el.forEach(innerEl => {
        if ((innerEl.value === 'Percent' && innerEl.percent != null) || (innerEl.parseStatus == 'Success' && innerEl.parsedInstruction.includes('pipe'))) {
          let num = 0;
          if (innerEl.parsedInstruction && innerEl.parsedInstruction.includes('pipe')) {
            num = innerEl.value;
          } else {
            num = innerEl.percent;
          }
          rowsum += Number(num);
        }
        if (innerEl.instruction === '[AUTOSUM]') {
          innerEl['percent'] = Number(rowsum);
          rowsum = 0;
        }
      });
    });

    const columLen = this.questionDetails.Options.rows.length;
    this.questionDetails.Options.rows[columLen - 1].forEach((element, i) => {
      if (element && element.instruction === '[AUTOSUM]') {
        this.questionDetails.Options.rows.forEach(ele => {
          ele.forEach((arr, innerIndex) => {
            if (i === innerIndex && (arr.value === 'Percent' || (arr.parseStatus == 'Success' && arr.parsedInstruction.includes('pipe')))) {
              let num = 0;
              if (arr.parsedInstruction && arr.parsedInstruction.includes('pipe')) {
                num = arr.value;
              } else {
                num = arr.percent;
              }
              colSum += Number(num);
            }
          });
        });
        element['percent'] = Number(colSum) ? Number(colSum) : element['percent'];
        colSum = 0;
      }
    });

    // let sum = 0;
    // this.questionDetails.Options.rows.forEach((el, index) => {
    //   el.forEach((innerEl) => {
    //     if (innerEl.value === 'Percent') {
    //       sum += Number(innerEl.percent);
    //     }
    //   });
    //   if (index === (columLen - 1)) {
    //     el.forEach(element => {
    //       if (element.instruction === '[AUTOSUM]') {
    //         element['percent'] = element['percent'] ? element['percent'] : Number(sum);
    //       }
    //     });
    //   }
    // });
  }


  deselectColumn(selected_header) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] == selected_header) {
          row[colInd]['percent'] = '';
        }
      });
    });
    this.response.forEach(element => {
      if (element && element.selected_header == selected_header) {
        element.selected_value = '';
      }
    });
    console.log(this.optionSelected);
    this.dataEmitter();
  }

  deselectColumnRadio(selected_header) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] === selected_header) {
          if (row[colInd]['header'] == selected_header && row[colInd]['value'] == 'Single choice') {
            row[colInd]['radioSelect'] = false;
          }
        }
      });
    });
    this.response.forEach(element => {
      if (element && element.selected_header == selected_header && element.option_type == 'Single choice') {
        element.selected_value = 0;
      }
    });
  }

  responseSingleSelectColumn(element) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] === element['selected_header'] && row[0]['value'] === element.selected_row) {
          row[colInd]['radioSelect'] = true;
        }
      });
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.duration_time)
  }

}
