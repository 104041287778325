import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpLayerService } from '../../../services/http-layer.service';
import { Config } from '../../../config/config';
import { SessionService } from '../../../services/session-service.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-dnd-message',
  templateUrl: './dnd-message.component.html',
  styleUrls: ['./dnd-message.component.css']
})
export class DndMessageComponent implements OnInit {
  @Input() questionDetails: any;
  @Input() isLiveMessage: boolean;
  @Input() messageQuestions: any;
  @Input() messageScreenText: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  @Output('onTableData') emitTableData=new EventEmitter();
  public todo = [];
  public done = [];
  public todoMessage  = [];
  public popUpContent = [];
  public response     = [];
  private BASE_INPUT;
  LoopNo     = [];
  messages   = [];
  messageSet = [];
  firstSet   = [];
  selectedRespondentNo = 1;
  selectedLoopNo       = 1;
  doneLength;

  constructor(private _session: SessionService, private httpLayer: HttpLayerService, private notification: NzNotificationService,) { }

  ngOnInit() {
    if(this.isLiveMessage){
      if (this.messageQuestions && this.messageQuestions.data.length > 0) {
        this.messageSet  = this.messageQuestions.data;
        this.todoMessage = [];
        this.done        = [];
        let doneArray    = [];
        let todoArray    = [];
        this.doneLength  = this.messageSet[0].length;
        this.firstSet    = this.messageSet[0];
        let saveData     = this.messageQuestions.response;

        this.messageSet[0].forEach((item, index) => { this.todoMessage.push([this.messageSet[0][index]]) });
        this.messageSet[1].forEach((item, index) => { this.todoMessage.push([this.messageSet[1][index]]) });
          
        for (let index = 0; index < this.doneLength; index++) {
          doneArray[index] = [{
            message: '...message',
            colorCode: 'white'
          }]; 
        }

        if (saveData.length > 0) {
            saveData.forEach((item, index) => {
              this.todoMessage.forEach((todoItem) => {
                if (item === todoItem[0].messageID) {
                  doneArray[index] = [todoItem[0]];
                }
              })
            });
            this.todoMessage.forEach((item) => {
              if (!saveData.includes(item[0].messageID)) {
                todoArray.push([item[0]])
              }
            });
        } else {
           this.todoMessage.forEach((item)=>{
            todoArray.push([item[0]])
          });
        }

        this.todo = Object.assign([], todoArray);
        this.done = Object.assign([], doneArray);
      }
      this.selectedData()
    } else {
      this.LoopNo         = this.questionDetails.message_options.data["0"].messageIndex;
      this.selectedLoopNo = this.LoopNo["0"];
      this.onUpdateLoopNo(1);
    }
  }

  /**
   * Triggered on udate of respondent number
   * @param value - value
   */
  onUpdateRespondentNo(value): void {
    this.selectedRespondentNo = value;
    this.LoopNo               = this.questionDetails.message_options.data[value - 1].messageIndex;
    this.onUpdateLoopNo(1);
  }

  /**
   * Triggered on udate of loop number
   * @param value - value
   */
  onUpdateLoopNo(value) {
    this.BASE_INPUT = JSON.stringify(
      this._session.api.local.get(Config.CONSTANTS.SELECTED_PROJECT)
    );
    const inputData          = {};
    this.messages            = [];
    this.todoMessage         = [];
    this.done                = [];
    let doneArray            = [];
    this.selectedLoopNo      = value;
    inputData['surveyKey']   = JSON.parse(this.BASE_INPUT)['surveyKey'];
    inputData['participant'] = this.selectedRespondentNo;
    inputData['sino']        = this.LoopNo[this.selectedLoopNo-1];

    this.httpLayer.post(Config.SERVICE_IDENTIFIER.MESSAGE_QUESTIONS_PREVIEW, inputData).subscribe(
      response => {
        if (response.status === 'success') {
          const results   = response.result.data;
          this.doneLength = results[0].length;
          this.firstSet   = results[0];
          results[0].forEach((item,index)=>{this.todoMessage.push([results[0][index]])});
          results[1].forEach((item,index)=>{this.todoMessage.push([results[1][index]])});

          for (let index = 0; index < this.doneLength; index++) {
          doneArray[index] = [{
            message: '...message',
            colorCode: 'white'
          }]; 
        }
          this.todo = Object.assign([], this.todoMessage);
          this.done = Object.assign([], doneArray);
        } else {
          this.notification.error('Error', response['result'] || 'Failed to get data, please try again later');
        }
      }
    );
  }

  /**
   * Triggered on drop of an option
   * @param event dropped event
   */
  dropPreferredStory(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if (event.container.data.length < this.doneLength + 1) {
      if (this.done[event.currentIndex] && this.done[event.currentIndex][0].message === '...message') {
        this.done[event.currentIndex] = this.todo[event.previousIndex];
        this.todo.splice(event.previousIndex, 1);
      } else if (this.done[event.currentIndex]) {
        let doneCurrent = this.done[event.currentIndex];
        this.done[event.currentIndex] = this.todo[event.previousIndex];
        this.todo[event.previousIndex] = doneCurrent;
      }
    }
    this.selectedData();
  }

  /**
   * Triggered on drop of an option
   * @param event dropped event
   */
  dropCandidateSet(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (this.done[event.previousIndex] && this.done[event.previousIndex][0].message !== '...message') {
        this.todo.push(this.done[event.previousIndex]);
        this.done[event.previousIndex] = [{
          message: '...message',
          colorCode: 'white'
        }];
      } else if (this.done[event.previousIndex + 1] && this.done[event.previousIndex + 1][0].message !== '...message') {
        this.todo.push(this.done[event.previousIndex + 1]);
        this.done[event.previousIndex + 1] = [{
          message: '...message',
          colorCode: 'white'
        }];
      }
    }
    this.selectedData();
  }

  /**
   * Triggered on click of the option
   * @param value - option value
   * @param ind - option index
   */
  clickRanking(value, ind) {
    if (this.done.length < this.doneLength + 1) {
      let flag = true;
      this.done.forEach((item,index)=>{
        if(item[0].message === '...message' && flag){
          this.done[index] = this.todo[ind];
          this.todo.splice(ind, 1);
          flag = false;
        }
      });
    }
    this.selectedData();
  }

  /**
   * Emit back the selectedItems to live survey
   */
  selectedData() {
    let selectedItems = []
    this.done.map(x => {
      if (x[0].message !== '...message') {
        selectedItems.push(x[0].messageID);
      }
    })
    this.emitTableData.emit(selectedItems);
  }

  /**
   * Emit back the response to live survey to get saved
   */
   dataEmitter() {
    const data = {"res": this.response, "id": this.questionDetails.questionId}
    this.emitData.emit(data);
  }
}