import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsPreviewComponent } from './questions-preview.component';
import { SingleSelectQuestionComponent } from './single-select-question/single-select-question.component';
import { FormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';
//  import { NzRadioModule } from 'ng-zorro-antd/radio';
import { MultiSelectQuestionComponent } from './multi-select-question/multi-select-question.component';
import { NumberQuestionComponent } from './number-question/number-question.component';
import { PercentQuestionComponent } from './percent-question/percent-question.component';
import { OpenEndedTextQuestionComponent } from './open-ended-text-question/open-ended-text-question.component';
import { RatingQuestionComponent } from './rating-question/rating-question.component';
import { SliderQuestionComponent } from './slider-question/slider-question.component';
import { DateQuestionComponent } from './date-question/date-question.component';
import { DropdownQuestionComponent } from './dropdown-question/dropdown-question.component';
import { RankingQuestionType1Component } from './ranking-question-type1/ranking-question-type1.component';
import { RankingQuestionType2Component } from './ranking-question-type2/ranking-question-type2.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BlankTableQuestionComponent } from './blank-table-question/blank-table-question.component';
import { LargeTextQuestionComponent } from './large-text-question/large-text-question.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SafeHtmlPipe } from './safehtml.pipe';
import { DndMessageComponent } from './dnd-message/dnd-message.component';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { SingleTypeMessageComponent } from './single-type-message/single-type-message.component'

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    DragDropModule,
    NgxSliderModule
  ],
  declarations: [QuestionsPreviewComponent, SingleSelectQuestionComponent, MultiSelectQuestionComponent, NumberQuestionComponent, PercentQuestionComponent, OpenEndedTextQuestionComponent, RatingQuestionComponent, SliderQuestionComponent, DateQuestionComponent, DropdownQuestionComponent, RankingQuestionType1Component, RankingQuestionType2Component, BlankTableQuestionComponent, LargeTextQuestionComponent, SafeHtmlPipe, DndMessageComponent, SingleTypeMessageComponent, MessagePopupComponent],
  exports: [QuestionsPreviewComponent]
})
export class QuestionsPreviewModule { }
