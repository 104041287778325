import { Injectable } from '@angular/core';
import { CommonServices } from 'src/app/services/common-services.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectSharedService {

  constructor(
    private _commonServices: CommonServices
  ) { }

  private cacheData: object = {};

  /**
   * Cache data with the given key
   * @param key key name
   * @param data data to be cached
   */
  setData(key: string, data: any) {
    if (data) {
      this.cacheData[key] = this._commonServices.cloneObject(data);
    } else {
      this.resetData(key);
    }
  }

  /**
   * Fetch cached data of the given key
   * @param key name of key of cached data
   */
  getData(key: string) {
    if (this.cacheData[key]) {
      return this._commonServices.cloneObject(this.cacheData[key]);
    }
  }

  /**
   * Reset a cached data
   * @param key key in which data cached
   */
  resetData(key: string) {
    delete this.cacheData[key];
  }

  /**
   * Reset all cached data related to field metadata
   */
  resetFieldRelatedData() {
    /** Field data related keys */
    const keys = ['data-definition', 'field-list', 'formula-popup', 'data-grid-field-list', 'edit-data'];
    keys.forEach(key => delete this.cacheData[key]);
  }

  /**
   * Reset all cached data
   */
  resetAllData() {
    this.cacheData = {};
  }

  dateFormat(d) {
    const dformat = [(d.getMonth() + 1),
    d.getDate(),
    d.getFullYear()].join('-');
    const time = d.getHours() + '-' + d.getMinutes() + '-' + d.getSeconds();
    return time;
  }

  dateFormatWithTime() {
    let date = new Date(),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    return [date.getFullYear(), mnth, day].join("-") + ':' + time;

  }
}
