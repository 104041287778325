import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { Config } from '../config/config';
import { HttpLayerService } from '../services/http-layer.service';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { SessionService } from '../services/session-service.service';
import { QuestionsPreviewService } from '../shared/questions-preview/questions-preview.service';
import { TranslateService } from '@ngx-translate/core';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ProjectSharedService } from '../layout/project/project-shared.service';

@Component({
  selector: "app-live-survey",
  templateUrl: "./live-survey.component.html",
  styleUrls: ["./live-survey.component.css"],
})
export class LiveSurveyComponent implements OnInit {
  public ribbon_message;
  public routerParams;
  public respondentDetails;
  public sectionDetails;
  public questionDetails;
  public instructionDetails;
  public password;
  public showRespDetails = true;
  public surveyStatus = "";
  public NEXT_SECTION = "next_section";
  public NEXT_QUESTION = "next_question";
  public PREV_SECTION = "previous_section";
  public PREV_QUESTION = "previous_question";
  public FETCH_QUESTION_WITH_QUESTIONID = "fetch_question_with_questionid";
  public FETCH_MULTIPLE_QUESTIONS = "fetch_multiple_questions";
  public BACK_TO_SURVEY = "backTo";
  public action = "";
  public current_section: any;
  public current_section_index: any;
  public current_question: any = "";
  public current_question_index: any;
  public total_section_questions = 0;
  public total_survey_questions = 0;
  public total_completed_questions = 0;
  public displayQuestionIndex = 0;
  public selectedData: any = [];
  public progressPercentage = 0;
  public required_question_id: any;
  public start_question_id: any;
  public end_question_id: any;
  surveyPartcipantReponse: any;
  public dashboardStatus = "";
  public errorMsgShow = false;
  public errorMsg: any;
  public translateLang: any;
  public errorMsgKeyPattern = /{[a-zA-Z]+}/g
  public surveyLangCode = 'en';
  public OTHER_OPTION = 'Other (please specify)'.toLowerCase();
  public OTHERS_OPTION = 'Others (please specify)'.toLowerCase();
  public validateCompleteQuestion = true;
  public paricipant_action = undefined;
  public isbackToSurvey = false;
  public atmostEnable = undefined;
  public isLiveMessage = true;
  public questionDetailOptions = [];
  public currentVersionId = undefined;
  isPopup = true;
  isVisible = false;
  currentMessageIndex = 0;
  messageLoopCount;
  messageSetLength;
  popUpContent = [];
  public checkPage = false;
  public checkMultiReponse = false;
  public skipPassword : boolean;

  // to get the updatesurvey api
  public terminate_current_question = "";
  public terminate_current_section_index = "";

  public languageList = Config.languageList;
  ipAddress: any;
  jumpTo: any;
  questionIdList: any;
  public regionList: any;
  public languageDetails: any = {
    region: null,
    language: null,
  };
  public showRegionDropdown = false;
  surveyLanguageList: any;
  public languageData: any;
  public initialSurveyOpen = false;
  public atleastEnable: any;
  public chooseEnable: any;
  private BASE_INPUT: any;
  public isOkOrCancel: any;
  messageQuestions: any;
  isMessageQuestions: boolean;
  currentQuestionType: any;
  // currentCombinationType: any;
  currentQuestionId: any;
  messageBreakFlag: boolean;
  public quota_termination = false;
  public termination_message;
  public checkVendor = false;
  public gl_participantId: any;
  public gl_surveyKey: any;
  public checkGenericLink = false;
  public redirectURLCompleted: any;
  public redirectURLQuota: any;
  public redirectURLTerminated: any;
  public checkRedirectURL: any = false;
  public multipleErrorMsg = [];
  public checkPrevious = false;
  public fetchMultiple: any;
  public pageLengthList: any;
  public checkJumpTo: any;
  public questionDetailsList: any;
  public errList: any = [];
  public checkquestionDetails = false;
  public version_id: any;
  public QuestionNumberMapMultiple;
  public checkMultiplePreviousSection = false;
  public lastQuestionIndex;
  public message_type;
  private timeToRedirect = 2000;

  constructor(
    private route: ActivatedRoute,
    private httpLayer: HttpLayerService,
    private notification: NzNotificationService,
    private _session: SessionService,
    private questionsPreviewService: QuestionsPreviewService,
    public translate: TranslateService,
    private http: HttpClient,
    private shared: ProjectSharedService,
    @Inject(DOCUMENT) private document: any
  ) {
    // translate.addLangs(['en', 'nl']);
    translate.setDefaultLang("en");
    this.translateLanguage();
  }

  ngOnInit() {
    this.BASE_INPUT = JSON.stringify(
      this._session.api.local.get(Config.CONSTANTS.SELECTED_PROJECT)
    );

    // Hide main loader
    try {
      this.httpLayer.hideLoader();
      this.getIPAddress();
      /** Remove main-page-loader */
      const el = this.document.getElementsByClassName("main-page-loader")[0];
      el.style.display = "none";
      /**Set default values on load - first time when we open live survey*/
      this.isbackToSurvey = false;
      this._session.api.local.save(Config.CONSTANTS.RANGE_START, 0);
      this._session.api.local.save(Config.CONSTANTS.NUM_RANGE_END, 9999);
      this._session.api.local.save(Config.CONSTANTS.PER_RANGE_END, 100);
      this._session.api.local.save(Config.CONSTANTS.PRECISION, 0);
      this._session.api.local.save(Config.CONSTANTS.TEXT_START, 10);
      this._session.api.local.save(Config.CONSTANTS.TEXT_END, 500);
      this._session.api.local.save(Config.CONSTANTS.MINIMUM_RESPONSE, 1);
      this._session.api.local.save(Config.CONSTANTS.HOLD_SCREEN_DURATION, 30);
      this._session.api.local.save(Config.CONSTANTS.DEFAULT_VALUE, null);
      this._session.api.local.save(Config.CONSTANTS.ATMOST, false);
      this._session.api.local.save(Config.CONSTANTS.ATLEAST, false);
      this._session.api.local.save(Config.CONSTANTS.CHOOSE, false);
      // this.initialLoad = true;
      this._session.api.local.save("QuestionTypes", {});
      this._session.api.local.save("QuestionNumberMap", {});
      // this._session.api.local.save("QuestionTypes", new Map());
      // this._session.api.local.save("QuestionNumberMap", new Map());
    } catch (e) {
      console.log(e);
    }

    this.routerParams = this.route.snapshot.params;
    console.log(this.routerParams);

    /**Check survey status if it is live then proceed else terminate */
    this.httpLayer
      .post(Config.SERVICE_IDENTIFIER.FETCH_SURVEY_STATUS, this.routerParams)
      .subscribe((response) => {
        if (response["status"] === "success") {
          // tslint:disable-next-line: triple-equals
          if (response["result"] == null) {
            this.notification.warning('', "Check survey key and PID");
          }
          if (response["result"] != null && response["result"]["status"] == "Closed") {
            this.surveyStatus = response["result"]["status"];
            this.showRespDetails = false;
            return;
          }
          if (response["result"]['generic_link'] && response["result"]["status"]) {
            this.gl_participantId = response["result"]['gl_pid'];
            this.gl_surveyKey = response["result"]['surveyKey'];
            this.checkGenericLink = true;
            this.fetchTranslationSettings();
            this.getQuestionTypeList();
            this.getQuestionIds();
          }
          if (response["result"]["vendor_link"] && response["result"]["status"]) {
            this.checkVendor = response["result"]["vendor_link"];
            this.fetchParticipantDetails(true);

            if (response["result"]['completed_link']) {
              this.checkRedirectURL = true;
              this.redirectURLCompleted = response["result"]['completed_link'];
              this.redirectURLQuota = response["result"]['overquota_terminated_link'];
              this.redirectURLTerminated = response["result"]['terminated_link'];
            }
          }
          if (response["result"]["status"] && this.checkVendor == false && this.checkGenericLink == false) {
            this.fetchParticipantDetails();
          }
          else if (response["result"]["status"] && this.checkVendor === false && this.checkGenericLink == false) {
            this.notification.warning('', "Check survey key and PID");
            // this.fetchParticipantDetails();
          }

        }

      });
    // this.completeQuestionLists();
  }

  /**
   * After completed the survey - if required to back to fist question
   */
  backToSurvey() {
    this.action = this.BACK_TO_SURVEY;
    this.surveyStatus = '';
    this.currentMessageIndex = 0;
    this.fetchData();
  }

  /**
   * Popup for each drag and drop message loop
   */
  showPopup(): void {
    if (this)
      this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
    this.isPopup = false;
    this.checkData();
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  setErrorMessage(message) {
    this.errorMsgShow = true;
    this.errorMsg = message;
    return;
  }

  setMultipleErrorMessage(message) {
    this.errorMsgShow = true;
    this.multipleErrorMsg = message;
    return;
  }

  fetchParticipantDetails(timeOut?) {
    //const inputData = { "actual_survey_key": JSON.parse(this.BASE_INPUT).surveyKey, ...this.routerParams }
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_PARTICIPANT_DETAILS, this.routerParams).subscribe(response => {
      if (response['status'] === 'success') {
        this.respondentDetails = response['result'];
        this.questionsPreviewService.respondentDetails = response['result'];
        this.ribbon_message = response['result']['ribbon_message'];
        this.skipPassword = response['result']['password_status'];
        this.fetchSurveyResponse(this.routerParams);
        this.getQuestionTypeList();
        this.getQuestionIds();
        if (timeOut) {
          this.fetchTranslationSettings();
        }
        if (this.skipPassword) {
          this.validatePassword();
        }
      }
    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  setRegionData() {
    this.showRegionDropdown = true;
    this.showRespDetails = false;
    this.getRegionDetails();
  }

  startSurvey() {
    //get and set survey language to translator 
    let surveyLang = this.languageList.find(l => l.label === this.languageDetails.language);
    if (!surveyLang) {
      surveyLang = { code: 'en', label: 'English' }
    }
    if (surveyLang) {
      this.surveyLangCode = surveyLang.code;
      this.translate.use(this.surveyLangCode);
      this.questionsPreviewService.surveyLanguage = this.surveyLangCode;
      this.translate.get(['AnswerCheck', 'RangeCheck',
        'PrecisionChk', 'DuplicateVal', 'PlsEnterbetween', 'EntervalShouldBwChar', 'PrecisionOutofrange', 'PlsEnterNumeric',
        'NegativeNotAllowed', 'OtherValue', 'Warning'
      ])
        .subscribe((res: any[]) => {
          this.languageData = res;
          this.questionsPreviewService.setLanguageNotificationError(this.languageData);
        });
    }
    this.showRespDetails = false;
    // this.action = 'COMPLETE_QUESTION';
    this.action = this.NEXT_SECTION;
    this.current_section = '';
    this.current_section_index = '';
    this.current_question = '';
    this.current_question_index = '';
    this.fetchData();
    // this.updateLiveSurveyStatus('Started');
    this.initialSurveyOpen = true;
    this.showRegionDropdown = false;
    // this.completeQuestionLists();
  }
  getPrevQuestion() {
    // If moving from message question to out then will set to 0'th index
    this.currentMessageIndex = this.currentQuestionType === 'Message_drag_and_drop_type' ? this.currentMessageIndex : 0;
    this.initialSurveyOpen = false;
    this.validateCompleteQuestion = false;
    this.jumpTo = {};
    this.isbackToSurvey = false;
    this.errorMsgShow = false;
    this.errorMsg = undefined;
    this.multipleErrorMsg = [];
    this.checkPrevious = true;
    this.selectedData = null;
    this.fetchMultiple = this._session.api.local.get(Config.CONSTANTS.MULTIPLE_QUESTIONS)
    if (this.fetchMultiple === null || Object.keys(this.fetchMultiple).length === 0) {
      if (this.current_question_index === 0 || this.current_question_index === '') {
        this.action = this.PREV_SECTION;
        this.fetchData();
      } else {
        this.action = this.PREV_QUESTION;
        this.fetchData();
      }
    } else {
      const inputCheck = {
        action: "check_previous_section",
        surveyKey: this.respondentDetails.surveyKey,
        participant_id: this.respondentDetails.participant_id,
        current_question: this.current_question,
        current_section_index: this.current_section_index,
      }
      this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_QUESTION_DETAILS, inputCheck).subscribe(response => {
        if (response.result.call_previous_section == true) {
          this.checkPreviousAction(true)
        } else {
          this.checkPreviousAction(false)
        }
      });
    }
  }

  checkPreviousAction(flag) {
    const input = {
      action: "fetch_previous_details",
      surveyKey: this.respondentDetails.surveyKey,
      participant_id: this.respondentDetails.participant_id,
      current_question: this.current_question,
      current_section_index: this.current_section_index,
      current_question_index: this.current_question_index,
    }
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_QUESTION_DETAILS, input).subscribe(response => {
      if (flag) {
        this.action = this.PREV_SECTION;
        this.checkMultiplePreviousSection = true;
        this.fetchData();
      }
      else if (response.result.action == "previous_question") {
        this.current_question = response.result.current_qn_id;
        this.current_question_index = response.result.current_question_index;
        this.action = this.PREV_QUESTION;
        this.fetchData();
      } else if (response.result.action == "fetch_multiple_questions") {
        this.action = this.FETCH_MULTIPLE_QUESTIONS;
        this.start_question_id = response.result.start_qn_id;
        this.end_question_id = response.result.end_qn_id;
        this.fetchData();
      }
    })
  }

  getNextQuestion() {
    this.initialSurveyOpen = false;
    this.isbackToSurvey = false;
    this.validateCompleteQuestion = false;
    this.jumpTo = {};
    this.checkPrevious = false;
    this.errorMsgShow = false;
    this.errorMsg = undefined;
    this.multipleErrorMsg = [];
    this.pageLengthList = [];
    this.selectedData = null;
    this.fetchMultiple = this._session.api.local.get(Config.CONSTANTS.MULTIPLE_QUESTIONS);
    if (this.fetchMultiple === null || Object.keys(this.fetchMultiple).length === 0) {
      this.action = (this.current_question_index !== null && (this.current_question_index === this.total_section_questions - 1)) ? this.NEXT_SECTION : this.NEXT_QUESTION;
      // Next section will only start after the all loop of message set questions
      this.action = (this.action === this.NEXT_SECTION && (this.currentQuestionType === 'Message_drag_and_drop_type' && !this.messageBreakFlag)) ? this.NEXT_QUESTION : this.action;
      // this.action = (this.action === this.NEXT_SECTION && (this.currentQuestionType === 'Message_drag_and_drop_type' && this.currentMessageIndex !== this.messageLoopCount)) ? this.NEXT_QUESTION : this.action;
      this.current_question_index = this.action === this.NEXT_SECTION ? '' : this.current_question_index;
      this.current_question = this.action === this.NEXT_SECTION ? '' : this.current_question;
      this.fetchData();
    }
    else {
      if (this.fetchMultiple && !this.fetchMultiple.hasOwnProperty(this.current_question)) {
        this.action = (this.current_question_index !== null && (this.current_question_index === this.total_section_questions - 1)) ? this.NEXT_SECTION : this.NEXT_QUESTION;
        // Next section will only start after the all loop of message set questions
        this.action = (this.action === this.NEXT_SECTION && (this.currentQuestionType === 'Message_drag_and_drop_type' && !this.messageBreakFlag)) ? this.NEXT_QUESTION : this.action;
        // this.action = (this.action === this.NEXT_SECTION && (this.currentQuestionType === 'Message_drag_and_drop_type' && this.currentMessageIndex !== this.messageLoopCount)) ? this.NEXT_QUESTION : this.action;
        this.current_question_index = this.action === this.NEXT_SECTION ? '' : this.current_question_index;
        this.current_question = this.action === this.NEXT_SECTION ? '' : this.current_question;
        this.start_question_id = '';
        this.end_question_id = '';
      }
      else {
        if (this.fetchMultiple && this.fetchMultiple.hasOwnProperty(this.current_question)) {
          for (const property in this.fetchMultiple) {
            if (this.current_question === property) {
              this.start_question_id = this.fetchMultiple[property][0];
              this.end_question_id = this.fetchMultiple[property].slice(-1)[0];
            }
            this.pageLengthList.push(this.fetchMultiple[property].length);
          }
          this.current_question = this.end_question_id;
        } else {
          this.start_question_id = '';
          this.end_question_id = '';
          this.current_question = this.end_question_id === '' ? this.current_question : this.end_question_id;
          this.action = (this.current_question_index !== null && (this.current_question_index === this.total_section_questions - 1)) ? this.NEXT_SECTION : this.FETCH_MULTIPLE_QUESTIONS;
        }

        this.action = (this.current_question_index !== null && (this.current_question_index === this.total_section_questions - 1)) ? this.NEXT_SECTION : this.FETCH_MULTIPLE_QUESTIONS;
        // Next section will only start after the all loop of message set questions
        this.action = (this.action === this.NEXT_SECTION && (this.currentQuestionType === 'Message_drag_and_drop_type' && !this.messageBreakFlag)) ? this.FETCH_MULTIPLE_QUESTIONS : this.action;
        this.current_question_index = this.action === this.NEXT_SECTION ? '' : this.current_question_index;
        this.current_question = this.action === this.NEXT_SECTION ? '' : this.current_question;
      }
      this.fetchData();
    }
  }
  goToSpecificQuestion() {
    this.isVisible = false;
    this.selectedData = null;
    this.isbackToSurvey = false;
    this.initialSurveyOpen = false;
    this.validateCompleteQuestion = false;
    this.errorMsgShow = false;
    this.errorMsg = undefined;
    this.multipleErrorMsg = [];
    this.pageLengthList = [];
    this.checkJumpTo = true;
    this.fetchMultiple = this._session.api.local.get(Config.CONSTANTS.MULTIPLE_QUESTIONS);
    if (this.fetchMultiple === null || Object.keys(this.fetchMultiple).length === 0) {
      this.action = (this.current_question_index !== null && (this.current_question_index === this.total_section_questions - 1)) ? 'JumpTo' : 'JumpTo';
      this.current_question_index = this.action === 'JumpTo' ? '' : this.current_question_index;
      this.current_question = this.action === 'JumpTo' ? '' : this.current_question;
      this.currentMessageIndex = this.action === 'JumpTo' ? 0 : this.currentMessageIndex;
      this.fetchData();
    } else {
      this.selectedData = [];
      if (this.fetchMultiple) {
        for (const property in this.fetchMultiple) {
          if (this.fetchMultiple[property].includes(this.jumpTo.value)) {
            this.start_question_id = this.fetchMultiple[property][0];
            this.end_question_id = this.fetchMultiple[property].slice(-1)[0]
            break;
          } else {
            this.start_question_id = '';
            this.end_question_id = '';
          }
          this.pageLengthList.push(this.fetchMultiple[property].length);
        }
      }
      if (this.start_question_id == "" || null) {
        this.action = (this.current_question_index !== null && (this.current_question_index === this.total_section_questions - 1)) ? 'JumpTo' : 'JumpTo';
        this.current_question_index = this.action === 'JumpTo' ? '' : this.current_question_index;
        this.current_question = this.action === 'JumpTo' ? '' : this.current_question;
        this.currentMessageIndex = this.action === 'JumpTo' ? 0 : this.currentMessageIndex;
        this.fetchData();
      } else {
        this.action = (this.current_question_index !== null && (this.current_question_index === this.total_section_questions - 1)) ? this.FETCH_MULTIPLE_QUESTIONS : this.FETCH_MULTIPLE_QUESTIONS;
        this.fetchData();
      }
    }
  }

  getSkiptoQuestion() {
    this.action = this.FETCH_QUESTION_WITH_QUESTIONID;
    this.fetchData();
  }

  getRangeQuestions(start_question_id, end_question_id) {
    this.action = this.FETCH_MULTIPLE_QUESTIONS;
    this.start_question_id = start_question_id;
    this.end_question_id = end_question_id;
    this.fetchData();
  }

  checkData() {
    if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
      this.checkMultipleData();
    } else {
      if (this.currentQuestionType === 'Message_drag_and_drop_type' && this.isPopup) {
        this.popUpContent.length === this.messageSetLength ? this.showPopup() : this.setErrorMessage(this.languageData['AnswerCheck']);
      } else {
        /** ONSUBMIT - DO NOT FORCE Rule handling */
        const onsubmitRules = this.instructionDetails.get('ONSUBMIT');
        if (onsubmitRules) {
          for (const rule of onsubmitRules) {
            let funName = rule['instdet'].substring(0, rule['instdet'].indexOf("("));
            let funParams = rule['instdet'].substring(rule['instdet'].indexOf("(") + 1, rule['instdet'].length - 1);
            let params = funParams.split("##");

            if (funName === 'do_not_force_response' && this.selectedData && this.selectedData.length > 0) {
              this.questionsPreviewService.currentSelectedData = this.selectedData;
              const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg'], this.questionDetails);
            } else if (funName === 'do_not_force_response' && funParams === '####' && (this.selectedData == null || this.selectedData.length == 0)) { /** Not selected any data and question level rule */
              this.getNextQuestion();
              return;
            }

            /** DRAG AND DROP with atleast one data selected - column or question level rule */
            if (funName === 'do_not_force_response' && (params[0] === 'column' || funParams === '####')) {
              if (this.questionDetails['questionType'] == 'Ranking' && this.questionDetails.Instructions[0]['instruction'] == 'RANK TYPE = DRAG AND DROP') {
                this.selectedData && this.selectedData.length > 0 ? this.saveData() : this.getNextQuestion();
              } else if (['Single choice'].includes(this.questionDetails['questionType']) && (this.selectedData == null || this.selectedData.length == 0) && params[0] === 'column') {
                let answeredFlag = true;
                this.questionDetails.Options.columns.forEach(element => {
                  if (element.answer && element.columnId && !(params[1].includes(element.columnId))) {
                    answeredFlag = false;
                  }
                });
                answeredFlag ? this.getNextQuestion() : this.setErrorMessage(this.languageData['AnswerCheck']);
                return;
              } else if (['Single choice'].includes(this.questionDetails['questionType']) && (this.selectedData && this.selectedData.length > 0)) {
                if (funParams === '####') {
                  this.saveData();
                } else if (params[0] === 'column') {
                  let answered = true;
                  let noRuleColumn = [];
                  let selectedHeaders = [];
                  this.questionDetails.Options.columns.forEach(element => {
                    if (element.answer && element.columnId && !(params[1].includes(element.columnId))) {
                      noRuleColumn.push(element.columnId);
                    }
                  });
                  this.selectedData.forEach(el => {
                    selectedHeaders.push(el.selected_header);
                  });
                  for (const colmn of noRuleColumn) {
                    if (!(selectedHeaders.includes(colmn))) {
                      answered = false;
                    }
                  }
                  answered ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
                  return;
                }
              }
            }


            /** Multiselect with do not force */
            if (funName === 'do_not_force_response' && ['Select all that apply'].includes(this.questionDetails['questionType']) && this.selectedData && this.selectedData.length > 0) {
              let answered = true;
              this.selectedData.forEach(el => {
                if (!el['doNotForce'] && el['selected_value'] !== 1) {
                  answered = false;
                }
              });
              answered ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
              return;
            }
          }
        }

        this.isPopup = true;
        this.atmostEnable = this._session.api.local.get('ATMOSTENABLE');
        this.atleastEnable = this._session.api.local.get('ATLEASTENABLE');
        this.chooseEnable = this._session.api.local.get('CHOOSEENABLE');
        /**Blank table */
        if (this.questionDetails['questionType'] == 'Blank Table') {
          this.getNextQuestion();
          return;
        }

        /**If data is not emitted it means atleast single field is not answered */
        if (!this.selectedData || (this.selectedData && this.selectedData.length <= 0)) {
          this.setErrorMessage(this.languageData['AnswerCheck']);
          return;
        }
        /**If other specify value is given check if the other specified value is entered or not */
        if (this.selectedData.length > 0) {
          let single_other_specify_warning = false;
          const otherSpecify_array = this.selectedData.filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch)) && (el['otherSpecify_value'] || el['otherSpecify_value'] == ''));
          let otherInd;
          if (otherSpecify_array.length > 0) {
            const otherSpecify_text = otherSpecify_array[0]['otherSpecify_value'];
            this.selectedData.forEach((ele,ind) => {
            if ( ele['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch)) {
              ele['other_specify'] = true;
              ele['otherSpecify_value'] = otherSpecify_text;
              }
            })
          }
          otherInd = this.selectedData.findIndex((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_value']));
          
          if (this.currentQuestionType == 'Number') {
            const otherSpecify_answered = this.selectedData.filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_value']));
            if (otherSpecify_answered.length > 0) {
              const otherSpecify_text = otherSpecify_answered[0]['otherSpecify_value'];
              const total_otherSpecify_columns = this.selectedData.filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_header'] != 'N/A'));
              if ( otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && !otherSpecify_text) {
                this.setErrorMessage(this.languageData['OtherValue']);
                return;
              } else if (otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && otherSpecify_text){
                this.setErrorMessage(this.languageData['AnswerCheck']);
                return;
              }
            }
          }

          if (this.currentQuestionType == 'SLIDER_CATEGORY') {
            const otherSpecify_answered = this.selectedData.filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_value'] !== ''));
            if (otherSpecify_answered.length > 0) {
              const otherSpecify_text = otherSpecify_answered[0]['otherSpecify_value'];
              const total_otherSpecify_columns = this.selectedData.filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_header'] != 'N/A'));
              if ( otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && !otherSpecify_text) {
                this.setErrorMessage(this.languageData['OtherValue']);
                return;
              } else if (otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && otherSpecify_text){
                this.setErrorMessage(this.languageData['AnswerCheck']);
                return;
              } else if (otherSpecify_answered.length != 0  && total_otherSpecify_columns.length == otherSpecify_answered.length && !otherSpecify_text) {
                this.setErrorMessage(this.languageData['OtherValue']);
                return;
              }
            }
          }



          // const otherInd = this.selectedData.findIndex((el) => el['selected_item'].toString().toLowerCase() == this.OTHER_OPTION || el['selected_row'] == this.questionDetails['other_specify_index']);
          // const otherInd = this.selectedData.findIndex((el) => el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == this.questionDetails['other_specify_index']);

          /**Checks for other specify value */
          if (otherInd > -1 && (this.selectedData[otherInd] && this.selectedData[otherInd]['selected_value'] && !this.selectedData[otherInd]['otherSpecify_value']) ||
            (this.selectedData[otherInd] && this.selectedData[otherInd]['otherSpecify_value'] && (this.selectedData[otherInd]['selected_value'] == '' && this.selectedData[otherInd]['selected_value'] !== 0))) {
            this.setErrorMessage(this.languageData['OtherValue']);
            return;
          }

          this.selectedData.forEach(element => {
            if (element.option_type === 'Single choice' && element.otherSpecify_value && !element.selected_item.match(this.questionsPreviewService.pleaseSpecifyMatch)) {
              single_other_specify_warning = true;
            }
          });
          if (single_other_specify_warning) {
            // this.setErrorMessage('Please select Other (Please Specify) option');
            this.setErrorMessage('Please specify Other value');
            return;
          }
        }
        // onload of upto function validation starts
        const onloadRules = this.instructionDetails.get('ONLOAD');

        // const onloadRules = this.instructionDetails.get('ONSUBMIT');
        if (onloadRules) {
          let upto_rule = [];
          this.questionsPreviewService.onScreenDisable = '';
          onloadRules.forEach((rule) => {
            let fun_name = rule['instdet'].substring(0, rule['instdet'].indexOf("("));
            if (['choose', 'atleast', 'atmost'].indexOf(fun_name) > -1) {
              const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
              upto_rule.push(response);
            }

          });
          if (upto_rule.length > 0) {
            let setError = '';
            let uptoValid = true;
            upto_rule.forEach(element => {
              if (element && element.type === 'choose' && this.selectedData.length > 0 && (['Select all that apply', 'Ranking'].indexOf(this.questionDetails['questionType']) > -1 || (['Combination'].indexOf(this.questionDetails['questionType']) > -1 && ['Select all that apply And Single choice', 'Ranking And Single choice'].indexOf(this.questionDetails['combinationTypes'])) > -1)) {
                if (element.ruleOn == 'COLUMNS') {
                  const selectCount = this.selectedData.filter(el => el.selected_header == element.field_id && el.selected_value);
                  const headerColumn = this.questionDetails.Options.columns.filter(el => el.columnId == element.field_id);
                  const headerName = headerColumn[0].header.split('\n')[0];
                  const allUnansweredArray = this.selectedData.filter(el => el.selected_value === 0);
                  const allUnanswered = this.selectedData.length == allUnansweredArray.length ? true : false;
                  if (((selectCount.length !== element.upto_count) && (selectCount[0] && selectCount[0].option_type !== "Single choice")) || (selectCount.length == 0)) {
                    if (allUnanswered) {
                      this.setErrorMessage(this.languageData['AnswerCheck']);
                    } else if (selectCount.length == 0 && !allUnanswered) {
                      setError += 'Please choose ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                      uptoValid = false;
                    } else {
                      setError += 'Please choose ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                      uptoValid = false;
                    }
                  }
                } else if (element.ruleOn == 'ROWS') {
                  const answered = this.selectedData.filter(el => el.selected_value);
                  if (answered.length == 0) {
                    this.setErrorMessage(this.languageData['AnswerCheck']);
                  }
                  const selectCount = this.selectedData.filter(el => el.selected_item == element.field_id && el.selected_value);
                  const headerRow = element.field_id;
                  if (selectCount.length !== element.upto_count) {
                    setError += 'Please choose ' + element.upto_count + ' option in ' + headerRow + ' row' + '<br>';
                    uptoValid = false;
                  }
                }
              } else if (element && element.type == 'atmost' && this.selectedData.length > 0 && (['Select all that apply', 'Ranking'].indexOf(this.questionDetails['questionType']) > -1 || (['Combination'].indexOf(this.questionDetails['questionType']) > -1 && ['Select all that apply And Single choice', 'Ranking And Single choice'].indexOf(this.questionDetails['combinationTypes'])) > -1)) {
                if ( element.ruleOn == 'COLUMNS') {
                  const selectCount = this.selectedData.filter(el => el.selected_header == element.field_id && el.selected_value);
                  const headerColumn = this.questionDetails.Options.columns.filter(el => el.columnId == element.field_id);
                  const headerName = headerColumn[0].header.split('\n')[0];
                  if (selectCount.length > element.upto_count || selectCount.length == 0) {
                    if (selectCount.length == 0) {
                  // const answeredInd = this.selectedData.findIndex(el => el['selected_value'] == 1);
                    this.setErrorMessage(this.languageData['AnswerCheck']);
                    } else {
                    setError += 'Please select upto ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                    uptoValid = false;
                    }
                  }
                } else if ( element.ruleOn == 'ROWS') {
                  const answered = this.selectedData.filter(el => el.selected_value);
                  if (answered.length == 0) {
                    this.setErrorMessage(this.languageData['AnswerCheck']);
                  }
                  const selectCount = this.selectedData.filter(el => el.selected_item == element.field_id && el.selected_value);
                  const headerRow = element.field_id;
                  if (selectCount.length > element.upto_count) {
                    setError += 'Please select upto ' + element.upto_count + ' option in ' + headerRow + ' row' + '<br>';
                    uptoValid = false;
                  }
                }
            } else if (element && element.type == 'atleast' && this.selectedData.length > 0 && (['Select all that apply', 'Ranking'].indexOf(this.questionDetails['questionType']) > -1 || (['Combination'].indexOf(this.questionDetails['questionType']) > -1 && ['Select all that apply And Single choice', 'Ranking And Single choice'].indexOf(this.questionDetails['combinationTypes'])) > -1)) {
              if (element.ruleOn == 'COLUMNS') {
                const selectCount = this.selectedData.filter(el => el.selected_header === element.field_id && el.selected_value);
                const headerColumn = this.questionDetails.Options.columns.filter(el => el.columnId === element.field_id);
                const headerName = headerColumn[0].header.split('\n')[0];
                const allUnansweredArray = this.selectedData.filter(el => el.selected_value === 0);
                const allUnanswered = this.selectedData.length == allUnansweredArray.length ? true : false;
                if (((selectCount.length < element.upto_count) && (selectCount[0] && selectCount[0].option_type !== "Single choice")) || (selectCount.length == 0)) {
                  if (allUnanswered) {
                    this.setErrorMessage(this.languageData['AnswerCheck']);
                  } else if (selectCount.length == 0 && !allUnanswered) {
                    uptoValid = false;
                    setError += 'Please select atleast ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                  } else {
                    setError += 'Please select atleast ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                    uptoValid = false;
                  }
                }
              } else if (element.ruleOn == 'ROWS') {
                const answered = this.selectedData.filter(el => el.selected_value);
                  if (answered.length == 0) {
                    this.setErrorMessage(this.languageData['AnswerCheck']);
                  }
                  const selectCount = this.selectedData.filter(el => el.selected_item == element.field_id && el.selected_value);
                  const headerRow = element.field_id;
                  if (selectCount.length < element.upto_count) {
                    setError += 'Please select atleast ' + element.upto_count + ' option in ' + headerRow + ' row' + '<br>';
                    uptoValid = false;
                  }
              }
            }
          });
          if (!uptoValid) {
            this.setErrorMessage(setError);
            return;
          } else {
            this.errorMsgShow = false;
            this.errorMsg = '';
          }

        }
      }
      // onload of upto function validation ends
      if (['Single choice'].includes(this.questionDetails['questionType'])) {
        /**Single select including single select multi column */
        let selectedHeaders = []
        this.selectedData.forEach(element => {
          selectedHeaders.push(element.selected_header);
        });
        let reduceColumn = [];
        let reduce = 0;
        this.questionDetails['Options']['columns'].forEach((element, ind) => {
          if (ind > 1 && !(selectedHeaders.includes(element.columnId))) {
            this.questionDetails['Options']['rows'].forEach((row) => {
              if (row[ind].value == 'Single choice' && row[ind].disable) {
                if (reduceColumn[ind] == undefined) {
                  reduceColumn[ind] = 1;
                } else {
                  reduceColumn[ind] += 1;
                }
              }
            });
          }
        });
        this.questionDetails['Options']['columns'].forEach((elem, ind) => {
          if (reduceColumn[ind] == this.questionDetails['Options']['rows'].length) {
            reduce++;
          }
        });

        this.selectedData.length == this.questionDetails['Options']['columns'].length - 2 - reduce ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
      } else if (['Select all that apply'].includes(this.questionDetails['questionType'])) {
        /**Multiselect */
        const answeredInd = this.selectedData.findIndex(el => el['selected_value'] == 1);
        answeredInd > -1 && answeredInd != null ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
      } else if (['Text', 'Large Text', 'Number', 'Percent', 'SLIDER', 'SLIDER_CATEGORY', 'Date', 'DROP-DOWN'].includes(this.questionDetails['questionType'])) {
        /**
         * Text, number,percent,slider,date,dropdown
         */

        let answeredInd;
        if (this.questionDetails['questionType'] == 'Number' || this.questionDetails['questionType'] == 'Percent') {
          // answeredInd = this.selectedData.findIndex(el => ((el['selected_value'] !== 0 && !el['selected_value']) || el['selected_value'] == 0 || el['selected_value'] == null || el['selected_value'] < 0) && (el['selected_item'].toLowerCase() !== this.OTHER_OPTION || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
          answeredInd = this.selectedData.findIndex(el => ((el['selected_value'] === '' || el['selected_value'] < 0) && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A'));
          // answeredInd = this.selectedData.findIndex(el => (el['selected_value'] == '' || el['selected_value'] == null || el['selected_value'] < 0) && (el['selected_item'].toLowerCase() !== this.OTHER_OPTION || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
        } else if (this.questionDetails['questionType'] == 'SLIDER' || this.questionDetails['questionType'] == 'SLIDER_CATEGORY') {
          answeredInd = this.selectedData.findIndex(el => el['defaultCell'] && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
        } else {
          answeredInd = this.selectedData.findIndex(el => (el['selected_value'] == '' || el['selected_value'] <= 0) && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
        }
        if (answeredInd > -1 && answeredInd != null) {
          this.setErrorMessage(this.languageData['AnswerCheck']);
          return;
        } else {
          if (['SLIDER', 'SLIDER_CATEGORY', 'Date', 'DROP-DOWN'].includes(this.questionDetails['questionType'])) {
            this.saveData();
          }
        }

        if (['Text', 'Large Text', 'Number', 'Percent'].includes(this.questionDetails['questionType'])) {
          /** Update range values */
          let rangeFlag = true, rangeStart, rangeEnd;
          const emptyFlag = true;
          if (this.questionDetails['questionType'] == 'Number') {
            rangeStart = 0, rangeEnd = 9999;
            rangeStart = Number(this._session.api.local.get(Config.CONSTANTS.RANGE_START));
            rangeEnd = Number(this._session.api.local.get(Config.CONSTANTS.NUM_RANGE_END));
          } else if (this.questionDetails['questionType'] == 'Percent') {
            rangeStart = 0, rangeEnd = 100;
            rangeStart = this._session.api.local.get(Config.CONSTANTS.RANGE_START);
            rangeEnd = this._session.api.local.get(Config.CONSTANTS.PER_RANGE_END);
          } else {
            rangeStart = 10, rangeEnd = 500;
            rangeStart = this._session.api.local.get(Config.CONSTANTS.TEXT_START);
            rangeEnd = this._session.api.local.get(Config.CONSTANTS.TEXT_END);
          }
          this.selectedData.forEach((row, rowInd) => {
            if (this.questionDetails['questionType'] == 'Number' || this.questionDetails['questionType'] == 'Percent') {
              if ((row['selected_value'] || row['selected_value'] == 0) && (row['selected_value'] < rangeStart || row['selected_value'] > rangeEnd) && row['selected_row'] != 'N/A' && (!row['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || row['selected_row'] == this.questionDetails['other_specify_index']) && !row['disable'] && !row['doNotForce']) {
                rangeFlag = false;
                this.errorMsgShow = true;
                const col = row['selected_header'];
                let colIdx = -1;
                this.questionDetails['Options']['columns'].find(function (c, i) {
                  if (c['columnId'] === col) {
                    colIdx = i;
                    return i;
                  }
                });
                if (colIdx > -1) {
                  this.questionDetails['Options']['rows'].forEach((r) => {
                    const roVal = r[0]['value'];
                    const colRow = r[colIdx];
                    if (roVal && roVal === row['selected_row'] && colRow && colRow['valueType'] !== 'Label') {
                      colRow['invalid'] = true;
                    }
                  });
                }
                return;
              }

            } else {
              if (row['selected_value'] && (row['selected_value'].length < rangeStart || row['selected_value'].length > rangeEnd) && row['selected_row'] != 'N/A' && (!row['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || row['selected_row'] == this.questionDetails['other_specify_index']) && !row['disable'] && !row['doNotForce']) {
                rangeFlag = false;
                this.errorMsgShow = true;
                const col = row['selected_header'];
                let colIdx = -1;
                this.questionDetails['Options']['columns'].find(function (c, i) {
                  if (c['columnId'] === col) {
                    colIdx = i;
                    return i;
                  }
                });
                if (colIdx > -1) {
                  this.questionDetails['Options']['rows'].forEach((r) => {
                    const roVal = r[0]['value'];
                    const colRow = r[colIdx];
                    if (roVal && roVal === row['selected_row'] && colRow && colRow['valueType'] !== 'Label') {
                      colRow['invalid'] = true;
                    }
                  });
                }
                return;
              }
            }
          });
          if (this.questionDetails['questionType'] == 'Number' || this.questionDetails['questionType'] == 'Percent') {
            if (!rangeFlag) {
              this.errorMsg = this.languageData['PlsEnterbetween'] + ' ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeStart) + ' -  ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeEnd);
              this.errorMsgShow = true;
              return;
            } else {
              this.selectedData ? this.saveData() : (this.errorMsgShow = true, this.errorMsg = this.languageData['AnswerCheck']);
            }
          } else {
            if (!rangeFlag) {
              this.errorMsg = this.languageData['EntervalShouldBwChar'] + ' ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeStart) + ' -  ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeEnd);
              this.errorMsgShow = true;
              return;
            } else {
              this.selectedData ? this.saveData() : (this.errorMsgShow = true, this.errorMsg = this.languageData['AnswerCheck']);
            }
          }
        }

      } else if (this.questionDetails['questionType'] == 'Ranking' && this.questionDetails.Instructions[0]['instruction'] == 'RANK TYPE = DRAG AND DROP' && (!this.atmostEnable && !this.atleastEnable && !this.chooseEnable)) {
        this.selectedData.length == this.questionDetails['Options']['rows'].length ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
      } else if (this.questionDetails['questionType'] == 'Ranking' && this.questionDetails.Instructions[0]['instruction'] == 'RANK TYPE = DRAG AND DROP' && (this.atmostEnable || this.atleastEnable || this.chooseEnable)) {
        if (!this.errorMsgShow && !this.errorMsg) {
          this.saveData();
        }
      } else if (this.questionDetails['questionType'] == 'Ranking' && this.questionDetails.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (!this.atmostEnable && !this.atleastEnable && !this.chooseEnable)) {
        let count = 0;
        if (this.selectedData) {
          this.selectedData.forEach((ele, ind) => {
            if ((ele['selected_value'] == '' || ele['selected_value'] == null) && !ele['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch)) {
              count += 1;
            }
          })
        } else if (this.selectedData == undefined) {
          count += 1;
        }
        count > 0 ? this.setErrorMessage(this.languageData['AnswerCheck']) : this.saveData();
      } else if (this.questionDetails['questionType'] == 'Ranking' && this.questionDetails.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (this.atmostEnable || this.atleastEnable || this.chooseEnable)) {
        if (!this.errorMsgShow && !this.errorMsg) {
          this.saveData();
        }
      } else if (this.questionDetails['questionType'] == 'Combination' || this.questionDetails['questionType'] == 'Ranking') {
        /** Combination and ranking-2 */

        if (this.questionDetails['combinationTypes'] == 'Select all that apply And Single choice') {
          /** multi + single choice */
          const Ind = this.selectedData.findIndex(el => (el['selected_value'] == 1 && el['option_type'] == 'Single choice') || el['option_type'] == 'Select all that apply' && el['selected_value'] == 1);
          this.translate.get('AnswerCheck').subscribe((text: string) => {
            Ind > -1 && Ind != null ? this.saveData() : this.setErrorMessage(text);
          });
        } else if (['Number And Single choice', 'Percent And Single choice', 'Text And Single choice', 'DROP-DOWN And Single choice', 'Date And Single choice', 'Ranking And Single choice'].includes(this.questionDetails['combinationTypes']) && this.questionDetails.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (!this.atmostEnable && !this.atleastEnable && !this.chooseEnable)) {
          /**
           * (Number, percent,text,dropdown,date) + (single choice)
           */
          let Ind1, Ind2;
          // if (this.questionDetails['combinationTypes'] == 'Ranking And Single choice') {
          //   Ind1 = this.selectedData.findIndex(el => el['selected_value'] === el['selected_row'] && el['option_type'] == 'Single choice' && !el['disable']);
          // } else {
          Ind1 = this.selectedData.findIndex(el => el['selected_value'] == 1 && el['option_type'] == 'Single choice');
          // }
          Ind2 = this.selectedData.findIndex(el => ['Ranking', 'DROP-DOWN', 'Date', 'Number', 'Percent', 'Text', 'Large Text'].includes(el['option_type']) && ((el['selected_value'] !== 0 && !el['selected_value']) || el['selected_value'] == null || el['selected_value'] < 0) && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A' && !el['disable'] && !el['doNotForce'] && !el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch));
          (Ind1 > -1 && Ind2 > -1) || (Ind1 < 0 && Ind2 < 0) ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
        } else if (['Select all that apply And Number', 'Select all that apply And Text'].includes(this.questionDetails['combinationTypes'])) {
          const Ind1 = this.selectedData.findIndex(el => el['selected_value'] == 1 && el['option_type'] == 'Select all that apply');
          const Ind2 = this.selectedData.findIndex(el => (el['selected_value'] == null || el['selected_value'] < 0 || !el['selected_value']) &&
            (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == this.questionDetails['other_specify_index']) || el['selected_row'] == this.questionDetails['other_specify_index'] && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' &&
            el['selected_header'] != 'N/A');
          (Ind1 > -1 && Ind2 <= -1) ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
          (Ind1 > -1 && Ind2 > -1) || (Ind1 < 0 && Ind2 < 0) ? this.saveData() : this.setErrorMessage(this.languageData['AnswerCheck']);
        } else if (['Number And Single choice', 'Percent And Single choice', 'Text And Single choice', 'DROP-DOWN And Single choice', 'Date And Single choice', 'Ranking And Single choice'].includes(this.questionDetails['combinationTypes']) && this.questionDetails.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (this.atmostEnable || this.atleastEnable || this.chooseEnable)) {
          if (!this.errorMsgShow && !this.errorMsg) {
            this.saveData();
          }
        }

      } else if (this.questionDetails['questionType'] == 'Rating') {
        /**RATING */
        const answeredInd = this.selectedData.findIndex(el => el['selected_value'] == null || !el['selected_value'] && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && !el['doNotForce']);
        answeredInd > -1 && answeredInd != null ? this.setErrorMessage(this.languageData['AnswerCheck']) : this.saveData();
      } else if (this.questionDetails['questionType'] == 'Message_single_type' || this.questionDetails['questionType'] == 'Message_drag_and_drop_type') {
        const answeredInd = this.selectedData.findIndex(el => el['selected_value'] == null || !el['selected_value']);
        answeredInd > -1 && answeredInd != null ? this.setErrorMessage(this.languageData['AnswerCheck']) : this.saveData();
      } else {
        this.saveData();
      }
    }
  }
}
checkMultipleData() {
  this.errList = [];
  this.multipleErrorMsg = [];
  if (this.currentQuestionType === 'Message_drag_and_drop_type' && this.isPopup) {
    this.popUpContent.length === this.messageSetLength ? this.showPopup() : this.setErrorMessage(this.languageData['AnswerCheck']);
  } else {
    // this.questionDetailsList.forEach((element, i) => {
    //   const onsubmitRules = this.instructionDetails[element.questionId].get('ONSUBMIT');
    //   if (onsubmitRules) {
    //     for (const rule of onsubmitRules) {
    //       let funName = rule['instdet'].substring(0, rule['instdet'].indexOf("("));
    //       if (funName === 'DO_NOT_FORCE_RESPONSE' && this.selectedData[i][element.questionId] && this.selectedData[i][element.questionId].length > 0) {
    //         this.questionsPreviewService.currentSelectedData = this.selectedData[i][element.questionId];
    //         const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg'], element);
    //       }
    //     }
    //   }
    // })
    let checkRankingDNF = false;
    this.isPopup = true;
    this.atmostEnable = this._session.api.local.get('ATMOSTENABLE');
    this.atleastEnable = this._session.api.local.get('ATLEASTENABLE');
    this.chooseEnable = this._session.api.local.get('CHOOSEENABLE');
    /**Blank table */
    this.questionDetailsList.forEach((element) => {
      if (element['questionType'] == 'Blank Table') {
        this.getNextQuestion();
        return;
      }
    });
    this.questionDetailsList.forEach((element, i) => {
      /**If data is not emitted it means atleast single field is not answered */
      // if (!this.selectedData[i][element.questionId] || (this.selectedData[i][element.questionId] && this.selectedData[i][element.questionId].length <= 0)) {
      //   this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
      //   this.setMultipleErrorMessage(this.errList);
      //   return;
      // }
      /**If other specify value is given check if the other specified value is entered or not */
      if (this.selectedData[i] && this.selectedData[i][element.questionId] && this.selectedData[i][element.questionId].length > 0) {
        let single_other_specify_warning = false;
        // const otherInd = this.selectedData.findIndex((el) => el['selected_item'].toString().toLowerCase() == this.OTHER_OPTION || el['selected_row'] == this.questionDetails['other_specify_index']);
        const otherSpecify_array = this.selectedData[i][element.questionId].filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch)) && (el['otherSpecify_value'] || el['otherSpecify_value'] == ''));
        let otherInd;
        if (otherSpecify_array.length > 0) {
          const otherSpecify_text = otherSpecify_array[0]['otherSpecify_value'];
          this.selectedData[i][element.questionId].forEach((ele,ind) => {
            if ( ele['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch)) {
              ele['other_specify'] = true;
              ele['otherSpecify_value'] = otherSpecify_text;
            }
          })
        }
        otherInd = this.selectedData[i][element.questionId].findIndex((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_value']));
        
        if (this.currentQuestionType == 'Number') {
          const otherSpecify_answered = this.selectedData[i][element.questionId].filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_value']));
          if (otherSpecify_answered.length > 0) {
            const otherSpecify_text = otherSpecify_answered[0]['otherSpecify_value'];
            const total_otherSpecify_columns = this.selectedData[i][element.questionId].filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_header'] != 'N/A'));
            if ( otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && !otherSpecify_text) {
              this.setErrorMessage(this.languageData['OtherValue']);
              return;
            } else if (otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && otherSpecify_text){
              this.setErrorMessage(this.languageData['AnswerCheck']);
              return;
            }
          }
        }

        if (this.currentQuestionType == 'SLIDER_CATEGORY') {
          const otherSpecify_answered = this.selectedData[i][element.questionId].filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_value'] !== ''));
          if (otherSpecify_answered.length > 0) {
            const otherSpecify_text = otherSpecify_answered[0]['otherSpecify_value'];
            const total_otherSpecify_columns = this.selectedData[i][element.questionId].filter((el) => (el['selected_item'].toString().match(this.questionsPreviewService.pleaseSpecifyMatch) && el['selected_header'] != 'N/A'));
            if ( otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && !otherSpecify_text) {
              this.setErrorMessage(this.languageData['OtherValue']);
              return;
            } else if (otherSpecify_answered.length != 0  && total_otherSpecify_columns.length != otherSpecify_answered.length && otherSpecify_text){
              this.setErrorMessage(this.languageData['AnswerCheck']);
              return;
            } else if (otherSpecify_answered.length != 0  && total_otherSpecify_columns.length == otherSpecify_answered.length && !otherSpecify_text) {
              this.setErrorMessage(this.languageData['OtherValue']);
              return;
            }
          }
        }
        
        /**Checks for other specify value */
        

        if (otherInd > -1 && (this.selectedData[i][element.questionId][otherInd] && this.selectedData[i][element.questionId][otherInd]['selected_value'] && !this.selectedData[i][element.questionId][otherInd]['otherSpecify_value']) ||
          (this.selectedData[i][element.questionId][otherInd] && this.selectedData[i][element.questionId][otherInd]['otherSpecify_value'] && (this.selectedData[i][element.questionId][otherInd]['selected_value'] == '' && this.selectedData[i][element.questionId][otherInd]['selected_value'] !== 0))) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['OtherValue'] });
          this.setMultipleErrorMessage(this.errList);
          return;
        }

        this.selectedData[i][element.questionId].forEach(elementR => {
          if (elementR.option_type === 'Single choice' && elementR.otherSpecify_value && !elementR.selected_item.match(this.questionsPreviewService.pleaseSpecifyMatch)) {
            single_other_specify_warning = true;
          }
        });
        if (single_other_specify_warning) {
          // this.setErrorMessage('Please select Other (Please Specify) option');
          this.setMultipleErrorMessage('Please specify Other value');
          return;
        }
      }
    });

    this.questionDetailsList.forEach((element, i) => {
      let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(element, i);
      const onsubmitRules = instructionDetailsMultipleQuestion.get('ONSUBMIT');
      // const onsubmitRules = this.instructionDetails[element.questionId] ? this.instructionDetails[element.questionId].get('ONSUBMIT') : false;
      if (onsubmitRules) {
        for (const rule of onsubmitRules) {
          let funName = rule['instdet'].substring(0, rule['instdet'].indexOf("(")).toLowerCase();
          let funParams = rule['instdet'].substring(rule['instdet'].indexOf("(") + 1, rule['instdet'].length - 1);
          let params = funParams.split("##");

          if (funName === 'do_not_force_response' && this.selectedData[i][element.questionId] && this.selectedData[i][element.questionId].length > 0) {
            this.questionsPreviewService.currentSelectedData = this.selectedData[i][element.questionId];
            const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg'], element);
          } else if (funName === 'do_not_force_response' && funParams === '####' && (this.selectedData[i][element.questionId] == null || this.selectedData[i][element.questionId].length == 0)) { /** Not selected any data and question level rule */
            // this.getNextQuestion();
            checkRankingDNF = true;
            break;
          }

          /** DRAG AND DROP with atleast one data selected - column or question level rule */
          if (funName === 'do_not_force_response' && (params[0] === 'column' || funParams === '####')) {
            if (element['questionType'] == 'Ranking' && element.Instructions[0]['instruction'] == 'RANK TYPE = DRAG AND DROP') {
              if (this.selectedData[i][element.questionId] == null || this.selectedData[i][element.questionId].length == 0) {
                checkRankingDNF = true;
                //this.getNextQuestion();
              }
            } else if (['Single choice'].includes(element['questionType']) && (this.selectedData[i][element.questionId] == null || this.selectedData[i][element.questionId].length == 0) && params[0] === 'column') {
              let answeredFlag = true;
              element.Options.columns.forEach(elementR => {
                if (elementR.answer && elementR.columnId && !(params[1].includes(elementR.columnId))) {
                  answeredFlag = false;
                }
              });
              if (!answeredFlag) {
                this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
                this.setMultipleErrorMessage(this.errList);
              }
              return;
            } else if (['Single choice'].includes(element['questionType']) && (this.selectedData[i][element.questionId] && this.selectedData[i][element.questionId].length > 0)) {
              if (funParams === '####') {
                checkRankingDNF = false;
                break;
              } else if (params[0] === 'column') {
                let answered = true;
                let noRuleColumn = [];
                let selectedHeaders = [];
                element.Options.columns.forEach(elementR => {
                  if (elementR.answer && elementR.columnId && !(params[1].includes(elementR.columnId))) {
                    noRuleColumn.push(elementR.columnId);
                  }
                });
                this.selectedData[i][element.questionId].forEach(el => {
                  selectedHeaders.push(el.selected_header);
                });
                for (const colmn of noRuleColumn) {
                  if (!(selectedHeaders.includes(colmn))) {
                    answered = false;
                  }
                }
                if (!answered) {
                  this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
                  this.setMultipleErrorMessage(this.errList);
                }
                return;
              }
            }
          }


          /** Multiselect with do not force */
          if (funName === 'do_not_force_response' && ['Select all that apply'].includes(element['questionType']) && this.selectedData[i][element.questionId] && this.selectedData[i][element.questionId].length > 0) {
            let answered = true;
            this.selectedData[i][element.questionId].forEach(el => {
              if (!el['doNotForce'] && el['selected_value'] !== 1) {
                answered = false;
                checkRankingDNF = true;
              }
            });
            if (!answered) {
              this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
              this.setMultipleErrorMessage(this.errList);
            }
            return;
          }
        }
      }

      // onload of upto function validation starts
      instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(element, i);
      this.questionsPreviewService.currentQuestionOptions = element.Options;
      const onloadRules = instructionDetailsMultipleQuestion.get('ONLOAD');
      // const onloadRules = this.instructionDetails[element.questionId] ? this.instructionDetails[element.questionId].get('ONLOAD') : false;

      // const onloadRules = this.instructionDetails.get('ONSUBMIT');
      if (onloadRules) {
        let upto_rule = [];
        let question = element;
        // this.questionsPreviewService.cu
        this.questionsPreviewService.onScreenDisable = '';
        onloadRules.forEach((rule) => {
          let fun_name = rule['instdet'].substring(0, rule['instdet'].indexOf("("));
          if (['choose', 'atleast', 'atmost'].indexOf(fun_name) > -1) {
            const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
            upto_rule.push(response);
          }
        });
        if (upto_rule.length > 0) {
          let setError = '';
          let uptoValid = true;
          let multipleUptoValid = true;
          upto_rule.forEach(element => {
            if (element && element.type === 'choose' && this.selectedData[i] && this.selectedData[i][question.questionId].length > 0 && (['Select all that apply', 'Ranking'].indexOf(question['questionType']) > -1 || (['Combination'].indexOf(question['questionType']) > -1 && ['Select all that apply And Single choice', 'Ranking And Single choice'].indexOf(question['combinationTypes'])) > -1)) {
              if (element.ruleOn == 'COLUMNS') {
                const selectCount = this.selectedData[i][question.questionId].filter(el => el.selected_header == element.field_id && el.selected_value);
                const headerColumn = question.Options.columns.filter(el => el.columnId == element.field_id);
                const headerName = headerColumn[0].header.split('\n')[0];
                const allUnansweredArray = this.selectedData[i][question.questionId].filter(el => el.selected_value === 0);
                const allUnanswered = this.selectedData[i][question.questionId].length == allUnansweredArray.length ? true : false;
                if (((selectCount.length !== element.upto_count) && (selectCount[0] && selectCount[0].option_type !== "Single choice")) || (selectCount.length == 0)) {
                  if (allUnanswered) {
                    this.errList.push({ 'id': question['questionId'], 'msg': this.languageData['AnswerCheck'] });
                    this.setMultipleErrorMessage(this.errList);
                    multipleUptoValid = false;
                  } else if (selectCount.length == 0 && !allUnanswered) {
                    setError += 'Please choose ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                    uptoValid = false;
                  } else {
                    setError += 'Please choose ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                    uptoValid = false;
                  }
                }
              } else if (element.ruleOn == 'ROWS') {
                const answered = this.selectedData[i][question.questionId].filter(el => el.selected_value);
                if (answered.length == 0) {
                  this.errList.push({ 'id': question['questionId'], 'msg': this.languageData['AnswerCheck'] });
                  this.setMultipleErrorMessage(this.errList);
                  multipleUptoValid = false;
                }
                const selectCount = this.selectedData[i][question.questionId].filter(el => el.selected_item == element.field_id && el.selected_value);
                const headerRow = element.field_id;
                if (selectCount.length !== element.upto_count) {
                  setError += 'Please choose ' + element.upto_count + ' option in ' + headerRow + ' row' + '<br>';
                  uptoValid = false;
                }
              }

            } else if (element && element.type === 'atmost' && this.selectedData[i] && this.selectedData[i][question.questionId].length > 0 && (['Select all that apply', 'Ranking'].indexOf(question['questionType']) > -1 || (['Combination'].indexOf(question['questionType']) > -1 && ['Select all that apply And Single choice', 'Ranking And Single choice'].indexOf(question['combinationTypes'])) > -1)) {
              if (element.ruleOn === 'COLUMNS') {
                const selectCount = this.selectedData[i][question.questionId].filter(el => el.selected_header == element.field_id && el.selected_value);
                const headerColumn = question.Options.columns.filter(el => el.columnId == element.field_id);
                const headerName = headerColumn[0].header.split('\n')[0];
                if (selectCount.length > element.upto_count || selectCount.length == 0) {
                  if (selectCount.length == 0) {
                    // const answeredInd = this.selectedData.findIndex(el => el['selected_value'] == 1);
                    this.errList.push({ 'id': question['questionId'], 'msg': this.languageData['AnswerCheck'] });
                    this.setMultipleErrorMessage(this.errList);
                    multipleUptoValid = false;
                  }
                  else {
                    setError += 'Please select upto ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                    uptoValid = false;
                  }
                }
              } else if (element.ruleOn === 'ROWS') {
                const answered = this.selectedData[i][question.questionId].filter(el => el.selected_value);
                if (answered.length == 0) {
                  this.errList.push({ 'id': question['questionId'], 'msg': this.languageData['AnswerCheck'] });
                  this.setMultipleErrorMessage(this.errList);
                  multipleUptoValid = false;
                }
                const selectCount = this.selectedData[i][question.questionId].filter(el => el.selected_item == element.field_id && el.selected_value);
                const headerRow = element.field_id;
                if (selectCount.length > element.upto_count) {
                  setError += 'Please select upto ' + element.upto_count + ' option in ' + headerRow + ' row' + '<br>';
                  uptoValid = false;
                }
              }

            } else if (element && element.type === 'atleast' && this.selectedData[i] && this.selectedData[i][question.questionId].length > 0 && (['Select all that apply', 'Ranking'].indexOf(question['questionType']) > -1 || (['Combination'].indexOf(question['questionType']) > -1 && ['Select all that apply And Single choice', 'Ranking And Single choice'].indexOf(question['combinationTypes'])) > -1)) {
              if (element.ruleOn == 'COLUMNS') {
                const selectCount = this.selectedData[i][question.questionId].filter(el => el.selected_header === element.field_id && el.selected_value);
                const headerColumn = question.Options.columns.filter(el => el.columnId === element.field_id);
                const headerName = headerColumn[0].header.split('\n')[0];
                const allUnansweredArray = this.selectedData[i][question.questionId].filter(el => el.selected_value === 0);
                const allUnanswered = this.selectedData[i][question.questionId].length == allUnansweredArray.length ? true : false;
                if (((selectCount.length < element.upto_count) && (selectCount[0] && selectCount[0].option_type !== "Single choice")) || (selectCount.length == 0)) {
                  if (allUnanswered) {
                    this.errList.push({ 'id': question['questionId'], 'msg': this.languageData['AnswerCheck'] });
                    this.setMultipleErrorMessage(this.errList);
                    multipleUptoValid = false;
                  } else if (selectCount.length == 0 && !allUnanswered) {
                    uptoValid = false;
                    setError += 'Please select atleast ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                  } else {
                    setError += 'Please select atleast ' + element.upto_count + ' option in ' + headerName + ' column' + '<br>';
                    uptoValid = false;
                  }
                }
              } else if (element.ruleOn == 'ROWS') {
                const answered = this.selectedData[i][question.questionId].filter(el => el.selected_value);
                if (answered.length == 0) {
                  this.errList.push({ 'id': question['questionId'], 'msg': this.languageData['AnswerCheck'] });
                  this.setMultipleErrorMessage(this.errList);
                  multipleUptoValid = false;
                }
                const selectCount = this.selectedData[i][question.questionId].filter(el => el.selected_item == element.field_id && el.selected_value);
                const headerRow = element.field_id;
                if (selectCount.length < element.upto_count) {
                  setError += 'Please select atleast ' + element.upto_count + ' option in ' + headerRow + ' row' + '<br>';
                  uptoValid = false;
                }
              }

            }
          });
          if (!uptoValid) {
            this.errList.push({ 'id': question['questionId'], 'msg': setError });
            this.setMultipleErrorMessage(this.errList);
            return;
          } else if (uptoValid && !multipleUptoValid) {
            this.setMultipleErrorMessage(this.errList);
            return;
          } else {
            this.errorMsgShow = false;
            // this.errList = [];
          }
        }
      }
      // onload of upto function validation ends

      if (['Single choice'].includes(element['questionType'])) {
        /**Single select including single select multi column */
        let selectedHeaders = []
        this.selectedData[i][element.questionId].forEach(elementRes => {
          selectedHeaders.push(elementRes.selected_header);
        });
        let reduceColumn = [];
        let reduce = 0;
        element['Options']['columns'].forEach((col, ind) => {
          if (ind > 1 && !(selectedHeaders.includes(col.columnId))) {
            element['Options']['rows'].forEach((row) => {
              if (row[ind].value == 'Single choice' && row[ind].disable) {
                if (reduceColumn[ind] == undefined) {
                  reduceColumn[ind] = 1;
                } else {
                  reduceColumn[ind] += 1;
                }
              }
            });
          }
        });
        element['Options']['columns'].forEach((elem, ind) => {
          if (reduceColumn[ind] == element['Options']['rows'].length) {
            reduce++;
          }
        });

        if ((this.selectedData[i][element.questionId].length != element['Options']['columns'].length - 2 - reduce) && checkRankingDNF == false) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
        }
      } else if (['Select all that apply'].includes(element['questionType'])) {
        /**Multiselect */
        const answeredInd = this.selectedData[i][element.questionId].findIndex(el => el['selected_value'] == 1);
        if ((answeredInd < -1 || answeredInd == null) && checkRankingDNF == false) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList)
        }
      } else if (['Text', 'Large Text', 'Number', 'Percent', 'SLIDER', 'SLIDER_CATEGORY', 'Date', 'DROP-DOWN'].includes(element['questionType'])) {
        /**
         * Text, number,percent,slider,date,dropdown
         */

        let answeredInd;
        if (element['questionType'] == 'Number' || element['questionType'] == 'Percent') {
          // answeredInd = this.selectedData.findIndex(el => ((el['selected_value'] !== 0 && !el['selected_value']) || el['selected_value'] == 0 || el['selected_value'] == null || el['selected_value'] < 0) && (el['selected_item'].toLowerCase() !== this.OTHER_OPTION || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
          answeredInd = this.selectedData[i][element.questionId].findIndex(el => ((el['selected_value'] === '' || el['selected_value'] < 0) && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == element['other_specify_index']) && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A'));
          // answeredInd = this.selectedData.findIndex(el => (el['selected_value'] == '' || el['selected_value'] == null || el['selected_value'] < 0) && (el['selected_item'].toLowerCase() !== this.OTHER_OPTION || el['selected_row'] == this.questionDetails['other_specify_index']) && !el['disable'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
        } else if (element['questionType'] == 'SLIDER' || element['questionType'] == 'SLIDER_CATEGORY') {
          answeredInd = this.selectedData[i][element.questionId].findIndex(el => el['defaultCell'] && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == element['other_specify_index']) && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
        } else {
          answeredInd = this.selectedData[i][element.questionId].findIndex(el => (el['selected_value'] == '' || el['selected_value'] <= 0) && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == element['other_specify_index']) && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A');
        }
        if ((answeredInd > -1 && answeredInd != null) && checkRankingDNF == false) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
          return;
        }

        if (['Text', 'Large Text', 'Number', 'Percent'].includes(element['questionType'])) {
          /** Update range values */
          let rangeFlag = true, rangeStart, rangeEnd;
          const emptyFlag = true;
          if (element['questionType'] == 'Number') {
            rangeStart = 0, rangeEnd = 9999;
            rangeStart = this._session.api.local.get(Config.CONSTANTS.RANGE_START);
            rangeEnd = this._session.api.local.get(Config.CONSTANTS.NUM_RANGE_END);
          } else if (element['questionType'] == 'Percent') {
            rangeStart = 0, rangeEnd = 100;
            rangeStart = this._session.api.local.get(Config.CONSTANTS.RANGE_START);
            rangeEnd = this._session.api.local.get(Config.CONSTANTS.PER_RANGE_END);
          } else {
            rangeStart = 10, rangeEnd = 500;
            rangeStart = this._session.api.local.get(Config.CONSTANTS.TEXT_START);
            rangeEnd = this._session.api.local.get(Config.CONSTANTS.TEXT_END);
          }
          /****
           * Multi range set;
           */

          const multiRange = this.questionsPreviewService.getmultiRangeConfig();
          const rangeQuestionIds = Object.keys(multiRange);
          if (rangeQuestionIds.length > 0 && rangeQuestionIds.indexOf(element.questionId) > -1) {
            rangeStart = multiRange[element.questionId].start;
            rangeEnd = multiRange[element.questionId].end;
          }
          this.selectedData[i][element.questionId].forEach((row, rowInd) => {
            if (element['questionType'] == 'Number' || element['questionType'] == 'Percent') {
              if ((row['selected_value'] || row['selected_value'] == 0) && (row['selected_value'] < rangeStart || row['selected_value'] > rangeEnd) && row['selected_row'] != 'N/A' && row['selected_header'] != 'N/A' && (!row['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || row['selected_row'] == element['other_specify_index']) && !row['disable'] && !row['doNotForce']) {
                rangeFlag = false;
                this.errorMsgShow = true;
                const col = row['selected_header'];
                let colIdx = -1;
                element['Options']['columns'].find(function (c, i) {
                  if (c['columnId'] === col) {
                    colIdx = i;
                    return i;
                  }
                });
                if (colIdx > -1) {
                  element['Options']['rows'].forEach((r) => {
                    const roVal = r[0]['value'];
                    const colRow = r[colIdx];
                    if (roVal && roVal === row['selected_row'] && colRow && colRow['valueType'] !== 'Label') {
                      colRow['invalid'] = true;
                    }
                  });
                }
                return;
              }

            } else {
              if (row['selected_value'] && (row['selected_value'].length < rangeStart || row['selected_value'].length > rangeEnd) && row['selected_row'] != 'N/A' && (!row['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || row['selected_row'] == element['other_specify_index']) && !row['disable'] && !row['doNotForce']) {
                rangeFlag = false;
                this.errorMsgShow = true;
                const col = row['selected_header'];
                let colIdx = -1;
                element['Options']['columns'].find(function (c, i) {
                  if (c['columnId'] === col) {
                    colIdx = i;
                    return i;
                  }
                });
                if (colIdx > -1) {
                  element['Options']['rows'].forEach((r) => {
                    const roVal = r[0]['value'];
                    const colRow = r[colIdx];
                    if (roVal && roVal === row['selected_row'] && colRow && colRow['valueType'] !== 'Label') {
                      colRow['invalid'] = true;
                    }
                  });
                }
                return;
              }
            }
          });
          if (element['questionType'] == 'Number' || element['questionType'] == 'Percent') {
            if (!rangeFlag) {
              this.multipleErrorMsg.push({ 'id': element.questionId, 'msg': this.languageData['PlsEnterbetween'] + ' ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeStart) + ' -  ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeEnd) });
              this.errorMsgShow = true;
              return;
            } else {
              if (this.selectedData[i][element.questionId]) {
                return;
              } else {
                this.errorMsgShow = true;
                this.multipleErrorMsg.push({ 'id': element.questionId, 'msg': this.languageData['AnswerCheck'] });
              }
            }
          } else {
            if (!rangeFlag) {
              this.multipleErrorMsg.push({ 'id': element.questionId, 'msg': this.languageData['EntervalShouldBwChar'] + ' ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeStart) + ' -  ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeEnd) });
              this.errorMsgShow = true;
              return;
            } else {
              if (this.selectedData[i][element.questionId]) {
                return;
              } else {
                this.errorMsgShow = true;
                this.multipleErrorMsg.push({ 'id': element.questionId, 'msg': this.languageData['AnswerCheck'] });
              }
            }
          }
        }

      } else if (element['questionType'] == 'Ranking' && element.Instructions[0]['instruction'] == 'RANK TYPE = DRAG AND DROP' && (!this.atmostEnable && !this.atleastEnable && !this.chooseEnable) && checkRankingDNF == false) {
        if (this.selectedData[i][element.questionId].length != element['Options']['rows'].length) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
        }
      } else if (element['questionType'] == 'Ranking' && element.Instructions[0]['instruction'] == 'RANK TYPE = DRAG AND DROP' && (this.atmostEnable || this.atleastEnable || this.chooseEnable) && checkRankingDNF == false) {
        if (this.selectedData[i][element.questionId].length == 0) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
        } else if (!this.errorMsgShow && (this.errList.length == 0)) {
          return;
        }
      } else if (element['questionType'] == 'Ranking' && element.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (!this.atmostEnable && !this.atleastEnable && !this.chooseEnable) && checkRankingDNF == false) {
        // if (this.selectedData[i][element.questionId].length != element['Options']['rows'].length) {
        //   this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
        //   this.setMultipleErrorMessage(this.errList);
        // }
        let count = 0;
        if (this.selectedData[i] && this.selectedData[i][element.questionId]) {
          this.selectedData[i][element.questionId].forEach((ele, ind) => {
            if ((ele['selected_value'] == '' || ele['selected_value'] == null) && !ele['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch)) {
              count += 1;
            }
          })
        } else if (this.selectedData[i] == undefined) {
          count += 1;
        }

        if (count > 0) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
        } else {
          ;
          return
        }
      } else if (element['questionType'] == 'Ranking' && element.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (this.atmostEnable || this.atleastEnable || this.chooseEnable) && checkRankingDNF == false) {
        if (!this.selectedData[i]) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
        } else if (!this.errorMsgShow && (this.errList.length == 0)) {
          return;
        }
      } else if (element['questionType'] == 'Combination' || element['questionType'] == 'Ranking') {
        /** Combination and ranking-2 */

        if (element['combinationTypes'] == 'Select all that apply And Single choice' && checkRankingDNF == false) {
          /** multi + single choice */
          const Ind = this.selectedData[i][element.questionId].findIndex(el => (el['selected_value'] == 1 && el['option_type'] == 'Single choice') || el['option_type'] == 'Select all that apply' && el['selected_value'] == 1);
          this.translate.get('AnswerCheck').subscribe((text: string) => {
            if (Ind > -1 && Ind != null) {
              return;
            } else {
              this.errList.push({ 'id': element['questionId'], 'msg': text });
              this.setMultipleErrorMessage(this.errList);
            }
          });
        } else if (['Number And Single choice', 'Percent And Single choice', 'Text And Single choice', 'DROP-DOWN And Single choice', 'Date And Single choice', 'Ranking And Single choice'].includes(element['combinationTypes']) && element.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (!this.atmostEnable && !this.atleastEnable && !this.chooseEnable) && checkRankingDNF == false) {
          /**
           * (Number, percent,text,dropdown,date) + (single choice)
           */
          let Ind1, Ind2;
          // if (this.questionDetails['combinationTypes'] == 'Ranking And Single choice') {
          //   Ind1 = this.selectedData.findIndex(el => el['selected_value'] === el['selected_row'] && el['option_type'] == 'Single choice' && !el['disable']);
          // } else {
          Ind1 = this.selectedData[i][element.questionId].findIndex(el => el['selected_value'] == 1 && el['option_type'] == 'Single choice');
          // }
          Ind2 = this.selectedData[i][element.questionId].findIndex(el => ['Ranking', 'DROP-DOWN', 'Date', 'Number', 'Percent', 'Text', 'Large Text'].includes(el['option_type']) && ((el['selected_value'] !== 0 && !el['selected_value']) || el['selected_value'] == null || el['selected_value'] < 0) && el['selected_row'] != 'N/A' && el['selected_header'] != 'N/A' && !el['disable'] && !el['doNotForce'] && !el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch));
          if ((Ind1 > -1 && Ind2 > -1) || (Ind1 < 0 && Ind2 < 0)) {
            return;
          } else {
            this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
            this.setMultipleErrorMessage(this.errList);
          }
        } else if (['Select all that apply And Number', 'Select all that apply And Text'].includes(element['combinationTypes']) && checkRankingDNF == false) {
          const Ind1 = this.selectedData[i][element.questionId].findIndex(el => el['selected_value'] == 1 && el['option_type'] == 'Select all that apply');
          const Ind2 = this.selectedData[i][element.questionId].findIndex(el => (el['selected_value'] == null || el['selected_value'] < 0 || !el['selected_value']) &&
            (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == element['other_specify_index']) || el['selected_row'] == element['other_specify_index'] && !el['disable'] && !el['doNotForce'] && el['selected_row'] != 'N/A' &&
            el['selected_header'] != 'N/A');
          if ((Ind1 > -1 && Ind2 <= -1)) {
            return;
          } else {
            this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
            this.setMultipleErrorMessage(this.errList);
          }
          if ((Ind1 > -1 && Ind2 > -1) || (Ind1 < 0 && Ind2 < 0)) {
            return;
          } else {
            this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
            this.setMultipleErrorMessage(this.errList);
          }
        } else if (['Number And Single choice', 'Percent And Single choice', 'Text And Single choice', 'DROP-DOWN And Single choice', 'Date And Single choice', 'Ranking And Single choice'].includes(element['combinationTypes']) && element.Instructions[0]['instruction'] !== 'RANK TYPE = DRAG AND DROP' && (this.atmostEnable || this.atleastEnable || this.chooseEnable) && checkRankingDNF == false) {
          if (!this.errorMsgShow && this.errList.length == 0) {
            return;
          }
        }

      } else if (element['questionType'] == 'Rating' && checkRankingDNF == false) {
        /**RATING */
        const answeredInd = this.selectedData[i][element.questionId].findIndex(el => el['selected_value'] == null || !el['selected_value'] && (!el['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch) || el['selected_row'] == element['other_specify_index']) && !el['disable'] && !el['doNotForce']);
        if (answeredInd > -1 && answeredInd != null) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
        }
      } else if (element['questionType'] == 'Message_single_type' || element['questionType'] == 'Message_drag_and_drop_type' && checkRankingDNF == false) {
        const answeredInd = this.selectedData[i][element.questionId].findIndex(el => el['selected_value'] == null || !el['selected_value']);
        if (answeredInd > -1 && answeredInd != null) {
          this.errList.push({ 'id': element['questionId'], 'msg': this.languageData['AnswerCheck'] });
          this.setMultipleErrorMessage(this.errList);
        }
      }
    });

    this.multipleErrorMsg.length > 0 ? this.errList = [] : this.errList;
    // this.errList.length == 0 ? this.multipleErrorMsg : this.errList.length;
    if ((this.errList.length == 0) && (this.multipleErrorMsg.length == 0)) {
      this.saveData();
    }
  }
}

fetchData() {
  this._session.api.local.save(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL, 0); /** resetting HOLD_SCREEN_DURATION_FOR_ALL to 0  */
  if (this.languageDetails.language && this.languageDetails.region) {
    let surveyResponse = this._session.api.local.get('SurveyResponse');
    surveyResponse['COUNTRY'] = this.languageDetails.region;
    surveyResponse['LANGUAGE'] = this.languageDetails.language;
    this._session.api.local.save('SurveyResponse', surveyResponse);
  }
  this.messageQuestions = undefined;
  // check if survey is marked for immediate termination
  if (this.questionsPreviewService.terminateSurveyImmediately) {
    const temp = this._session.api.local.get('terminateSurveyQuestionDetails');
    this.surveyStatus = 'terminated';
    this.updateLiveSurveyStatus('Terminated', temp);
    return;
  }
  let skipAndMove = false;
  let inputBase;
  if (this.action === this.FETCH_QUESTION_WITH_QUESTIONID) {
    inputBase = {
      action: this.action,
      current_section: this.current_section,
      current_section_index: this.current_section_index,
      required_question_id: this.required_question_id,
      language: this.languageDetails.language ? this.languageDetails.language : '',
      region: this.languageDetails.region ? this.languageDetails.region : ''
    };
    if (this.isbackToSurvey) {
      inputBase['required_question_id'] = this.required_question_id ? this.required_question_id : this.current_question;
    }
  } else if (this.action === this.FETCH_MULTIPLE_QUESTIONS) {
    inputBase = {
      action: this.action,
      current_section: this.current_section,
      current_section_index: this.current_section_index,
      current_question_index: this.current_question_index,
      language: this.languageDetails.language ? this.languageDetails.language : '',
      region: this.languageDetails.region ? this.languageDetails.region : '',
      start_question_id: this.start_question_id,
      end_question_id: this.end_question_id,

    };
  } else if (this.action === 'JumpTo') {
    inputBase = {
      action: 'jumpto_questionID',
      current_section: this.current_section,
      current_section_index: this.current_section_index,
      required_question_id: this.jumpTo.value,
      language: this.languageDetails.language ? this.languageDetails.language : '',
      region: this.languageDetails.region ? this.languageDetails.region : ''
    };
  } else if (this.action === "backTo") {
    inputBase = {
      action: 'backTo',
      current_section: '',
      current_section_index: '',
      language: this.languageDetails.language ? this.languageDetails.language : '',
      region: this.languageDetails.region ? this.languageDetails.region : '',
      current_question: '',
      current_question_index: '',
      current_message_type: this.currentQuestionType ? this.currentQuestionType : '',
      current_message_index: this.currentMessageIndex ? this.currentMessageIndex : 0,
      total_message_count: this.messageLoopCount ? this.messageLoopCount : 0,
    }
  }
  else {
    inputBase = {
      action: this.action,
      current_section: this.current_section,
      current_section_index: this.current_section_index,
      current_question: this.current_question,
      current_question_index: this.current_question_index,
      language: this.languageDetails.language ? this.languageDetails.language : '',
      region: this.languageDetails.region ? this.languageDetails.region : '',

      current_message_type: this.currentQuestionType ? this.currentQuestionType : this.message_type,
      current_message_index: this.currentMessageIndex ? this.currentMessageIndex : 0,
      total_message_count: this.messageLoopCount ? this.messageLoopCount : 0,
    };
  }
  let inputData;
  if (this.checkGenericLink == true) {
    inputData = { ...{ surveyKey: this.gl_surveyKey, participant_id: this.gl_participantId }, ...inputBase };
  } else {
    inputData = { ...{ surveyKey: this.respondentDetails.surveyKey, zs_id: this.respondentDetails.zs_id, participant_id: this.respondentDetails.participant_id }, ...inputBase };
  } this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_QUESTION_DETAILS, inputData).subscribe(response => {
    if (response && response['status'] === 'success') {
      this.checkPage = false; /** Single questions always keep false */
      const result = response['result'];
      this.questionDetailOptions = response['result']['Options']; /** ///Added for reuse the data */
      this.currentVersionId = response['result'].version_id;
      this.questionsPreviewService.currentVersionId = this.currentVersionId;
      this.message_type = result['message_type'];
      this.currentQuestionType = result['questionType'];
      // this.currentCombinationType = result['combinationTypes'] ? result['combinationTypes'] : '';
      this.currentQuestionId = result['questionId'];
      if (response['multi_flag'] && response['multi_flag'] == true) {
        this.checkMultiReponse = true;
      }
      else this.checkMultiReponse = false;

      if (result["questionType"] === "Message_single_type" || result["questionType"] === "Message_drag_and_drop_type") {
        this.isMessageQuestions = true;
        this.fetchMessageQuestions();
      } else {
        this.isMessageQuestions = false;
      }

      if (result['questionType'] != 'Blank Table' && this.document.getElementById('nextBtn')) {
        this.document.getElementById('nextBtn').disabled = false;
      }
      /** Update default ranges after loading next question*/
      this._session.api.local.save(Config.CONSTANTS.ATMOST, false);
      this._session.api.local.save(Config.CONSTANTS.ATLEAST, false);
      this._session.api.local.save(Config.CONSTANTS.CHOOSE, false);
      this._session.api.local.save(Config.CONSTANTS.RANGE_START, 0);
      this._session.api.local.save(Config.CONSTANTS.NUM_RANGE_END, 9999);
      this._session.api.local.save(Config.CONSTANTS.PER_RANGE_END, 100);
      this._session.api.local.save(Config.CONSTANTS.PRECISION, 0);
      this._session.api.local.save(Config.CONSTANTS.TEXT_START, 10);
      this._session.api.local.save(Config.CONSTANTS.TEXT_END, 500);
      this._session.api.local.save(Config.CONSTANTS.MINIMUM_RESPONSE, 1);
      this._session.api.local.save(Config.CONSTANTS.HOLD_SCREEN_DURATION, 30);
      this._session.api.local.save(Config.CONSTANTS.HOLD_SCREEN_DURATION_FOR_ALL, 0);
      this._session.api.local.save(Config.CONSTANTS.DEFAULT_VALUE, null);
      this._session.api.local.save('Field_precision', null);

      this._session.api.local.save("QuestionNumberMap", {});


      if (result === 'Survey Completd' || result === 'All the questions in the section are completed') {
        if (this.checkRedirectURL == true) {
          this.updateLiveSurveyStatus('Completed');
          this.surveyStatus = 'completed';
          setTimeout(() => window.location.href = this.redirectURLCompleted, this.timeToRedirect)
        } else {
          this.surveyStatus = 'completed';
          this.updateLiveSurveyStatus('Completed');
        }
      } else {
        this.total_section_questions = result['total_section_questions'];
        this.total_survey_questions = result['total_survey_questions'];
        this.total_completed_questions = result['total_completed_questions'];
      }
      if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
        this.questionDetailsList = result;
        this.checkquestionDetails = true;
        // this.selectedData = [];
        this.fetchMultipleQns();
      } else if (this.action === this.NEXT_SECTION) {
        // if (this.action === this.NEXT_SECTION) {
        // check if survey is marked for termination
        if (this.questionsPreviewService.terminateSurvey) {
          const temp = this._session.api.local.get('terminateSurveyQuestionDetails');
          this.surveyStatus = 'terminated';
          this.updateLiveSurveyStatus('Terminated', temp);
          return;
        }
        /** If action nextsection and first question is page start then will change action to fetch_multiple_questions */
        this.fetchMultiple = this._session.api.local.get(Config.CONSTANTS.MULTIPLE_QUESTIONS);
        if ((result && result.action == 'fetch_multiple_questions') || ((this.fetchMultiple && this.fetchMultiple.hasOwnProperty(this.lastQuestionIndex)) && (this.action === this.NEXT_SECTION))) {
          this.action = this.FETCH_MULTIPLE_QUESTIONS;
          this.questionDetailsList = []
        }
        else {
          this.action = this.NEXT_QUESTION;
          this.questionDetails = null;
        }
        this.current_section = result['name'];
        this.current_section_index = this.questionsPreviewService.terminateSurvey ? this.current_section_index : result['section_index'];
        // this.current_section_index = result['section_index'];
        this.total_section_questions = result['total_section_questions'];
        this.total_survey_questions = result['total_survey_questions'];
        this.total_completed_questions = result['total_completed_questions'];
        this.current_question = '';
        this.current_question_index = '';
        if (this.checkGenericLink == true) {
          if (this.validateCompleteQuestion) {
            this.fetchGenericSurvey();
            return;
          } else {
            if (this.initialSurveyOpen) {
              this.updateLiveSurveyStatus('Started');
            }
            this.fetchData();
          }
        } else {
          if (this.validateCompleteQuestion) {
            this.completeQuestionLists();
            return;
          } else {
            if (this.initialSurveyOpen) {
              this.updateLiveSurveyStatus('Started');
            }
            this.fetchData();
          }
        }
      }
      if ([this.PREV_QUESTION, this.FETCH_MULTIPLE_QUESTIONS, this.NEXT_QUESTION, this.BACK_TO_SURVEY, this.FETCH_QUESTION_WITH_QUESTIONID].includes(this.action) && Array.isArray(result) == true) {
        this.instructionDetails = [];
        this.questionDetailsList = result;
        this.checkquestionDetails = true;
        this.checkPage = (result[0]['question_index'] == 0 && result[0]['section_index'] == 0) ? true : false;
        /** Skip Rule - Page start page end */
        result.forEach((el, i) => {
          if (this.questionsPreviewService.skipRulesMap.get(el.questionId.toUpperCase()) === true) {
            result.splice(i, 1); /** Removing the question from result array */
          }

          let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(el, i);
          const onloadRules = instructionDetailsMultipleQuestion.get('ONLOAD');
          if (onloadRules) {
            onloadRules.forEach((rule) => {
              if (rule['instdet'].includes('hold_screen')) {
                const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
              }
            });
          }
        });

        result.forEach((element, j) => {
          this.questionsPreviewService.setCurrentQuestionDetails(element);
          this.questionDetailsList = [];
          this.instructionDetails[element.questionId] = new Map();
          this.questionsPreviewService.currentQuestion = null;
          this.questionsPreviewService.currentQuestionOptions = null;
          // this.current_question = element['questionId'];
          // this.current_question_index = response['current_question_index'] ? response['current_question_index'] : result['question_index'];
          this.current_section_index = response['current_section_index'] ? response['current_section_index'] : element['section_index'];
          this.current_question_index = element['question_index'];
          this.terminate_current_question = element['questionId'];
          this.terminate_current_section_index = element['question_index'];
          let lastQuestionId = result[result.length - 1]['questionId'];
          const backToQuestionId = this.questionIdList.filter(el => el.value == lastQuestionId);
          this.displayQuestionIndex = backToQuestionId[0].count;

          const QuestionTypes = this._session.api.local.get('QuestionTypes');
          const tempInd = Object.keys(QuestionTypes).indexOf(element.questionId);
          if (tempInd === -1) {
            if (element.questionId) {
              QuestionTypes[element.questionId.toUpperCase()] = element['questionType'];
            }
          }
          this._session.api.local.save('QuestionTypes', QuestionTypes);
          if (this.action == this.BACK_TO_SURVEY) {
            this.current_section = element['current_section'] ? element['current_section'] : element['section_name']; /** Some case only we are getting 'current_section' */
          }

          // if SKIP rule is present then display based on status of rule evaluation
          // if (this.questionsPreviewService.skipRulesMap.has(element.questionId.toUpperCase())) {
          //   const skipQuestion = this.questionsPreviewService.skipRulesMap.get(element.questionId.toUpperCase());
          //   if (skipQuestion) {
          //     skipAndMove = true;
          //     if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
          //       this.getNextQuestion();
          //     } else if (this.action === this.PREV_QUESTION) {
          //       this.getPrevQuestion();
          //     }
          //   }
          // }
          // if SKIPTO rule is present then navigate to the specified question
          if (this.questionsPreviewService.skipToRulePresent) {
            this.required_question_id = this.questionsPreviewService.skipToQuestion;
            // reset status
            this.questionsPreviewService.skipToRulePresent = false;
            this.questionsPreviewService.skipToQuestion = null;
            // skipAndMove = true;
            // this.getSkiptoQuestion();
          }
          if (!skipAndMove) {
            setTimeout(() => {
              this.questionDetailsList = result;
              // update question data piped variables with actual values like - PIPE , SHOW TEXT and LINK
              element['question'] = this.questionsPreviewService.frameQuestion(element);

              // save question level rules
              if (element['Instructions'].length > 0) {
                element['Instructions'].forEach((instruction) => {
                  if (instruction.parseStatus && instruction.parseStatus === 'Success') {
                    const text = instruction.instruction.indexOf('FIELD')
                    const precisionTest = instruction.instruction.indexOf('PRECISION')

                    if (text > -1 && precisionTest > -1) {
                      instruction.ruleExecutionPhase = "ONLOAD"
                    }
                    if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
                      const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
                      if (this.instructionDetails[element.questionId]) {
                        if (this.instructionDetails[element.questionId].has(instruction.ruleExecutionPhase)) {
                          // this.instructionDetails.get(instruction.ruleExecutionPhase).push(instruction.parsedInstruction);
                          this.instructionDetails[element.questionId].get(instruction.ruleExecutionPhase).push(parseData);
                        } else {
                          // this.instructionDetails.set(instruction.ruleExecutionPhase, [instruction.parsedInstruction]);
                          this.instructionDetails[element.questionId].set(instruction.ruleExecutionPhase, [parseData]);
                        }
                      }
                    }
                    console.log('>>>>', this.instructionDetails)
                  }
                });
              }

              const optionsData = element['Options'];

              // There is no options for messsage questions.
              if (element['questionType'] !== "Message_single_type" && element['questionType'] !== "Message_drag_and_drop_type") {
                // save column level rules -
                optionsData['columns'].forEach((column) => {
                  const parseStatus = column['parseStatus'];
                  if (parseStatus && parseStatus === 'Success') {
                    const inst = column['parsedInstruction'];
                    if (inst) {
                      const execPhase = column['ruleExecutionPhase'];
                      //handle pipe rules  -
                      if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null);
                        if (result) {
                          const header = column['header'];
                          if (header) {
                            column['header'] = header + ' ' + result;
                          } else {
                            column['header'] = result;
                          }
                        }
                      } else {
                        const parseData = { instdet: inst, errmsg: null };
                        if (this.instructionDetails[element.questionId]) {
                          if (this.instructionDetails[element.questionId].has(execPhase)) {
                            // this.instructionDetails.get(execPhase).push(inst);
                            this.instructionDetails[element.questionId].get(execPhase).push(parseData);
                          } else {
                            // this.instructionDetails.set(execPhase, [inst]);
                            this.instructionDetails[element.questionId].set(execPhase, [parseData]);
                          }
                        }
                      }
                    }
                  }
                });

                // save row level rules -
                optionsData['rows'].forEach((row) => {
                  row.forEach((r) => {
                    const parseStatus = r['parseStatus'];
                    if (parseStatus && parseStatus === 'Success') {
                      const inst = r['parsedInstruction'];
                      let label_name;
                      if (r['option_name']) {
                        label_name = r['option_name'];
                      }
                      if (inst) {
                        const execPhase = r['ruleExecutionPhase'];
                        //handle pipe rules  -
                        if (inst.includes('pipe(') || inst.includes('pipe_conditional(') || inst.includes('pipe_rank(') || inst.includes('pipe_name(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null, null, label_name);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                              // Not required - instead use DEFAULT rule -
                              /*
                              else if(valueType === 'SYMBOL') {
                                let val = r['value'];
                                if(val === 'Number') {
                                  r['number'] = result;
                                } else if(val === 'Percent') {
                                  r['percent'] = result;
                                }
                                // r['disable'] = true;
                              }
                              */
                            }
                          }
                        } else if (inst.includes('image(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                            }
                          }

                        } else {
                          const parseData = { instdet: inst, errmsg: null };
                          if (this.instructionDetails[element.questionId]) {
                            if (this.instructionDetails[element.questionId].has(execPhase)) {
                              // this.instructionDetails.get(execPhase).push(inst);
                              this.instructionDetails[element.questionId].get(execPhase).push(parseData);
                            } else {
                              // this.instructionDetails.set(execPhase, [inst]);
                              this.instructionDetails[element.questionId].set(execPhase, [parseData]);
                            }
                          }
                        }
                      }
                    }
                  });
                });
              }

              // console.log(optionsData);
              // set current question and option values in service
              this.questionsPreviewService.currentQuestion = element.questionId;
              this.questionsPreviewService.currentQuestionOptions = optionsData;
              this.questionsPreviewService.questionType = element['questionType'];
              // execute onload rules here
              let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(element, j);
              const onloadRules = instructionDetailsMultipleQuestion.get('ONLOAD');
              // const onloadRules = this.instructionDetails[element.questionId] ? this.instructionDetails[element.questionId].get('ONLOAD') : false;
              if (onloadRules) {
                this.questionsPreviewService.currentCompleteQuestion = element;

                const Field_Precision_List = [];
                onloadRules.forEach((rule) => {
                  if (!(rule['instdet'].includes('hold_screen'))) {
                    const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
                    this.questionsPreviewService.currentQuestionOptions = optionsData;
                    if (response) {
                      if (this.questionsPreviewService.askRulesMap.has(element.questionId.toUpperCase())) {
                        const showQuestion = this.questionsPreviewService.askRulesMap.get(element.questionId.toUpperCase());
                        // if (!showQuestion) {
                        //   skipAndMove = true;
                        //   if (this.action === this.FETCH_MULTIPLE_QUESTIONS && this.checkPrevious) {
                        //     this.getPrevQuestion();
                        //   } else if (this.action === this.FETCH_MULTIPLE_QUESTIONS) {
                        //     this.getNextQuestion();
                        //   }
                        // }
                      }
                      if (response.type == 'Field_precision') {
                        Field_Precision_List.push(response);
                      }
                    }
                  }
                });
                if (Field_Precision_List.length > 0) {
                  this._session.api.local.save('Field_precision', Field_Precision_List);
                }
              }
            }, 100);
            let QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
            QuestionNumberMap = QuestionNumberMap ? QuestionNumberMap : {};
            QuestionNumberMap[element.questionId.toUpperCase()] = this.displayQuestionIndex;
            this._session.api.local.save('QuestionNumberMap', QuestionNumberMap);
          }
          this.progressPercentage = (element['total_completed_questions'] / element['total_survey_questions']) * 100;
          this.progressPercentage = Math.floor(this.progressPercentage);
        });
      } else if (this.action === this.PREV_SECTION && this.checkMultiplePreviousSection == true) {
        this.current_section = result['name'];
        this.current_section_index = result['section_index'];
        this.current_question = '';
        this.current_question_index = '';
        const input = {
          action: "fetch_previous_details",
          surveyKey: this.respondentDetails.surveyKey,
          participant_id: this.respondentDetails.participant_id,
          current_question: this.current_question,
          current_section_index: this.current_section_index,
          current_question_index: this.current_question_index,
        }
        this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_QUESTION_DETAILS, input).subscribe(response => {
          if (response.result.action == "previous_question") {
            this.current_question = this.action === this.PREV_SECTION ? "" : response.result.current_qn_id;
            this.current_question_index = this.action === this.PREV_SECTION ? "" : response.result.current_question_index;
            this.action = this.PREV_QUESTION;
            this.fetchData();
          } else if (response.result.action == "fetch_multiple_questions") {
            this.action = this.FETCH_MULTIPLE_QUESTIONS;
            this.start_question_id = response.result.start_qn_id;
            this.end_question_id = response.result.end_qn_id;
            this.fetchData();
          }
        })
      }
      else {
        this.checkquestionDetails = false;
        const result = response['result'];
        this.currentQuestionType = result['questionType'];
        // this.currentCombinationType = result['combinationTypes'] ? result['combinationTypes'] : '';
        this.currentQuestionId = result['questionId'];

        if (result["questionType"] === "Message_single_type" || result["questionType"] === "Message_drag_and_drop_type") {
          this.isMessageQuestions = true;
          this.fetchMessageQuestions();
        } else {
          this.isMessageQuestions = false;
        }

        if (result['questionType'] != 'Blank Table' && this.document.getElementById('nextBtn')) {
          this.document.getElementById('nextBtn').disabled = false;
        }

        if (result === 'Survey Completd' || result === 'All the questions in the section are completed') {
          this.surveyStatus = 'completed';
          this.updateLiveSurveyStatus('Completed');
        } else {
          this.total_section_questions = result['total_section_questions'];
          this.total_survey_questions = result['total_survey_questions'];
          this.total_completed_questions = result['total_completed_questions'];
        }
        if (this.action === this.NEXT_SECTION) {
          // if (this.action === this.NEXT_SECTION) {
          // check if survey is marked for termination
          if (this.questionsPreviewService.terminateSurvey) {
            const temp = this._session.api.local.get('terminateSurveyQuestionDetails');
            this.surveyStatus = 'terminated';
            this.updateLiveSurveyStatus('Terminated', temp);
            return;
          }
          this.questionDetails = null;
          this.current_section = result['name'];
          this.current_section_index = this.questionsPreviewService.terminateSurvey ? this.current_section_index : result['section_index'];
          // this.current_section_index = result['section_index'];
          this.total_section_questions = result['total_section_questions'];
          this.total_survey_questions = result['total_survey_questions'];
          this.total_completed_questions = result['total_completed_questions'];
          this.current_question = '';
          this.current_question_index = '';
          this.action = this.NEXT_QUESTION;
          if (this.validateCompleteQuestion) {
            this.completeQuestionLists();
            return;
          } else {
            if (this.initialSurveyOpen) {
              this.updateLiveSurveyStatus('Started');
            }
            this.fetchData();
          }
        }
        if ([this.PREV_QUESTION, this.NEXT_QUESTION, this.BACK_TO_SURVEY].includes(this.action)) {
          this.questionsPreviewService.setCurrentQuestionDetails(response['result']);
          this.questionDetails = null;
          this.instructionDetails = new Map();
          this.questionsPreviewService.currentQuestion = null;
          this.questionsPreviewService.currentQuestionOptions = null;
          this.current_question = result['questionId'];
          if (this.action == this.BACK_TO_SURVEY) {
            this.current_section = result['current_section'] ? result['current_section'] : result['section_name']; /** Some case only we are getting 'current_section' */
          }
          // this.current_question_index = response['current_question_index'] ? response['current_question_index'] : result['question_index'];
          this.current_section_index = response['current_section_index'] ? response['current_section_index'] : result['section_index'];
          this.current_question_index = result['question_index'];
          this.terminate_current_question = result['questionId'];
          this.terminate_current_section_index = result['question_index'];
          const QuestionTypes = this._session.api.local.get('QuestionTypes');
          const tempInd = Object.keys(QuestionTypes).indexOf(this.current_question);
          if (tempInd === -1) {
            if (this.current_question) {
              QuestionTypes[this.current_question.toUpperCase()] = result['questionType'];
            }
          }
          this._session.api.local.save('QuestionTypes', QuestionTypes);

          // if ASK rule is present then display based on status of rule evaluation
          // if (this.questionsPreviewService.askRulesMap.has(this.current_question.toUpperCase())) {
          //   console.log(this.questionsPreviewService.askRulesMap);
          //   const showQuestion = this.questionsPreviewService.askRulesMap.get(this.current_question.toUpperCase());
          //   console.log('ASK rule status for question - ' + this.current_question + ', status - ' + showQuestion + ', action - ' + this.action);
          //   if (!showQuestion) {
          //     skipAndMove = true;
          //     if (this.action === this.NEXT_QUESTION) {
          //       this.getNextQuestion();
          //     } else if (this.action === this.PREV_QUESTION) {
          //       this.getPrevQuestion();
          //     }
          //   }
          // }

          // if SKIP rule is present then display based on status of rule evaluation
          if (this.questionsPreviewService.skipRulesMap.has(this.current_question.toUpperCase())) {
            console.log(this.questionsPreviewService.skipRulesMap);
            const skipQuestion = this.questionsPreviewService.skipRulesMap.get(this.current_question.toUpperCase());
            console.log('SKIP rule status for question - ' + this.current_question + ', status - ' + skipQuestion + ', action - ' + this.action);
            if (skipQuestion) {
              skipAndMove = true;
              if (this.action === this.NEXT_QUESTION) {
                this.getNextQuestion();
              } else if (this.action === this.PREV_QUESTION) {
                this.getPrevQuestion();
              }
            }
          }
          // if SKIPTO rule is present then navigate to the specified question
          if (this.questionsPreviewService.skipToRulePresent) {
            this.required_question_id = this.questionsPreviewService.skipToQuestion;
            // reset status
            this.questionsPreviewService.skipToRulePresent = false;
            this.questionsPreviewService.skipToQuestion = null;

            console.log('SKIPTO rule - move to question - ' + this.required_question_id);
            // skipAndMove = true;
            // this.getSkiptoQuestion();
          }
          console.log('skipAndMove - ' + skipAndMove);
          if (!skipAndMove) {
            setTimeout(() => {
              this.questionDetails = result;
              this.questionsPreviewService.onScreenDisable = '';

              // update question data piped variables with actual values like - PIPE , SHOW TEXT and LINK
              this.questionDetails['question'] = this.questionsPreviewService.frameQuestion(this.questionDetails);

              // save question level rules
              if (result['Instructions'].length > 0) {
                result['Instructions'].forEach((instruction) => {
                  if (instruction.parseStatus && instruction.parseStatus === 'Success') {
                    const text = instruction.instruction.indexOf('FIELD')
                    const precisionTest = instruction.instruction.indexOf('PRECISION')

                    if (text > -1 && precisionTest > -1) {
                      instruction.ruleExecutionPhase = "ONLOAD"
                    }
                    if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
                      const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
                      if (this.instructionDetails.has(instruction.ruleExecutionPhase)) {
                        // this.instructionDetails.get(instruction.ruleExecutionPhase).push(instruction.parsedInstruction);
                        this.instructionDetails.get(instruction.ruleExecutionPhase).push(parseData);
                      } else {
                        // this.instructionDetails.set(instruction.ruleExecutionPhase, [instruction.parsedInstruction]);
                        this.instructionDetails.set(instruction.ruleExecutionPhase, [parseData]);
                      }
                    }
                  }
                });
              }

              const optionsData = this.questionDetails['Options'];

              // There is no options for messsage questions.
              if (this.currentQuestionType !== "Message_single_type" && this.currentQuestionType !== "Message_drag_and_drop_type") {
                // save column level rules -
                optionsData['columns'].forEach((column) => {
                  const parseStatus = column['parseStatus'];
                  if (parseStatus && parseStatus === 'Success') {
                    const inst = column['parsedInstruction'];
                    if (inst) {
                      const execPhase = column['ruleExecutionPhase'];
                      //handle pipe rules  -
                      if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null);
                        if (result) {
                          const header = column['header'];
                          if (header) {
                            column['header'] = header + ' ' + result;
                          } else {
                            column['header'] = result;
                          }
                        }
                      } else {
                        const parseData = { instdet: inst, errmsg: null };
                        if (this.instructionDetails.has(execPhase)) {
                          // this.instructionDetails.get(execPhase).push(inst);
                          this.instructionDetails.get(execPhase).push(parseData);
                        } else {
                          // this.instructionDetails.set(execPhase, [inst]);
                          this.instructionDetails.set(execPhase, [parseData]);
                        }
                      }
                    }
                  }
                });

                // save row level rules -
                optionsData['rows'].forEach((row) => {
                  row.forEach((r) => {
                    const parseStatus = r['parseStatus'];
                    if (parseStatus && parseStatus === 'Success') {
                      const inst = r['parsedInstruction'];
                      let label_name;
                      if (r['option_name']) {
                        label_name = r['option_name'];
                      }
                      if (inst) {
                        const execPhase = r['ruleExecutionPhase'];
                        //handle pipe rules  -
                        if (inst.includes('pipe(') || inst.includes('pipe_conditional(') || inst.includes('pipe_rank(') || inst.includes('pipe_name(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null, null, label_name);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                              // Not required - instead use DEFAULT rule -
                              /*
                              else if(valueType === 'SYMBOL') {
                                let val = r['value'];
                                if(val === 'Number') {
                                  r['number'] = result;
                                } else if(val === 'Percent') {
                                  r['percent'] = result;
                                }
                                // r['disable'] = true;
                              }
                              */
                            }
                          }
                        } else if (inst.includes('image(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null);
                          if (result && result !== undefined) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                            }
                          }

                        } else {
                          const parseData = { instdet: inst, errmsg: null };
                          if (this.instructionDetails.has(execPhase)) {
                            // this.instructionDetails.get(execPhase).push(inst);
                            this.instructionDetails.get(execPhase).push(parseData);
                          } else {
                            // this.instructionDetails.set(execPhase, [inst]);
                            this.instructionDetails.set(execPhase, [parseData]);
                          }
                        }
                      }
                    }
                  });
                });
              }

              // console.log(optionsData);
              // set current question and option values in service
              this.questionsPreviewService.currentQuestion = this.current_question;
              this.questionsPreviewService.currentQuestionOptions = optionsData;
              this.questionsPreviewService.questionType = this.questionDetails['questionType'];
              // execute onload rules here
              const onloadRules = this.instructionDetails.get('ONLOAD');
              if (onloadRules) {
                this.questionsPreviewService.currentCompleteQuestion = response['result'];

                const Field_Precision_List = [];
                onloadRules.forEach((rule) => {
                  const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
                  this.questionsPreviewService.currentQuestionOptions = optionsData;
                  if (response) {
                    if (this.questionsPreviewService.askRulesMap.has(this.current_question.toUpperCase())) {
                      const showQuestion = this.questionsPreviewService.askRulesMap.get(this.current_question.toUpperCase());
                      // if (!showQuestion) {
                      //   skipAndMove = true;
                      //   if (this.action === this.NEXT_QUESTION) {
                      //     this.getNextQuestion();
                      //   } else if (this.action === this.PREV_QUESTION) {
                      //     this.getPrevQuestion();
                      //   }
                      // }
                    }
                    if (response.type == 'Field_precision') {
                      Field_Precision_List.push(response);
                    }
                  }
                });
                if (Field_Precision_List.length > 0) {
                  this._session.api.local.save('Field_precision', Field_Precision_List);
                }
              }
            }, 100);

            if (this.action === this.NEXT_QUESTION) {
              // if (this.currentQuestionType === 'Message_drag_and_drop_type' || this.currentQuestionType === 'Message_single_type') {
              //   const backToQuestionId    = this.questionIdList.filter(el => el.value == result.questionId);
              //   this.displayQuestionIndex = backToQuestionId[0].count;
              //   // this.displayQuestionIndex = this.currentMessageIndex === 0 ? this.displayQuestionIndex + 1 : this.displayQuestionIndex;
              // } else {
              // this.displayQuestionIndex++;
              // }
              const backToQuestionId = this.questionIdList.filter(el => el.value == result.questionId);
              this.displayQuestionIndex = backToQuestionId[0].count;
            } else if (this.action !== 'jump') {
              // this.displayQuestionIndex--;
              const backToQuestionId = this.questionIdList.filter(el => el.value == result.questionId);
              this.displayQuestionIndex = backToQuestionId[0].count;
            }

            let QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
            QuestionNumberMap = QuestionNumberMap ? QuestionNumberMap : {};

            QuestionNumberMap[this.current_question.toUpperCase()] = this.displayQuestionIndex;
            this._session.api.local.save('QuestionNumberMap', QuestionNumberMap);

          }
        } else if (this.action === this.PREV_SECTION) {
          this.current_section = result['name'];
          this.current_section_index = result['section_index'];
          this.current_question = '';
          this.current_question_index = '';
          this.action = this.PREV_QUESTION;
          this.fetchData();
        } else if (['JumpTo', this.FETCH_QUESTION_WITH_QUESTIONID].indexOf(this.action) > -1) {
          this.questionsPreviewService.setCurrentQuestionDetails(response['result']);
          this.errorMsgShow = false;
          this.errorMsg = "";
          this.checkquestionDetails = false;
          this.questionDetails = result;
          const backToQuestionId = this.questionIdList.filter(el => el.value == result.questionId);
          this.displayQuestionIndex = this.isbackToSurvey ? backToQuestionId[0].count : this.jumpTo.count;
          this.current_question = result['questionId'];
          this.current_question_index = result['question_index'];
          this.current_section = result['section_name'];
          this.current_section_index = result['section_index'];
          this.instructionDetails = new Map();
          this.questionsPreviewService.currentQuestion = null;
          this.questionsPreviewService.currentQuestionOptions = null;

          this.current_question_index = result['question_index'];

          this.terminate_current_question = result['questionId'];
          this.terminate_current_section_index = result['question_index'];

          const QuestionTypes = this._session.api.local.get('QuestionTypes');
          const tempInd = Object.keys(QuestionTypes).indexOf(this.current_question);
          if (tempInd === -1) {
            QuestionTypes[this.current_question.toUpperCase()] = result['questionType'];

          }
          this._session.api.local.save('QuestionTypes', QuestionTypes);

          if (!skipAndMove) {
            setTimeout(() => {
              this.questionDetails = result;
              // update question data piped variables with actual values like - PIPE , SHOW TEXT and LINK
              this.questionDetails['question'] = this.questionsPreviewService.frameQuestion(this.questionDetails);

              // save question level rules
              if (result['Instructions'].length > 0) {
                result['Instructions'].forEach((instruction) => {
                  if (instruction.parseStatus && instruction.parseStatus === 'Success') {
                    if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
                      const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
                      if (this.instructionDetails.has(instruction.ruleExecutionPhase)) {
                        // this.instructionDetails.get(instruction.ruleExecutionPhase).push(instruction.parsedInstruction);
                        this.instructionDetails.get(instruction.ruleExecutionPhase).push(parseData);
                      } else {
                        // this.instructionDetails.set(instruction.ruleExecutionPhase, [instruction.parsedInstruction]);
                        this.instructionDetails.set(instruction.ruleExecutionPhase, [parseData]);
                      }
                    }
                  }
                });
              }

              const optionsData = this.questionDetails['Options'];

              // There is no options for messsage questions.
              if (this.currentQuestionType !== "Message_single_type" && this.currentQuestionType !== "Message_drag_and_drop_type") {
                // save column level rules -
                optionsData['columns'].forEach((column) => {
                  const parseStatus = column['parseStatus'];
                  if (parseStatus && parseStatus === 'Success') {
                    const inst = column['parsedInstruction'];
                    if (inst) {
                      const execPhase = column['ruleExecutionPhase'];
                      //handle pipe rules  -
                      if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null);
                        if (result) {
                          const header = column['header'];
                          if (header) {
                            column['header'] = header + ' ' + result;
                          } else {
                            column['header'] = result;
                          }
                        }
                      } else {
                        const parseData = { instdet: inst, errmsg: null };
                        if (this.instructionDetails.has(execPhase)) {
                          // this.instructionDetails.get(execPhase).push(inst);
                          this.instructionDetails.get(execPhase).push(parseData);
                        } else {
                          // this.instructionDetails.set(execPhase, [inst]);
                          this.instructionDetails.set(execPhase, [parseData]);
                        }
                      }
                    }
                  }
                });

                // save row level rules -
                optionsData['rows'].forEach((row) => {
                  row.forEach((r) => {
                    const parseStatus = r['parseStatus'];
                    if (parseStatus && parseStatus === 'Success') {
                      const inst = r['parsedInstruction'];
                      let label_name;
                      if (r['option_name']) {
                        label_name = r['option_name'];
                      }
                      if (inst) {
                        const execPhase = r['ruleExecutionPhase'];
                        //handle pipe rules  -
                        if (inst.includes('pipe(') || inst.includes('pipe_conditional(') || inst.includes('pipe_rank(') || inst.includes('pipe_name(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null, null, label_name);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                if (r['value'].indexOf('%') > -1) {
                                  r['value'] = ' ' + result + r['value'];
                                } else {
                                  r['value'] = r['value'] + ' ' + result;

                                }
                              }
                              // Not required - instead use DEFAULT rule -
                              /*
                              else if(valueType === 'SYMBOL') {
                                let val = r['value'];
                                if(val === 'Number') {
                                  r['number'] = result;
                                } else if(val === 'Percent') {
                                  r['percent'] = result;
                                }
                                // r['disable'] = true;
                              }
                              */
                            }
                          }
                        } else if (inst.includes('image(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null);
                          if (result && result !== undefined) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                            }
                          }

                        } else {
                          const parseData = { instdet: inst, errmsg: null };
                          if (this.instructionDetails.has(execPhase)) {
                            // this.instructionDetails.get(execPhase).push(inst);
                            this.instructionDetails.get(execPhase).push(parseData);
                          } else {
                            // this.instructionDetails.set(execPhase, [inst]);
                            this.instructionDetails.set(execPhase, [parseData]);
                          }
                        }
                      }
                    }
                  });
                });
              }

              // console.log(optionsData);
              // set current question and option values in service
              this.questionsPreviewService.currentQuestion = this.current_question;
              this.questionsPreviewService.currentQuestionOptions = optionsData;
              this.questionsPreviewService.questionType = this.questionDetails['questionType'];

              // execute onload rules here
              const onloadRules = this.instructionDetails.get('ONLOAD');
              if (onloadRules) {
                onloadRules.forEach((rule) => {
                  const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
                  if (response) {
                    if (this.questionsPreviewService.askRulesMap.has(this.current_question.toUpperCase())) {
                      const showQuestion = this.questionsPreviewService.askRulesMap.get(this.current_question.toUpperCase());
                      // if (!showQuestion) {
                      //   skipAndMove = true;
                      //   if (this.action === this.NEXT_QUESTION) {
                      //     this.getNextQuestion();
                      //   } else if (this.action === this.PREV_QUESTION) {
                      //     this.getPrevQuestion();
                      //   }
                      // }
                    }
                  }
                });
              }
            }, 100);

            // if (this.action === this.NEXT_QUESTION) {
            //   this.displayQuestionIndex++;
            // } else if (['JumpTo', this.FETCH_QUESTION_WITH_QUESTIONID].indexOf(this.action) == -1) {
            //   this.displayQuestionIndex--;
            // }
            if (this.action === this.NEXT_QUESTION) {
              if (this.currentQuestionType === 'Message_drag_and_drop_type' || this.currentQuestionType === 'Message_single_type') {
                this.displayQuestionIndex = this.currentMessageIndex === 0 ? this.displayQuestionIndex + 1 : this.displayQuestionIndex;
              } else {
                this.displayQuestionIndex++;
              }
            } else if (['JumpTo', this.FETCH_QUESTION_WITH_QUESTIONID].indexOf(this.action) == -1) {

              this.displayQuestionIndex--;

            }

            let QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
            QuestionNumberMap = QuestionNumberMap ? QuestionNumberMap : {};

            QuestionNumberMap[this.current_question.toUpperCase()] = this.displayQuestionIndex;
            this._session.api.local.save('QuestionNumberMap', QuestionNumberMap);
            console.log('11>>>>', this.instructionDetails)


          }
        }

        this.progressPercentage = (result['total_completed_questions'] / result['total_survey_questions']) * 100;
        this.progressPercentage = Math.floor(this.progressPercentage);
      }
    }
  });
}

fetchMultipleQns() {
  this.questionDetailsList.forEach((element) => {
    this.questionDetailOptions = element['Options'];
    this.currentQuestionType = element['questionType'];
    // this.currentCombinationType = element['combinationTypes'] ? element['combinationTypes'] : '';
    this.currentQuestionId = element['questionId'];
    this.current_section_index = element['section_index'];
    this.current_section = element['name'];
    this.current_question = element['questionId'];
    this.currentVersionId = element['version_id'];

    if (element["questionType"] === "Message_single_type" || element["questionType"] === "Message_drag_and_drop_type") {
      this.isMessageQuestions = true;
      this.fetchMessageQuestions();
    } else {
      this.isMessageQuestions = false;
    }

    if (element['questionType'] != 'Blank Table' && this.document.getElementById('nextBtn')) {
      this.document.getElementById('nextBtn').disabled = false;
    }

    if (element === 'Survey Completd' || element === 'All the questions in the section are completed') {
      this.surveyStatus = 'completed';
      this.updateLiveSurveyStatus('Completed');
    } else {
      this.total_section_questions = element['total_section_questions'];
      this.total_survey_questions = element['total_survey_questions'];
      this.total_completed_questions = element['total_completed_questions'];
    }
    this.progressPercentage = (element['total_completed_questions'] / element['total_survey_questions']) * 100;
    this.progressPercentage = Math.floor(this.progressPercentage);
  })

  if (this.action === this.NEXT_SECTION) {
    // if (this.action === this.NEXT_SECTION) {
    // check if survey is marked for termination
    this.questionDetailsList.forEach((element) => {
      if (this.questionsPreviewService.terminateSurvey) {
        const temp = this._session.api.local.get('terminateSurveyQuestionDetails');
        this.surveyStatus = 'terminated';
        this.updateLiveSurveyStatus('Terminated', temp);
        return;
      }
      this.questionDetailsList = [];
      this.current_section = element['name'];
      this.current_section_index = this.questionsPreviewService.terminateSurvey ? this.current_section_index : element['section_index'];
      // this.current_section_index = result['section_index'];
      this.total_section_questions = element['total_section_questions'];
      this.total_survey_questions = element['total_survey_questions'];
      this.total_completed_questions = element['total_completed_questions'];
      this.current_question = '';
      this.current_question_index = '';
      this.action = this.FETCH_MULTIPLE_QUESTIONS;
      if (this.validateCompleteQuestion) {
        this.completeQuestionLists();
        return;
      } else {
        if (this.initialSurveyOpen) {
          this.updateLiveSurveyStatus('Started');
        }
        this.fetchData();
      }
      this.progressPercentage = (element['total_completed_questions'] / element['total_survey_questions']) * 100;
      this.progressPercentage = Math.floor(this.progressPercentage);
    })

  }
  this.questionDetailsList.forEach((element) => {
    this.progressPercentage = (element['total_completed_questions'] / element['total_survey_questions']) * 100;
    this.progressPercentage = Math.floor(this.progressPercentage);
  })
}

/**
 * Fetching message questions
 */
fetchMessageQuestions() {
  const inputData = {};
  if (this.checkGenericLink == true) {
    inputData['surveyKey'] = this.gl_surveyKey;
    inputData['participant_id'] = this.gl_participantId;
    inputData["current_message_index"] = this.currentMessageIndex;
    inputData["current_question"] = this.currentQuestionId;
  } else {
    inputData["surveyKey"] = this.respondentDetails.surveyKey;
    inputData["participant_id"] = this.respondentDetails.participant_id;
    inputData["current_message_index"] = this.currentMessageIndex;
    inputData["current_question"] = this.currentQuestionId;
  }

  this.httpLayer
    .post(Config.SERVICE_IDENTIFIER.FETCH_MESSAGE_QUESTIONS, inputData)
    .subscribe(
      (messageResponse) => {
        if (messageResponse.status === "success") {
          this.messageQuestions = undefined;
          this.messageQuestions = messageResponse.result;
          this.messageSetLength = messageResponse.result.data[0].length;
          this.messageLoopCount = messageResponse.result.total_message_count;
          this.messageBreakFlag = messageResponse.result.message_break_flag;
        } else {
          this.notification.error(
            "Error",
            messageResponse["result"] ||
            "Failed to get data, please try again later"
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
}

updateSelectedData(e, index) {
  this.fetchMultiple = this._session.api.local.get(Config.CONSTANTS.MULTIPLE_QUESTIONS)
  if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    this.selectedData = this.selectedData === null ? [] : this.selectedData;
    this.selectedData[index] = e;
    this.checkOnscreenDisableRule(this.selectedData);
  } else {
    this.selectedData = e;
    this.checkOnscreenDisableRule(this.selectedData);
  }
}

checkOnscreenDisableRule(selectedData) {
  let isDisableRule = false;
  if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    this.questionDetailsList.forEach((question, i) => {
      let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(question, i);
      const onscreenRules = instructionDetailsMultipleQuestion.get('ONSCREEN');
      // const onscreenRules = this.instructionDetails[question.questionId] ? this.instructionDetails[question.questionId].get('ONSCREEN') : false;
      if (onscreenRules) {
        for (const rule of onscreenRules) {
          /** Enabling all disabled COLUMNS,ROWS,FIELD based on the rule */
          let fun_string = rule['instdet']
          let fun_name = fun_string.substring(0, fun_string.indexOf("("));
          if (fun_name == 'disable') {
            isDisableRule = true;
          }
        }
      }
    })

  } else {
    const onscreenRules = this.instructionDetails.get('ONSCREEN');
    if (onscreenRules) {
      for (const rule of onscreenRules) {
        /** Enabling all disabled COLUMNS,ROWS,FIELD based on the rule */
        let fun_string = rule['instdet']
        let fun_name = fun_string.substring(0, fun_string.indexOf("("));
        if (fun_name == 'disable') {
          isDisableRule = true;
        }
      }
    }
  }
  if (isDisableRule) {
    this.disableSameScreen(selectedData);
    this.disableSelectedData();
  }
}

/**
 * For answer check updating disable to this.selectedData
 */
disableSelectedData() {
  if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    this.questionDetailsList.forEach((question, i) => {
      this.selectedData[i][question.questionId].forEach(element => {
        element['disable'] = false;
      })
    });
    this.questionDetailsList.forEach((question, i) => {
      this.selectedData[i][question.questionId].forEach(element => {
        question['Options']['columns'].forEach((colData, colId) => {
          if (colData.columnId == element.selected_header) {
            question['Options']['rows'].forEach(rowData => {
              if (rowData[0].value == element.selected_row && rowData[colId].disable) {
                element.disable = rowData[colId].disable;
              }
            });
          }
        });
      });
      if (question['questionType'] == 'Number') {
        this.totalAutoSumNumber();
      }
    })

  } else {
    this.selectedData.forEach( element => {
      element['disable'] = false;
    })
    this.selectedData.forEach(element => {
      this.questionDetails['Options']['columns'].forEach((colData, colId) => {
        if (colData.columnId == element.selected_header) {
          this.questionDetails['Options']['rows'].forEach(rowData => {
            if (rowData[0].value == element.selected_row && rowData[colId].disable) {
              element.disable = rowData[colId].disable;
            }
          });
        }
      });
    });
    if (this.questionDetails['questionType'] == 'Number') {
      this.totalAutoSumNumber();
    }
  }

}

/**
 * Autosum was disapearing after onscreen disable excecution
 */
totalAutoSumNumber() {
  let rowsum = 0;
  let colSum = 0;
  if (this.action == this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    this.questionDetailsList.forEach(question => {
      question.Options.rows.forEach((el, index) => {
        el.forEach(innerEl => {
          if ((innerEl.value === 'Number' && innerEl.number != null) || innerEl.parseStatus == 'Success' && innerEl.parsedInstruction.includes('pipe')) {
            let num = 0;
            if (innerEl.parsedInstruction && innerEl.parsedInstruction.includes('pipe')) {
              num = innerEl.value;
            } else {
              num = innerEl.number;
            }
            rowsum += Number(num);
          }
          if (innerEl.instruction === '[AUTOSUM]') {
            innerEl['number'] = Number(rowsum);
            rowsum = 0;
          }
        });
      });
      const columLen = question.Options.rows.length;
      question.Options.rows[columLen - 1].forEach((element, i) => {
        if (element && element.instruction === '[AUTOSUM]') {
          question.Options.rows.forEach(ele => {
            ele.forEach((arr, innerIndex) => {
              if (i === innerIndex && ((arr.value === 'Number') || arr.parseStatus == 'Success' && arr.parsedInstruction.includes('pipe'))) {
                let num = 0;
                if (arr.parsedInstruction && arr.parsedInstruction.includes('pipe')) {
                  num = arr.value;
                } else {
                  num = arr.number;
                }
                colSum += Number(num);
              }
            });
          });
          element['number'] = Number(colSum) ? Number(colSum) : element['number'];
          colSum = 0;
        }
      });
    });
  } else {
    this.questionDetails.Options.rows.forEach((el, index) => {
      el.forEach(innerEl => {
        if ((innerEl.value === 'Number' && innerEl.number != null) || innerEl.parseStatus == 'Success' && innerEl.parsedInstruction.includes('pipe')) {
          let num = 0;
          if (innerEl.parsedInstruction && innerEl.parsedInstruction.includes('pipe')) {
            num = innerEl.value;
          } else {
            num = innerEl.number;
          }
          rowsum += Number(num);
        }
        if (innerEl.instruction === '[AUTOSUM]') {
          innerEl['number'] = Number(rowsum);
          rowsum = 0;
        }
      });
    });
    const columLen = this.questionDetails.Options.rows.length;
    this.questionDetails.Options.rows[columLen - 1].forEach((element, i) => {
      if (element && element.instruction === '[AUTOSUM]') {
        this.questionDetails.Options.rows.forEach(ele => {
          ele.forEach((arr, innerIndex) => {
            if (i === innerIndex && ((arr.value === 'Number') || arr.parseStatus == 'Success' && arr.parsedInstruction.includes('pipe'))) {
              let num = 0;
              if (arr.parsedInstruction && arr.parsedInstruction.includes('pipe')) {
                num = arr.value;
              } else {
                num = arr.number;
              }
              colSum += Number(num);
            }
          });
        });
        element['number'] = Number(colSum) ? Number(colSum) : element['number'];
        colSum = 0;
      }
    });
  }
}

/**
 * Onscreen disable rules are handling in each change of same screen
 * @param data 
 * @returns 
 */
disableSameScreen(data) {
  if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    this.questionDetailsList.forEach((question, i) => {
      data[i][question.questionId].forEach(elem => {
        question['Options']['columns'].forEach((colData, colId) => {
          if (colData.columnId == elem.selected_header) {
            question['Options']['rows'].forEach(rowData => {
              if (rowData[0].value == elem.selected_row) {
                rowData[colId].number = elem.selected_value;
              }
            });
          }
        });
      });
      const optionsData = question['Options'];

      this.questionsPreviewService.currentQuestion = question.questionId;
      this.questionsPreviewService.currentQuestionOptions = optionsData;
      this.questionsPreviewService.questionType = question['questionType'];
      this.questionsPreviewService.currentSelectedData = this.selectedData[i][question.questionId];
      this.questionsPreviewService.onScreenDisable = 'onScreenDisable';

      let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(question, i);
      const onscreenRules = instructionDetailsMultipleQuestion.get('ONSCREEN');
      // const onscreenRules = this.instructionDetails[question.questionId] ? this.instructionDetails[question.questionId].get('ONSCREEN') : false;
      let errmsg;
      if (onscreenRules) {
        for (const rule of onscreenRules) {
          /** Enabling all disabled COLUMNS,ROWS,FIELD based on the rule */
          let fun_string = rule['instdet']
          let fun_name = fun_string.substring(0, fun_string.indexOf("("));
          let fun_params = fun_string.substring(fun_string.indexOf("(") + 1, fun_string.length - 1);
          let params = fun_params.split("##");
          if (fun_name !== 'disable') { return }
          if (params[0] == "COLUMNS") {
            let colUmnHeader = params[1].split(",");
            let colUmnId = [];
            question['Options']['columns'].forEach((colData, id) => {
              if (colUmnHeader.includes(colData.columnId)) {
                colUmnId.push(id);
              }
            });
            colUmnId.forEach(cId => {
              question['Options']['rows'].forEach(rowData => {
                if (rowData[cId] && rowData[cId].valueType !== 'Label' && rowData[cId].disable) {
                  rowData[cId].disable = false;
                }
              });
            });
          } else if (params[0] == "ROWS") {
            let rowId = params[1].split(",");
            rowId.forEach(rId => {
              question['Options']['rows'].forEach(rowDatas => {
                if (rowDatas[0].value == rId) {
                  rowDatas.forEach(eleme => {
                    if (eleme.valueType !== 'Label' && eleme.disable) {
                      eleme.disable = false;
                    }
                  });
                }
              });
            });
          } else if (params[0] == "FIELD") {
            let fields = params[1].split(",");
            fields.forEach(fld => {
              let item = fld.split("_");
              question['Options']['columns'].forEach((colDatas, colId) => {
                if (colDatas.columnId == item[1]) {
                  question['Options']['rows'].forEach(rData => {
                    if (rData[0].value == item[2] && rData[colId] && rData[colId].valueType !== 'Label' && rData[colId].disable) {
                      rData[colId].disable = false;
                    }
                  });
                }
              });
            });
          }

          const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
          if (response && response.status === false) {
            errmsg = response.message;
            break;
          }
        }
        if (errmsg) {
          this.errorMsgShow = true;
          let errorKeys = errmsg.match(this.errorMsgKeyPattern);
          if (errorKeys) {
            for (let key of errorKeys) {
              let keyStr = key.replace('{', '').replace('}', '');
              this.translate.get(keyStr).subscribe((text: string) => {
                errmsg = errmsg.replace(key, text);
              });
            }
          }
          this.errList.push({ 'id': question['questionId'], 'msg': errmsg });
          return;
        }
      }
    })
  } else {
    data.forEach(elem => {
      this.questionDetails['Options']['columns'].forEach((colData, colId) => {
        if (colData.columnId == elem.selected_header) {
          this.questionDetails['Options']['rows'].forEach(rowData => {
            if (rowData[0].value == elem.selected_row) {
              rowData[colId].number = elem.selected_value;
            }
          });
        }
      });
    });
    const optionsData = this.questionDetails['Options'];

    this.questionsPreviewService.currentQuestion = this.current_question;
    this.questionsPreviewService.currentQuestionOptions = optionsData;
    this.questionsPreviewService.questionType = this.questionDetails['questionType'];
    this.questionsPreviewService.currentSelectedData = this.selectedData;
    this.questionsPreviewService.onScreenDisable = 'onScreenDisable';

    const onscreenRules = this.instructionDetails.get('ONSCREEN');
    this.questionsPreviewService.currentSelectedData = this.selectedData;
    let errmsg;
    if (onscreenRules) {
      for (const rule of onscreenRules) {
        /** Enabling all disabled COLUMNS,ROWS,FIELD based on the rule */
        let fun_string = rule['instdet']
        let fun_name = fun_string.substring(0, fun_string.indexOf("("));
        let fun_params = fun_string.substring(fun_string.indexOf("(") + 1, fun_string.length - 1);
        let params = fun_params.split("##");
        if (fun_name !== 'disable') { return }
        if (params[0] == "COLUMNS") {
          let colUmnHeader = params[1].split(",");
          let colUmnId = [];
          this.questionDetails['Options']['columns'].forEach((colData, id) => {
            if (colUmnHeader.includes(colData.columnId)) {
              colUmnId.push(id);
            }
          });
          colUmnId.forEach(cId => {
            this.questionDetails['Options']['rows'].forEach(rowData => {
              if (rowData[cId] && rowData[cId].valueType !== 'Label' && rowData[cId].disable) {
                rowData[cId].disable = false;
              }
            });
          });
        } else if (params[0] == "ROWS") {
          let rowId = params[1].split(",");
          rowId.forEach(rId => {
            this.questionDetails['Options']['rows'].forEach(rowDatas => {
              if (rowDatas[0].value == rId) {
                rowDatas.forEach(eleme => {
                  if (eleme.valueType !== 'Label' && eleme.disable) {
                    eleme.disable = false;
                  }
                });
              }
            });
          });
        } else if (params[0] == "FIELD") {
          let fields = params[1].split(",");
          fields.forEach(fld => {
            let item = fld.split("_");
            this.questionDetails['Options']['columns'].forEach((colDatas, colId) => {
              if (colDatas.columnId == item[1]) {
                this.questionDetails['Options']['rows'].forEach(rData => {
                  if (rData[0].value == item[2] && rData[colId] && rData[colId].valueType !== 'Label' && rData[colId].disable) {
                    rData[colId].disable = false;
                  }
                });
              }
            });
          });
        }

        const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
        if (response && response.status === false) {
          errmsg = response.message;
          break;
        }
      }
      if (errmsg) {
        this.errorMsgShow = true;
        let errorKeys = errmsg.match(this.errorMsgKeyPattern);
        if (errorKeys) {
          for (let key of errorKeys) {
            let keyStr = key.replace('{', '').replace('}', '');
            this.translate.get(keyStr).subscribe((text: string) => {
              errmsg = errmsg.replace(key, text);
            });
          }
        }
        this.errorMsg = errmsg;
        return;
      }
    }

  }


}

/**
 * Fetching selected messages from dnd-component
 */
popUpData(e) {
  this.popUpContent = e;
}

/**
 * Message popup-component will return true or false based on customer selection
 */
isOkOrCancelToLiveSurvey(e) {
  this.isOkOrCancel = e;
  if (this.isOkOrCancel) {
    this.handleOk();
  }
}

saveData() {
  this.lastQuestionIndex = this.current_question; /** This will use in fetchData for nextsection action */
  this.errorMsgShow = false;
  this.errorMsg = undefined;
  this.multipleErrorMsg = [];
  let showFieldError = false;
  let showOnScreenError = false
  let temp = [];
  let questOrder = [];
  let inputBase = {};
  // console.log(this.questionDetails.Instructions && this.questionDetails.Instructions[0].rule_type == 'ORDER');

  // ONSCREEN Rule Execution.
  // const onscreenRules = this.instructionDetails.get('ONSCREEN');
  //       this.questionsPreviewService.currentSelectedData = this.selectedData;
  //       this.questionsPreviewService.onScreenDisable = '';
  //       let errmsg;
  //       if (onscreenRules) {
  //         for (const rule of onscreenRules) {
  //           const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
  //           if (response && response.status === false) {
  //             errmsg = response.message;
  //             break;
  //           }
  //         }
  //         if (errmsg) {
  //           this.errorMsgShow = true;
  //           let errorKeys = errmsg.match(this.errorMsgKeyPattern);
  //           if (errorKeys) {
  //             for (let key of errorKeys) {
  //               let keyStr = key.replace('{', '').replace('}', '');
  //               this.translate.get(keyStr).subscribe((text: string) => {
  //                 errmsg = errmsg.replace(key, text);
  //               });
  //             }
  //           }
  //           this.errorMsg = errmsg;
  //           this.errorMsgShow = true;
  //           showOnScreenError = true;
  //           return;
  //         }
  //       }
      
  // ONSCREEN Rule Execution Ends.

  if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    temp = this.selectedData;
  } else if (this.selectedData['0'].option_type === 'Message_single_type' || this.selectedData['0'].option_type === 'Message_drag_and_drop_type') {
    temp = this.selectedData;
  } else {
    questOrder = this.questionsPreviewService.currentQuestionOptions.rows.map(el => el[0].value);
    // const questOrder = this.questionsPreviewService.currentQuestionOptions.rows.map(el => el[0].value);
    questOrder.forEach(el => {
      this.selectedData.forEach(element => {
        if (element.selected_row == el) {
          temp.push(element);
        }
      });
    });
  }
  if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    inputBase = {
      current_section: this.current_section,
      current_section_index: this.current_section_index,
      current_question: this.current_question,
      current_question_index: this.current_question_index,
      response: temp,
      version_id: this.currentVersionId,
      start_time: this.paricipant_action === 'Newly Started' ? this.shared.dateFormatWithTime() : undefined,
      end_time: this.shared.dateFormatWithTime(),
      current_oder_list: questOrder,
      current_message_index: this.currentMessageIndex
    };
  } else {
    inputBase = {
      current_section: this.current_section,
      current_section_index: this.current_section_index,
      current_question: this.current_question,
      current_question_index: this.current_question_index,
      version_id: this.currentVersionId,
      response: temp,
      start_time: this.paricipant_action === 'Newly Started' ? this.shared.dateFormatWithTime() : undefined,
      end_time: this.shared.dateFormatWithTime(),
      current_oder_list: questOrder,
      current_message_index: this.currentMessageIndex
    };
  }

  let inputData;
  if (this.checkGenericLink == true) {
    inputData = { ...{ surveyKey: this.gl_surveyKey, participant_id: this.gl_participantId }, ...inputBase };
  } else {
    inputData = { ...{ surveyKey: this.respondentDetails.surveyKey, zs_id: this.respondentDetails.zs_id, participant_id: this.respondentDetails.participant_id }, ...inputBase };
  }
  // if (this.questionDetails.Instructions && this.questionDetails.Instructions[0].rule_type == 'ORDER') {
  //   input['OrderRule'] = true;
  // }
  if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
    this.questionDetailsList.forEach((ele, i) => {
      if (ele.Instructions && ele.Instructions[0].rule_type == 'ORDER') {
        inputData['OrderRule'] = true;
      }
    });
  }
  else if (this.questionDetails.Instructions && this.questionDetails.Instructions.length > 0) {
    this.questionDetails.Instructions.forEach(instrctn => {
      if (instrctn.rule_type == 'ANCHOR' || instrctn.rule_type == 'ORDER') {
        inputData['OrderRule'] = true;
      }
    });
  }
  inputData['ip_address'] = this.ipAddress;
  if (this.checkGenericLink == false) {
    inputData['test_user'] = this.respondentDetails['test_user'];
  }
  

  this.httpLayer.post(Config.SERVICE_IDENTIFIER.SAVE_RESPONSE, inputData).subscribe(resp => {
    if (resp['status'] === 'success') {

      if (this.currentQuestionType === 'Message_drag_and_drop_type') {
        this.currentMessageIndex = this.currentMessageIndex + 1;
      }
      const quesDetail = {
        section_index: this.current_section_index,
        current_question: this.current_question
      };
      if (Array.isArray(resp['result']) == true) {
        resp['result'].forEach((el) => {
          if (el['quota_status'] && el['quota_status'] == 'Terminate') {
            this.questionsPreviewService.setTerminationSurvey(true);
            this.quota_termination = true;
            localStorage.setItem('terminateSurveyQuestionDetails', JSON.stringify(quesDetail));
            this.quota_termination = true;
          }
        })
      } else {
        if (resp['result']['quota_status'] == 'Terminate') {
          this.questionsPreviewService.setTerminationSurvey(true);
          this.quota_termination = true;
          localStorage.setItem('terminateSurveyQuestionDetails', JSON.stringify(quesDetail));
          this.quota_termination = true;
        }
      }

      // set last question response value in response data
      const result = resp['result'];
      if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
        const multiValue = {};
        result.forEach(element => {
          for (let el in element) {
            multiValue[el] = element[el];
          }
        });
        this.questionsPreviewService.setNumberEachFieldRange(multiValue);

      } else {
        this.questionsPreviewService.setNumberEachFieldRange(resp['result']);

      }


      this.paricipant_action = undefined;
      if (result) {
        const retkey = Object.keys(result);
        retkey.forEach((key) => {
          const retval = result[key];
          let surveyResponse = this._session.api.local.get('SurveyResponse');
          surveyResponse == null ? surveyResponse = {} : surveyResponse;
          if (this.action == this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
            const multiKey = Object.keys(retval)
            multiKey.forEach((el, ind) => {
              surveyResponse[el.toUpperCase()] = retval[el];
            });
            // surveyResponse[multiKey[0].toUpperCase()] = retval[multiKey[0]];
            this._session.api.local.save('SurveyResponse', surveyResponse);
          } else {
            surveyResponse[key.toUpperCase()] = retval;
            this._session.api.local.save('SurveyResponse', surveyResponse);
          }
        });
      }

      // execute onscreen rules here
      if (this.action == this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
        this.questionDetailsList.forEach((questions, i) => {
          let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(questions, i);
          const onscreenRules = instructionDetailsMultipleQuestion.get('ONSCREEN');
          this.questionsPreviewService.currentQuestionOptions = this.questionDetailsList[i]['Options'];
          this.questionsPreviewService.currentSelectedData = this.selectedData[i][questions.questionId];
          this.questionsPreviewService.onScreenDisable = '';
          let errmsg;
          if (onscreenRules) {
            for (const rule of onscreenRules) {
              const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
              if (response && response.status === false) {
                errmsg = response.message;
                break;
              } else if (response && response.status === true) { /** if success then highlighted fields are removing */
                this.questionDetailsList[i]['Options']['rows'].forEach(rows => {
                  rows.forEach(el => {
                    if (el.invalid && el.invalid === true) {
                      el.invalid = false;
                    }
                  });
                });
              }
            }
            if (errmsg) {
              this.errorMsgShow = true;
              let errorKeys = errmsg.match(this.errorMsgKeyPattern);
              if (errorKeys) {
                for (let key of errorKeys) {
                  let keyStr = key.replace('{', '').replace('}', '');
                  this.translate.get(keyStr).subscribe((text: string) => {
                    errmsg = errmsg.replace(key, text);
                  });
                }
              }

              this.errList.push({ 'id': questions['questionId'], 'msg': errmsg });
              showOnScreenError = true;
              this.setMultipleErrorMessage(this.errList)
              // this.notification.error('Error', errmsg);
              // this.selectedData = null;
              return;
            }
          }
        })
      
      } else {
        const onscreenRules = this.instructionDetails.get('ONSCREEN');
        this.questionsPreviewService.currentSelectedData = this.selectedData;
        this.questionsPreviewService.onScreenDisable = '';
        let errmsg;
        if (onscreenRules) {
          for (const rule of onscreenRules) {
            const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
            if (response && response.status === false) {
              errmsg = response.message;
              break;
            }
          }
          if (errmsg) {
            this.errorMsgShow = true;
            let errorKeys = errmsg.match(this.errorMsgKeyPattern);
            if (errorKeys) {
              for (let key of errorKeys) {
                let keyStr = key.replace('{', '').replace('}', '');
                this.translate.get(keyStr).subscribe((text: string) => {
                  errmsg = errmsg.replace(key, text);
                });
              }
            }
            this.errorMsg = errmsg;
            showOnScreenError = true;
            // this.notification.error('Error', errmsg);
            // this.selectedData = null;
            return;
          }
        }
      }

      // execute onsubmit rules here
      if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
        this.questionDetailsList.forEach((questions, i) => {
          let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(questions, i);
          const onsubmitRules = instructionDetailsMultipleQuestion.get('ONSUBMIT');
          // const onsubmitRules = this.instructionDetails[questions.questionId] ? this.instructionDetails[questions.questionId].get('ONSUBMIT') : false;
          if (onsubmitRules) {
            // this.questionsPreviewService.setCurrentQuestionDetailsSave(quesDetail);
            // const errorFieldSet = new Set();
            const errorFieldSet = [];
            onsubmitRules.forEach((rule) => {
              const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg'], {
                section_index: this.current_section_index,
                current_question: questions.questionId
              });
              if (response != undefined && response.type == 'range' && !response.isValid) {
                errorFieldSet.push(response);
                return;
              }
            });
            let errText = '';
            errorFieldSet.forEach(element => {
              let fieldRowName = '';
              questions['Options']['rows'].filter((r) => {
                const roVal = r[0]['value'];
                if (element.fieldId === roVal) {
                  fieldRowName = r[1].value;
                }
              });
              errText += `${element.errorText} for ${fieldRowName} <br>`;
            });
            if (errorFieldSet.length > 0) {
              this.highlightErrorFields(errorFieldSet, questions);
              this.errorMsgShow = true;
              this.errList.push({ 'id': questions['questionId'], 'msg': errText });
              showFieldError = true;
              this.setMultipleErrorMessage(this.errList);
            }
          }
        })
      } else {
        const onsubmitRules = this.instructionDetails.get('ONSUBMIT');
        if (onsubmitRules) {
          // this.questionsPreviewService.setCurrentQuestionDetailsSave(quesDetail);
          // const errorFieldSet = new Set();
          const errorFieldSet = [];
          onsubmitRules.forEach((rule) => {
            const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg'], quesDetail);
            if (response != undefined && response.type == 'range' && !response.isValid) {
              errorFieldSet.push(response);
              return;
            }
          });
          let errText = '';
          errorFieldSet.forEach(element => {
            let fieldRowName = '';
            this.questionDetails['Options']['rows'].filter((r) => {
              const roVal = r[0]['value'];
              if (element.fieldId === roVal) {
                fieldRowName = r[1].value;
              }
            });
            errText += `${element.errorText} for ${fieldRowName} <br>`;
          });
          if (errorFieldSet.length > 0) {
            this.highlightErrorFields(errorFieldSet, this.questionDetails);
            this.errorMsgShow = true;
            this.errorMsg = errText;
            showFieldError = true;
          }
        }
      }

      if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
        if (!showFieldError && !showOnScreenError) {
          // this.selectedData = [];
          this.getNextQuestion();
        }
      } else {
        if (!showFieldError && !showOnScreenError) {
          this.selectedData = null;
          this.getNextQuestion();
        }
      }
    } else {
      if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
        this.errorMsgShow = true;
        this.errList.push(resp['result']);
        console.log(this.errorMsg);
      } else {
        this.errorMsgShow = true;
        this.errorMsg = resp['result'];
        console.log(this.errorMsg);
      }


      // this.notification.error('', resp['result']);
    }
  });
}

multipleQuestionInstructionDetail(questions, i) {
  /** Instead of instruction details using 'instructionDetailsMultipleQuestion' */
  let instructionDetailsMultipleQuestion = new Map();
  if (questions['Instructions'].length > 0) {
    questions['Instructions'].forEach((instruction) => {
      if (instruction.parseStatus && instruction.parseStatus === 'Success') {
        const text = instruction.instruction.indexOf('FIELD')
        const precisionTest = instruction.instruction.indexOf('PRECISION')

        if (text > -1 && precisionTest > -1) {
          instruction.ruleExecutionPhase = "ONLOAD"
        }
        if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
          const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
          if (instructionDetailsMultipleQuestion) {
            if (instructionDetailsMultipleQuestion.has(instruction.ruleExecutionPhase)) {
              instructionDetailsMultipleQuestion.get(instruction.ruleExecutionPhase).push(parseData);
            } else {
              instructionDetailsMultipleQuestion.set(instruction.ruleExecutionPhase, [parseData]);
            }
          }
        }
      }
    });
  }
  return instructionDetailsMultipleQuestion;
}

fetchSurveyResponse(input) {
  const newInput = Object.assign({ participant_id: this.respondentDetails.participant_id, actual_survey_key: this.respondentDetails.surveyKey, zs_id : this.respondentDetails.zs_id, test_user: this.respondentDetails.test_user }, input);
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.SURVEY_RESPONSE, newInput).subscribe(response => {
    if (response['status'] === 'success') {
      this.surveyPartcipantReponse = response['result'];
      // convert keys to uppercase for comparison
      this.surveyPartcipantReponse = Object.keys(this.surveyPartcipantReponse).reduce((c, k) => (c[k.toUpperCase()] = this.surveyPartcipantReponse[k], c), {});
      this._session.api.local.save('SurveyResponse', this.surveyPartcipantReponse);
    }
  });
}

updateLiveSurveyStatus(status, terminateSectionQuestion ?) {
  const input = {};
  if (this.checkGenericLink === true) {
    input['surveyKey'] = this.gl_surveyKey,
      input['participant_id'] = this.gl_participantId,
      input['status'] = status;
  } else {
    input['surveyKey'] = this.respondentDetails.surveyKey,
      input['zs_id'] = this.respondentDetails.zs_id,
      input['participant_id'] = this.respondentDetails.participant_id,
      input['status'] = status;
  }

  if (terminateSectionQuestion && status == 'Terminated' && !this.isbackToSurvey) {
    input['current_question'] = terminateSectionQuestion.current_question;
    input['current_section_index'] = terminateSectionQuestion.section_index;
  }

  if (this.questionsPreviewService.terminateSurveyImmediately) {
    input['terminate_immediately'] = true;
  }

  if (this.quota_termination && !(this.questionsPreviewService.terminateSurveyImmediately) && !(this.questionsPreviewService.terminateAfter)) {
    input['quota_termination'] = true;
    if (this.checkRedirectURL == true) {
      input['redirectionQuotaURL'] = true;
    }
  }
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.UPDATE_LIVESURVEY_STATUS, input).subscribe(response => {
    if (response['status'] === 'success') {
      if (status === 'Started') {
        this.saveTranslationSettings();
      }
      if (this.checkRedirectURL == true && status === 'Terminated' && !response['result']['quota_termination']) {
        this.surveyStatus = "terminated";
        setTimeout(() => window.location.href = this.redirectURLTerminated,this.timeToRedirect)
      }
      this.termination_message = response.survey_message;
    }
    if (response['status'] === 'quota_termination') {
      this.surveyStatus = "terminated";
      setTimeout(() =>  window.location.href = this.redirectURLQuota,this.timeToRedirect);
      this.termination_message = response.survey_message;

    }
  });
}

getIPAddress() {
  this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
    this.ipAddress = res.ip;
  });
}

createLabelValue(data) {
  return data.result.map((el, i) => {
    return {
      label: el,
      value: el,
      count: i + 1
    };
  });

}

getQuestionIds() {
  const input = {};
  if (this.checkGenericLink == true) {
    input['surveyKey'] = this.gl_surveyKey;
    input['participant_id'] = this.gl_participantId;
  } else {
    input['surveyKey'] = this.respondentDetails.surveyKey,
      input['participant_id'] = this.respondentDetails.participant_id;
  }
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_QUESTION_ID, input).subscribe(response => {
    if (response && response['status'] === 'success') {
      const temp = this.createLabelValue(response);
      this.questionIdList = JSON.parse(JSON.stringify(temp));
    }
  });
}

getRegionDetails() {
  const input = {};
  if (this.checkGenericLink == true) {
    input['surveyKey'] = this.gl_surveyKey
  } else {
    input['surveyKey'] = this.respondentDetails.surveyKey
  }
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_REGION, input).subscribe(response => {
    if (response && response['status'] === 'success') {
      this.regionList = this.createLabelValue(response);
      if (this.regionList.length > 0) {
        this.showRegionDropdown = true;
        this.showRespDetails = false;
      } else {
        this.startSurvey();
      }
    } else {
      this.startSurvey();
    }
  });
}

getLanguageList() {
  this.languageDetails.language = null;
  const input = {};
  input['surveyKey'] = this.respondentDetails.surveyKey,
    input['region'] = this.languageDetails.region,
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_LANGUAGE, input).subscribe(response => {
      if (response && response['status'] === 'success') {
        // this.surveyLanguageList = this.createLabelValue(response);
        this.surveyLanguageList = response.result.map((el, i) => {
          if (this.translateLang && this.translateLang[el]) {
            return {
              label: `${this.translateLang[el].language} (${el})`,
              value: el,
              count: i + 1
            };
          }
        });
      }
    });
}

translateLanguage() {
  this.httpLayer.get(Config.CONFIG_IDENTIFIER.LANGUAGE_JSON).subscribe(response => {
    if (response) {
      this.translateLang = response;
    }
  });
}

validatePassword() {
  const input = {};
  input['surveyKey'] = this.respondentDetails.surveyKey,
    input['participant_id'] = this.respondentDetails.participant_id,
    input['password'] = this.password ? this.password : '',
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.participant_login, input).subscribe(response => {
      if (response && response['status'] === 'success') {
        this.fetchTranslationSettings();
        this.notification.success('', response.result);

      } else {
        this.notification.warning('', response.result);
      }
    });

}

saveTranslationSettings() {
  const input = {};
  if (this.checkGenericLink == true) {
    input['surveyKey'] = this.gl_surveyKey;
    input['participant_id'] = this.gl_participantId;
  } else {
    input['surveyKey'] = this.respondentDetails.surveyKey;
    input['participant_id'] = this.respondentDetails.participant_id;
  }
  input['region'] = this.languageDetails.region ? this.languageDetails.region : '',
    input['language'] = this.languageDetails.language ? this.languageDetails.language : '',
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.save_translation_settings, input).subscribe(response => {
      if (response && response['status'] === 'success') {
      }
    });
}

fetchTranslationSettings() {
  const input = {};
  if (this.checkGenericLink == true) {
    if (this.gl_surveyKey && this.gl_participantId) {
      input['surveyKey'] = this.gl_surveyKey;
      input['participant_id'] = this.gl_participantId
    }
  } else {
    input['surveyKey'] = this.respondentDetails.surveyKey;
    input['participant_id'] = this.respondentDetails.participant_id
  }
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.fetch_translation_settings, input).subscribe(response => {
    if (response && response['status'] === 'success') {
      if (response['result']['language'] === '' || response['result']['region'] === '') {
        this.getRegionDetails();
      } else {
        this.languageDetails = response.result;
        this.startSurvey();
      }
    }
  });
}

completeQuestionLists() {
  let skipAndMove = false;
  this.checkMultipleQuestions();
  const input = {
    surveyKey: this.respondentDetails.surveyKey,
    participant_id: this.respondentDetails.participant_id,
    zs_id: this.respondentDetails.zs_id,
    language: this.languageDetails.language ? this.languageDetails.language : '',
    region: this.languageDetails.region ? this.languageDetails.region : ''
  };
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.completed_question, input).subscribe(response => {
    // this.currentVersionId = response['result'].version_id;
    if (response.result === 'completed') {
      if (this.checkRedirectURL == true) {
        this.updateLiveSurveyStatus('Completed');
        this.surveyStatus = 'completed';
        setTimeout(() => window.location.href = this.redirectURLCompleted,this.timeToRedirect);
      } else {
        this.surveyStatus = 'completed';
        this.updateLiveSurveyStatus('Completed');
      }
    } else if (response.result == 'Terminated') {
      if (this.checkRedirectURL == true) {
        this.updateLiveSurveyStatus('Terminated');
        this.surveyStatus = 'terminated';
        setTimeout(() => window.location.href = this.redirectURLTerminated,this.timeToRedirect);
      } else {
        this.surveyStatus = 'terminated';
        this.updateLiveSurveyStatus('Terminated');
      }
    } else if (response.result == 'Not-Allowed') {
      this.surveyStatus = 'Not-Allowed';
    } else if (response && response['status'] === 'success') {
      this.validateCompleteQuestion = false;
      const result = response['result'];
      this.currentMessageIndex = result['current_message_index'] ? result['current_message_index'] : 0;
      this.questionsPreviewService.setCurrentQuestionDetails(response['result']);
      // current index and name
      this.checkPage = false; /** Single questions always keep false */
      if (Array.isArray(result) == true) {
        this.questionsPreviewService.setIsMultiQuestion(true)
        this.checkMultiReponse = true;
        this.checkquestionDetails = true;
        this.checkPage = (result[0]['question_index'] == 0 && result[0]['section_index'] == 0) ? true : false;
        this.action = this.FETCH_MULTIPLE_QUESTIONS;
        this.questionDetailsList = result;
        this.instructionDetails = [];
        result.forEach((element, i) => {
          // current index and name
          this.current_section_index = element['section_index'];
          this.current_section = element['section_name'];
          this.version_id = element['version_id'];
          if (element.paricipant_action == 'Newly Started') {
            this.updateLiveSurveyStatus('Started');
            this.paricipant_action = element.paricipant_action;
          }
          // this.displayQuestionIndex = element['total_completed_questions'];

          const backToQuestionId = this.questionIdList.filter(el => el.value == element.questionId);
          this.displayQuestionIndex = backToQuestionId[0].count;
          this.current_question = element['questionId'] === "" ? 0 : element['questionId'];
          this.current_question_index = element['question_index'];
          this.total_completed_questions = element['total_completed_questions'];
          this.total_section_questions = element['total_section_questions'];
          this.instructionDetails[element.questionId] = new Map();
          this.questionsPreviewService.currentQuestion = null;
          this.questionsPreviewService.currentQuestionOptions = null;
          this.terminate_current_question = element['questionId'];
          this.terminate_current_section_index = element['question_index'];
          const QuestionTypes = this._session.api.local.get('QuestionTypes');
          QuestionTypes[this.current_question.toUpperCase()] = element['questionType'];
          this._session.api.local.save('QuestionTypes', QuestionTypes);
          this.progressPercentage = (element['total_completed_questions'] / element['total_survey_questions']) * 100;
          this.progressPercentage = Math.floor(this.progressPercentage);

        });
        // if SKIP rule is present then display based on status of rule evaluation
        if (this.questionsPreviewService.skipRulesMap.has(this.current_question.toUpperCase())) {
          const skipQuestion = this.questionsPreviewService.skipRulesMap.get(this.current_question.toUpperCase());
          if (skipQuestion) {
            skipAndMove = true;
            if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
              this.getNextQuestion();
            } else if (this.action === this.PREV_QUESTION) {
              this.getPrevQuestion();
            }
          }
        }


        result.forEach((el, i) => {
          let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(el, i);
          const onloadRules = instructionDetailsMultipleQuestion.get('ONLOAD');
          if (onloadRules) {
            onloadRules.forEach((rule) => {
              if (rule['instdet'].includes('hold_screen')) {
                const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
              }
            });
          }
        });

        if (!skipAndMove) {
          setTimeout(() => {
            this.questionDetailsList = result;
            // update question data piped variables with actual values like - PIPE , SHOW TEXT and LINK
            this.questionDetailsList.forEach((element, i) => {
              this.questionDetailsList[i]['question'] = this.questionsPreviewService.frameQuestion(this.questionDetailsList[i]);

              // save question level rules
              if (element['Instructions'].length > 0) {
                element['Instructions'].forEach((instruction) => {
                  if (instruction.parseStatus && instruction.parseStatus === 'Success') {
                    if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
                      const text = instruction.instruction.indexOf('FIELD')
                      const precisionTest = instruction.instruction.indexOf('PRECISION')

                      if (text > -1 && precisionTest > -1) {
                        instruction.ruleExecutionPhase = "ONLOAD"
                      }
                      const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
                      if (this.instructionDetails[element.questionId]) {
                        if (this.instructionDetails[element.questionId].has(instruction.ruleExecutionPhase)) {
                          // this.instructionDetails.get(instruction.ruleExecutionPhase).push(instruction.parsedInstruction);
                          this.instructionDetails[element.questionId].get(instruction.ruleExecutionPhase).push(parseData);
                        } else {
                          // this.instructionDetails.set(instruction.ruleExecutionPhase, [instruction.parsedInstruction]);
                          this.instructionDetails[element.questionId].set(instruction.ruleExecutionPhase, [parseData]);
                        }
                      }
                    }
                  }
                });
              }

              const optionsData = element['Options'];
              this.currentQuestionType = element["questionType"];
              // this.currentCombinationType = element['combinationTypes'] ? element['combinationTypes'] : '';
              this.currentQuestionId = element["questionId"]

              if (this.currentQuestionType === 'Message_single_type' || this.currentQuestionType === 'Message_drag_and_drop_type') {
                this.isMessageQuestions = true;
                this.fetchMessageQuestions();
              } else {
                this.isMessageQuestions = false;
                // save column level rules -
                optionsData['columns'].forEach((column) => {
                  const parseStatus = column['parseStatus'];
                  if (parseStatus && parseStatus === 'Success') {
                    const inst = column['parsedInstruction'];
                    if (inst) {
                      const execPhase = column['ruleExecutionPhase'];
                      //handle pipe rules  -
                      if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null);
                        if (result) {
                          const header = column['header'];
                          if (header) {
                            column['header'] = header + ' ' + result;
                          } else {
                            column['header'] = result;
                          }
                        }
                      } else {
                        const parseData = { instdet: inst, errmsg: null };
                        if (this.instructionDetails[element.questionId]) {
                          if (this.instructionDetails[element.questionId].has(execPhase)) {
                            // this.instructionDetails.get(execPhase).push(inst);
                            this.instructionDetails[element.questionId].get(execPhase).push(parseData);
                          } else {
                            // this.instructionDetails.set(execPhase, [inst]);
                            this.instructionDetails[element.questionId].set(execPhase, [parseData]);
                          }
                        }
                      }
                    }
                  }
                });

                // save row level rules -
                optionsData['rows'].forEach((row) => {
                  row.forEach((r) => {
                    const parseStatus = r['parseStatus'];
                    if (parseStatus && parseStatus === 'Success') {
                      const inst = r['parsedInstruction'];
                      let label_name;
                      if (r['option_name']) {
                        label_name = r['option_name'];
                      }
                      if (inst) {
                        const execPhase = r['ruleExecutionPhase'];
                        //handle pipe rules  -
                        if (inst.includes('pipe(') || inst.includes('pipe_conditional(') || inst.includes('pipe_rank(') || inst.includes('pipe_name(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null, null, label_name);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                            }
                          }
                        } else if (inst.includes('image(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                            }
                          }

                        } else {
                          const parseData = { instdet: inst, errmsg: null };
                          if (this.instructionDetails[element.questionId]) {
                            if (this.instructionDetails[element.questionId].has(execPhase)) {
                              // this.instructionDetails.get(execPhase).push(inst);
                              this.instructionDetails[element.questionId].get(execPhase).push(parseData);
                            } else {
                              // this.instructionDetails.set(execPhase, [inst]);
                              this.instructionDetails[element.questionId].set(execPhase, [parseData]);
                            }
                          }
                        }
                      }
                    }
                  });
                });
              }

              // console.log(optionsData);
              // set current question and option values in service
              this.questionsPreviewService.currentQuestion = element.questionId;
              this.questionsPreviewService.currentQuestionOptions = optionsData;
              this.questionsPreviewService.questionType = element['questionType'];

              // execute onload rules here
              let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(element, i);
              this.questionsPreviewService.setCurrentQuestionDetails(element);
              const onloadRules = instructionDetailsMultipleQuestion.get('ONLOAD');
              // const onloadRules = this.instructionDetails[element.questionId] ? this.instructionDetails[element.questionId].get('ONLOAD') : false;
              if (onloadRules) {
                const Field_Precision_List = [];
                onloadRules.forEach((rule) => {
                  if (!(rule['instdet'].includes('hold_screen'))) {
                    const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
                    if (response) {
                      if (this.questionsPreviewService.askRulesMap.has(element.questionId.toUpperCase())) {
                        const showQuestion = this.questionsPreviewService.askRulesMap.get(element.questionId.toUpperCase());
                        // if (!showQuestion) {
                        //   skipAndMove = true;
                        //   if (this.action === this.NEXT_QUESTION) {
                        //     this.getNextQuestion();
                        //   } else if (this.action === this.PREV_QUESTION) {
                        //     this.getPrevQuestion();
                        //   }
                        // }
                      }
                      if (response.type === 'Field_precision') {
                        Field_Precision_List.push(response);
                      }
                    }
                  }
                });
                if (Field_Precision_List.length > 0) {
                  this._session.api.local.save('Field_precision', Field_Precision_List);
                }
              }
            });
          }, 100);
          let QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
          QuestionNumberMap = QuestionNumberMap ? QuestionNumberMap : {};

          QuestionNumberMap[this.current_question.toUpperCase()] = this.displayQuestionIndex;
          this._session.api.local.save('QuestionNumberMap', QuestionNumberMap);
        }

        // if SKIPTO rule is present then navigate to the specified question
        if (this.questionsPreviewService.skipToRulePresent) {
          this.required_question_id = this.questionsPreviewService.skipToQuestion;
          // reset status
          this.questionsPreviewService.skipToRulePresent = false;
          this.questionsPreviewService.skipToQuestion = null;

          // skipAndMove = true;
          // this.getSkiptoQuestion();
        }
        this._session.api.local.save('QuestionNumberMap', this.QuestionNumberMapMultiple);
      } else {
        this.questionsPreviewService.setIsMultiQuestion(false)
        this.current_section_index = result['section_index'];
        this.current_section = result['section_name']
        if (result.paricipant_action == 'Newly Started') {
          this.updateLiveSurveyStatus('Started');
          this.paricipant_action = result.paricipant_action;
        }
        this.questionDetails = result;
        this.current_question = result['questionId'];
        this.current_question_index = result['question_index'];
        this.total_completed_questions = result['total_completed_questions'];
        this.total_section_questions = result['total_section_questions'];
        this.displayQuestionIndex = result.total_completed_questions;
        this.questionDetails = null;
        this.instructionDetails = new Map();
        this.questionsPreviewService.currentQuestion = null;
        this.questionsPreviewService.currentQuestionOptions = null;
        this.questionsPreviewService.onScreenDisable = '';


        this.current_question = result['questionId'];
        this.current_question_index = result['question_index'];

        this.terminate_current_question = result['questionId'];
        this.terminate_current_section_index = result['question_index'];

        const QuestionTypes = this._session.api.local.get('QuestionTypes');
        QuestionTypes[this.current_question.toUpperCase()] = result['questionType'];
        this._session.api.local.save('QuestionTypes', QuestionTypes);

        // if ASK rule is present then display based on status of rule evaluation
        // if (this.questionsPreviewService.askRulesMap.has(this.current_question.toUpperCase())) {
        //   console.log(this.questionsPreviewService.askRulesMap);
        //   const showQuestion = this.questionsPreviewService.askRulesMap.get(this.current_question.toUpperCase());
        //   console.log('ASK rule status for question - ' + this.current_question + ', status - ' + showQuestion + ', action - ' + this.action);
        //   if (!showQuestion) {
        //     skipAndMove = true;
        //     if (this.action === this.NEXT_QUESTION) {
        //       this.getNextQuestion();
        //     } else if (this.action === this.PREV_QUESTION) {
        //       this.getPrevQuestion();
        //     }
        //   }
        // }

        // if SKIP rule is present then display based on status of rule evaluation
        if (this.questionsPreviewService.skipRulesMap.has(this.current_question.toUpperCase())) {
          const skipQuestion = this.questionsPreviewService.skipRulesMap.get(this.current_question.toUpperCase());
          if (skipQuestion) {
            skipAndMove = true;
            if (this.action === this.NEXT_QUESTION) {
              this.getNextQuestion();
            } else if (this.action === this.PREV_QUESTION) {
              this.getPrevQuestion();
            }
          }
        }

        // if SKIPTO rule is present then navigate to the specified question
        if (this.questionsPreviewService.skipToRulePresent) {
          this.required_question_id = this.questionsPreviewService.skipToQuestion;
          // reset status
          this.questionsPreviewService.skipToRulePresent = false;
          this.questionsPreviewService.skipToQuestion = null;

          // skipAndMove = true;
          // this.getSkiptoQuestion();
        }

        if (!skipAndMove) {
          setTimeout(() => {
            this.questionDetails = result;
            // update question data piped variables with actual values like - PIPE , SHOW TEXT and LINK
            this.questionDetails['question'] = this.questionsPreviewService.frameQuestion(this.questionDetails);

            // save question level rules
            if (result['Instructions'].length > 0) {
              result['Instructions'].forEach((instruction) => {
                if (instruction.parseStatus && instruction.parseStatus === 'Success') {
                  if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
                    const text = instruction.instruction.indexOf('FIELD')
                    const precisionTest = instruction.instruction.indexOf('PRECISION')
                    if (text > -1 && precisionTest > -1) {
                      instruction.ruleExecutionPhase = "ONLOAD"
                    }
                    const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
                    if (this.instructionDetails.has(instruction.ruleExecutionPhase)) {
                      // this.instructionDetails.get(instruction.ruleExecutionPhase).push(instruction.parsedInstruction);
                      this.instructionDetails.get(instruction.ruleExecutionPhase).push(parseData);
                    } else {
                      // this.instructionDetails.set(instruction.ruleExecutionPhase, [instruction.parsedInstruction]);
                      this.instructionDetails.set(instruction.ruleExecutionPhase, [parseData]);
                    }
                  }
                }
              });
            }

            const optionsData = this.questionDetails['Options'];
            this.currentQuestionType = this.questionDetails["questionType"];
            // this.currentCombinationType = this.questionDetails['combinationTypes'] ? this.questionDetails['combinationTypes'] : '';
            this.currentQuestionId = this.questionDetails["questionId"]

            if (this.currentQuestionType === 'Message_single_type' || this.currentQuestionType === 'Message_drag_and_drop_type') {
              this.isMessageQuestions = true;
              this.fetchMessageQuestions();
            } else {
              this.isMessageQuestions = false;
              // save column level rules -
              optionsData['columns'].forEach((column) => {
                const parseStatus = column['parseStatus'];
                if (parseStatus && parseStatus === 'Success') {
                  const inst = column['parsedInstruction'];
                  if (inst) {
                    const execPhase = column['ruleExecutionPhase'];
                    //handle pipe rules  -
                    if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                      const result = this.questionsPreviewService.ruleParser(inst, null);
                      if (result) {
                        const header = column['header'];
                        if (header) {
                          column['header'] = header + ' ' + result;
                        } else {
                          column['header'] = result;
                        }
                      }
                    } else {
                      const parseData = { instdet: inst, errmsg: null };
                      if (this.instructionDetails.has(execPhase)) {
                        // this.instructionDetails.get(execPhase).push(inst);
                        this.instructionDetails.get(execPhase).push(parseData);
                      } else {
                        // this.instructionDetails.set(execPhase, [inst]);
                        this.instructionDetails.set(execPhase, [parseData]);
                      }
                    }
                  }
                }
              });

              // save row level rules -
              optionsData['rows'].forEach((row) => {
                row.forEach((r) => {
                  const parseStatus = r['parseStatus'];
                  if (parseStatus && parseStatus === 'Success') {
                    const inst = r['parsedInstruction'];
                    let label_name;
                    if (r['option_name']) {
                      label_name = r['option_name'];
                    }
                    if (inst) {
                      const execPhase = r['ruleExecutionPhase'];
                      //handle pipe rules  -
                      if (inst.includes('pipe(') || inst.includes('pipe_conditional(') || inst.includes('pipe_rank(') || inst.includes('pipe_name(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null,null,label_name);
                        if (result) {
                          const valueType = r['valueType'];
                          if (valueType) {
                            if (valueType === 'Label') {
                              r['value'] = r['value'] + ' ' + result;
                            }
                          }
                        }
                      } else if (inst.includes('image(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null);
                        if (result) {
                          const valueType = r['valueType'];
                          if (valueType) {
                            if (valueType === 'Label') {
                              r['value'] = r['value'] + ' ' + result;
                            }
                          }
                        }

                      } else {
                        const parseData = { instdet: inst, errmsg: null };
                        if (this.instructionDetails.has(execPhase)) {
                          // this.instructionDetails.get(execPhase).push(inst);
                          this.instructionDetails.get(execPhase).push(parseData);
                        } else {
                          // this.instructionDetails.set(execPhase, [inst]);
                          this.instructionDetails.set(execPhase, [parseData]);
                        }
                      }
                    }
                  }
                });
              });
            }

            // console.log(optionsData);
            // set current question and option values in service
            this.questionsPreviewService.currentQuestion = this.current_question;
            this.questionsPreviewService.currentQuestionOptions = optionsData;
            this.questionsPreviewService.questionType = this.questionDetails['questionType'];

            // execute onload rules here
            const onloadRules = this.instructionDetails.get('ONLOAD');
            if (onloadRules) {
              const Field_Precision_List = [];
              onloadRules.forEach((rule) => {
                const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
                if (response) {
                  if (this.questionsPreviewService.askRulesMap.has(this.current_question.toUpperCase())) {
                    const showQuestion = this.questionsPreviewService.askRulesMap.get(this.current_question.toUpperCase());
                  }
                  if (response.type === 'Field_precision') {
                    Field_Precision_List.push(response);
                  }
                }
              });
              if (Field_Precision_List.length > 0) {
                this._session.api.local.save('Field_precision', Field_Precision_List);
              }
            }
          }, 100);

          // if (this.action === this.NEXT_QUESTION) {
          //   this.displayQuestionIndex++;
          // } else if (this.action !== 'JumpTo') {
          //   this.displayQuestionIndex--;
          // }
          if (this.action === this.NEXT_QUESTION) {
            if (this.currentQuestionType === 'Message_drag_and_drop_type' || this.currentQuestionType === 'Message_single_type') {
              this.displayQuestionIndex = this.currentMessageIndex === 0 ? this.displayQuestionIndex + 1 : this.displayQuestionIndex;
            } else {
              this.displayQuestionIndex++;
            }
          } else if (this.action !== 'JumpTo') {
            this.displayQuestionIndex--;
          }

          let QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
          QuestionNumberMap = QuestionNumberMap ? QuestionNumberMap : {};
          QuestionNumberMap[this.current_question.toUpperCase()] = this.displayQuestionIndex;
          this._session.api.local.save('QuestionNumberMap', QuestionNumberMap);
        }
        this.progressPercentage = (result['total_completed_questions'] / result['total_survey_questions']) * 100;
        this.progressPercentage = Math.floor(this.progressPercentage);
      }
    }
  });

}

checkMultipleQuestions() {
  const input = {};
  if (this.checkGenericLink == true) {
    if (this.gl_surveyKey && this.gl_participantId) {
      input['surveyKey'] = this.gl_surveyKey;
      input['participant_id'] = this.gl_participantId
    }
  } else {
    input['surveyKey'] = this.respondentDetails.surveyKey;
    input['participant_id'] = this.respondentDetails.participant_id
  }
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_PAGE_DETAILS, input).subscribe(response => {
    this._session.api.local.save(Config.CONSTANTS.MULTIPLE_QUESTIONS, response.result);
  })
  this.fetchMultiple = this._session.api.local.get(Config.CONSTANTS.MULTIPLE_QUESTIONS);
}

highlightErrorFields(field_data, questionDetails) {
  field_data.forEach((row, rowInd) => {
    if (questionDetails['questionType'] == 'Number' || questionDetails['questionType'] == 'Percent') {
      const col = row['columnId'];
      let colIdx = -1;

      questionDetails['Options']['columns'].find(function (c, i) {
        if (c['columnId'] === col) {
          colIdx = i;
          return i;
        }
      });

      if (colIdx > -1) {
        questionDetails['Options']['rows'].forEach((r) => {
          const roVal = r[0]['value'];
          const colRow = r[colIdx];
          if (row['fieldId'] === roVal && row['columnId'] == col) {
            colRow['invalid'] = true;
          }
        });
      }
    }
  });
}

/**
 * After terminated if required to back
 */
backTotest() {
  this.isbackToSurvey = true;
  this.action = this.FETCH_QUESTION_WITH_QUESTIONID;
  this.questionsPreviewService.setTerminationSurveyImmediately(false);
  this.questionsPreviewService.setTerminationSurvey(false);
  this.surveyStatus = '';
  this.currentMessageIndex = 0;
  this.validateCompleteQuestion = false;
  this.fetchData();
}

getQuestionTypeList() {
  const input = {};
  this.checkGenericLink == true ? input['surveyKey'] = this.gl_surveyKey : input['surveyKey'] = this.respondentDetails.surveyKey,
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.fetchQuestionListType, input).subscribe(response => {
      if (response && response['status'] === 'success') {
        this._session.api.local.save(Config.CONSTANTS.QUESTIONTYPELIST, response.result);
      }
    });
}

fetchGenericSurvey() {
  let skipAndMove = false;
  this.checkMultipleQuestions();
  const input = {
    surveyKey: this.gl_surveyKey,
    participant_id: this.gl_participantId,
    language: this.languageDetails.language ? this.languageDetails.language : '',
    region: this.languageDetails.region ? this.languageDetails.region : ''
  };
  this.httpLayer.post(Config.SERVICE_IDENTIFIER.FETCH_GENERIC_SURVEY, input).subscribe(response => {
    if (response.result === 'completed') {
      this.surveyStatus = 'completed';
      this.updateLiveSurveyStatus('Completed');
    } else if (response.result == 'Terminated') {
      this.surveyStatus = 'terminated';
      this.updateLiveSurveyStatus('Terminated');
    } else if (response && response['status'] === 'success') {
      const result = response['result'];
      this.questionsPreviewService.setCurrentQuestionDetails(response['result']);
      this.validateCompleteQuestion = false;
      // current index and name

      if (result.paricipant_action == 'Newly Started') {
        this.updateLiveSurveyStatus('Started');
        this.paricipant_action = result.paricipant_action;
      }
      if (Array.isArray(result) == true) {
        this.checkquestionDetails = true;
        this.checkPage = (result[0]['question_index'] == 0 && result[0]['section_index'] == 0) ? true : false;
        this.action = this.FETCH_MULTIPLE_QUESTIONS;
        this.questionDetailsList = result;
        this.instructionDetails = [];
        result.forEach((element, i) => {
          // current index and name
          this.current_section_index = element['section_index'];
          this.current_section = element['section_name'];
          this.version_id = element['version_id'];
          this.questionsPreviewService.setCurrentQuestionDetails(element);
          // this.displayQuestionIndex = element['total_completed_questions'];
          const backToQuestionId = this.questionIdList.filter(el => el.value == element.questionId);
          this.displayQuestionIndex = backToQuestionId[0].count;
          this.current_question = element['questionId'] === "" ? 0 : element['questionId'];
          this.current_question_index = element['question_index'];
          this.total_completed_questions = element['total_completed_questions'];
          this.instructionDetails[element.questionId] = new Map();
          this.questionsPreviewService.currentQuestion = null;
          this.questionsPreviewService.currentQuestionOptions = null;
          this.terminate_current_question = element['questionId'];
          this.terminate_current_section_index = element['question_index'];
          const QuestionTypes = this._session.api.local.get('QuestionTypes');
          QuestionTypes[this.current_question.toUpperCase()] = element['questionType'];
          this._session.api.local.save('QuestionTypes', QuestionTypes);
          this.progressPercentage = (element['total_completed_questions'] / element['total_survey_questions']) * 100;
          this.progressPercentage = Math.floor(this.progressPercentage);

        })

        // if SKIP rule is present then display based on status of rule evaluation
        if (this.questionsPreviewService.skipRulesMap.has(this.current_question.toUpperCase())) {
          const skipQuestion = this.questionsPreviewService.skipRulesMap.get(this.current_question.toUpperCase());
          if (skipQuestion) {
            skipAndMove = true;
            if (this.action === this.FETCH_MULTIPLE_QUESTIONS || this.checkMultiReponse === true) {
              this.getNextQuestion();
            } else if (this.action === this.PREV_QUESTION) {
              this.getPrevQuestion();
            }
          }
        }

        result.forEach((el, i) => {
          let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(el, i);
          const onloadRules = instructionDetailsMultipleQuestion.get('ONLOAD');
          if (onloadRules) {
            onloadRules.forEach((rule) => {
              if (rule['instdet'].includes('hold_screen')) {
                const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
              }
            });
          }
        });

        if (!skipAndMove) {
          setTimeout(() => {
            this.questionDetailsList = result;
            // update question data piped variables with actual values like - PIPE , SHOW TEXT and LINK
            this.questionDetailsList.forEach((element, i) => {
              this.questionDetailsList[i]['question'] = this.questionsPreviewService.frameQuestion(this.questionDetailsList[i]);

              // save question level rules
              if (element['Instructions'].length > 0) {
                element['Instructions'].forEach((instruction) => {
                  if (instruction.parseStatus && instruction.parseStatus === 'Success') {
                    if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
                      const text = instruction.instruction.indexOf('FIELD')
                      const precisionTest = instruction.instruction.indexOf('PRECISION')

                      if (text > -1 && precisionTest > -1) {
                        instruction.ruleExecutionPhase = "ONLOAD"
                      }
                      const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
                      if (this.instructionDetails[element.questionId]) {
                        if (this.instructionDetails[element.questionId].has(instruction.ruleExecutionPhase)) {
                          // this.instructionDetails.get(instruction.ruleExecutionPhase).push(instruction.parsedInstruction);
                          this.instructionDetails[element.questionId].get(instruction.ruleExecutionPhase).push(parseData);
                        } else {
                          // this.instructionDetails.set(instruction.ruleExecutionPhase, [instruction.parsedInstruction]);
                          this.instructionDetails[element.questionId].set(instruction.ruleExecutionPhase, [parseData]);
                        }
                      }
                    }
                  }
                });
              }

              const optionsData = element['Options'];
              this.currentQuestionType = element["questionType"];
              // this.currentCombinationType = element['combinationTypes'] ? element['combinationTypes'] : '';
              this.currentQuestionId = element["questionId"]

              if (this.currentQuestionType === 'Message_single_type' || this.currentQuestionType === 'Message_drag_and_drop_type') {
                this.isMessageQuestions = true;
                this.fetchMessageQuestions();
              } else {
                this.isMessageQuestions = false;
                // save column level rules -
                optionsData['columns'].forEach((column) => {
                  const parseStatus = column['parseStatus'];
                  if (parseStatus && parseStatus === 'Success') {
                    const inst = column['parsedInstruction'];
                    if (inst) {
                      const execPhase = column['ruleExecutionPhase'];
                      //handle pipe rules  -
                      if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null);
                        if (result) {
                          const header = column['header'];
                          if (header) {
                            column['header'] = header + ' ' + result;
                          } else {
                            column['header'] = result;
                          }
                        }
                      } else {
                        const parseData = { instdet: inst, errmsg: null };
                        if (this.instructionDetails[element.questionId]) {
                          if (this.instructionDetails[element.questionId].has(execPhase)) {
                            // this.instructionDetails.get(execPhase).push(inst);
                            this.instructionDetails[element.questionId].get(execPhase).push(parseData);
                          } else {
                            // this.instructionDetails.set(execPhase, [inst]);
                            this.instructionDetails[element.questionId].set(execPhase, [parseData]);
                          }
                        }
                      }
                    }
                  }
                });

                // save row level rules -
                optionsData['rows'].forEach((row) => {
                  row.forEach((r) => {
                    const parseStatus = r['parseStatus'];
                    if (parseStatus && parseStatus === 'Success') {
                      const inst = r['parsedInstruction'];
                      let label_name;
                      if (r['option_name']) {
                        label_name = r['option_name'];
                      }
                      if (inst) {
                        const execPhase = r['ruleExecutionPhase'];
                        //handle pipe rules  -
                        if (inst.includes('pipe(') || inst.includes('pipe_conditional(') || inst.includes('pipe_rank(') || inst.includes('pipe_name(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null,null,label_name);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                            }
                          }
                        } else if (inst.includes('image(')) {
                          const result = this.questionsPreviewService.ruleParser(inst, null);
                          if (result) {
                            const valueType = r['valueType'];
                            if (valueType) {
                              if (valueType === 'Label') {
                                r['value'] = r['value'] + ' ' + result;
                              }
                            }
                          }

                        } else {
                          const parseData = { instdet: inst, errmsg: null };
                          if (this.instructionDetails[element.questionId]) {
                            if (this.instructionDetails[element.questionId].has(execPhase)) {
                              // this.instructionDetails.get(execPhase).push(inst);
                              this.instructionDetails[element.questionId].get(execPhase).push(parseData);
                            } else {
                              // this.instructionDetails.set(execPhase, [inst]);
                              this.instructionDetails[element.questionId].set(execPhase, [parseData]);
                            }
                          }
                        }
                      }
                    }
                  });
                });
              }

              // console.log(optionsData);
              // set current question and option values in service
              this.questionsPreviewService.currentQuestion = element.questionId;
              this.questionsPreviewService.currentQuestionOptions = optionsData;
              this.questionsPreviewService.questionType = element['questionType'];

              // execute onload rules here
              let instructionDetailsMultipleQuestion = this.multipleQuestionInstructionDetail(element, i);
              const onloadRules = instructionDetailsMultipleQuestion.get('ONLOAD');
              // const onloadRules = this.instructionDetails[element.questionId] ? this.instructionDetails[element.questionId].get('ONLOAD') : false;
              if (onloadRules) {
                const Field_Precision_List = [];
                onloadRules.forEach((rule) => {
                  if (!(rule['instdet'].includes('hold_screen'))) {
                    const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
                    if (response) {
                      if (this.questionsPreviewService.askRulesMap.has(element.questionId.toUpperCase())) {
                        const showQuestion = this.questionsPreviewService.askRulesMap.get(element.questionId.toUpperCase());
                        // if (!showQuestion) {
                        //   skipAndMove = true;
                        //   if (this.action === this.NEXT_QUESTION) {
                        //     this.getNextQuestion();
                        //   } else if (this.action === this.PREV_QUESTION) {
                        //     this.getPrevQuestion();
                        //   }
                        // }
                      }
                      if (response.type === 'Field_precision') {
                        Field_Precision_List.push(response);
                      }
                    }
                  }
                });
                if (Field_Precision_List.length > 0) {
                  this._session.api.local.save('Field_precision', Field_Precision_List);
                }
              }
            });
          }, 100);

          // if (this.action === this.NEXT_QUESTION) {
          //   this.displayQuestionIndex++;
          // } else if (this.action !== 'JumpTo') {
          //   this.displayQuestionIndex--;
          // }
          // if (this.action === this.FETCH_MULTIPLE_QUESTIONS) {
          //   this.displayQuestionIndex = result.length;
          // }

          const QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
          QuestionNumberMap[this.current_question.toUpperCase()] = this.displayQuestionIndex;
          this._session.api.local.save('QuestionNumberMap', QuestionNumberMap);
        }

        // if SKIPTO rule is present then navigate to the specified question
        if (this.questionsPreviewService.skipToRulePresent) {
          this.required_question_id = this.questionsPreviewService.skipToQuestion;
          // reset status
          this.questionsPreviewService.skipToRulePresent = false;
          this.questionsPreviewService.skipToQuestion = null;

          // skipAndMove = true;
          // this.getSkiptoQuestion();
        }
        this._session.api.local.save('QuestionNumberMap', this.QuestionNumberMapMultiple);
      } else {
        this.current_section_index = result['section_index'];
        this.current_section = result['section_name'];
        this.questionDetails = result;
        this.current_question = result['questionId'];
        this.current_question_index = result['question_index'];
        this.total_completed_questions = result['total_completed_questions'];
        this.displayQuestionIndex = result.total_completed_questions;
        this.questionDetails = null;
        this.instructionDetails = new Map();
        this.questionsPreviewService.currentQuestion = null;
        this.questionsPreviewService.currentQuestionOptions = null;
        this.questionsPreviewService.onScreenDisable = '';
        this.currentVersionId = result['version_id'];


        this.current_question = result['questionId'];
        this.current_question_index = result['question_index'];

        this.terminate_current_question = result['questionId'];
        this.terminate_current_section_index = result['question_index'];

        const QuestionTypes = this._session.api.local.get('QuestionTypes');
        QuestionTypes[this.current_question.toUpperCase()] = result['questionType'];
        this._session.api.local.save('QuestionTypes', QuestionTypes);

        // if SKIP rule is present then display based on status of rule evaluation
        if (this.questionsPreviewService.skipRulesMap.has(this.current_question.toUpperCase())) {
          console.log(this.questionsPreviewService.skipRulesMap);
          const skipQuestion = this.questionsPreviewService.skipRulesMap.get(this.current_question.toUpperCase());
          console.log('SKIP rule status for question - ' + this.current_question + ', status - ' + skipQuestion + ', action - ' + this.action);
          if (skipQuestion) {
            skipAndMove = true;
            if (this.action === this.NEXT_QUESTION) {
              this.getNextQuestion();
            } else if (this.action === this.PREV_QUESTION) {
              this.getPrevQuestion();
            }
          }
        }

        // if SKIPTO rule is present then navigate to the specified question
        if (this.questionsPreviewService.skipToRulePresent) {
          this.required_question_id = this.questionsPreviewService.skipToQuestion;
          // reset status
          this.questionsPreviewService.skipToRulePresent = false;
          this.questionsPreviewService.skipToQuestion = null;

          console.log('SKIPTO rule - move to question - ' + this.required_question_id);
          // skipAndMove = true;
          // this.getSkiptoQuestion();
        }

        console.log('skipAndMove - ' + skipAndMove);
        if (!skipAndMove) {
          setTimeout(() => {
            this.questionDetails = result;
            // update question data piped variables with actual values like - PIPE , SHOW TEXT and LINK
            this.questionDetails['question'] = this.questionsPreviewService.frameQuestion(this.questionDetails);

            // save question level rules
            console.log('Instructions length: ' + result['Instructions'].length);
            if (result['Instructions'].length > 0) {
              result['Instructions'].forEach((instruction) => {
                if (instruction.parseStatus && instruction.parseStatus === 'Success') {
                  if (instruction.parsedInstruction && instruction.ruleExecutionPhase) {
                    const text = instruction.instruction.indexOf('FIELD')
                    const precisionTest = instruction.instruction.indexOf('PRECISION')

                    if (text > -1 && precisionTest > -1) {
                      instruction.ruleExecutionPhase = "ONLOAD"
                    }
                    console.log('Question Level - ruleExecutionPhase: ' + instruction.ruleExecutionPhase
                      + ', parsedInstruction: ' + instruction.parsedInstruction);
                    const parseData = { instdet: instruction.parsedInstruction, errmsg: instruction.parseError };
                    if (this.instructionDetails.has(instruction.ruleExecutionPhase)) {
                      // this.instructionDetails.get(instruction.ruleExecutionPhase).push(instruction.parsedInstruction);
                      this.instructionDetails.get(instruction.ruleExecutionPhase).push(parseData);
                    } else {
                      // this.instructionDetails.set(instruction.ruleExecutionPhase, [instruction.parsedInstruction]);
                      this.instructionDetails.set(instruction.ruleExecutionPhase, [parseData]);
                    }
                  }
                }
              });
            }

            const optionsData = this.questionDetails['Options'];
            this.currentQuestionType = this.questionDetails["questionType"];
            // this.currentCombinationType = this.questionDetails['combinationTypes'] ? this.questionDetails['combinationTypes'] : '';
       this.currentQuestionId = this.questionDetails["questionId"]

            if (this.currentQuestionType === 'Message_single_type' || this.currentQuestionType === 'Message_drag_and_drop_type') {
              this.isMessageQuestions = true;
              this.fetchMessageQuestions();
            } else {
              this.isMessageQuestions = false;
              // save column level rules -
              optionsData['columns'].forEach((column) => {
                const parseStatus = column['parseStatus'];
                if (parseStatus && parseStatus === 'Success') {
                  const inst = column['parsedInstruction'];
                  if (inst) {
                    const execPhase = column['ruleExecutionPhase'];
                    console.log('Column Level - ruleExecutionPhase: ' + execPhase + ', parsedInstruction: ' + inst);
                    //handle pipe rules  -
                    if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                      const result = this.questionsPreviewService.ruleParser(inst, null);
                      if (result) {
                        const header = column['header'];
                        if (header) {
                          column['header'] = header + ' ' + result;
                        } else {
                          column['header'] = result;
                        }
                      }
                    } else {
                      const parseData = { instdet: inst, errmsg: null };
                      if (this.instructionDetails.has(execPhase)) {
                        // this.instructionDetails.get(execPhase).push(inst);
                        this.instructionDetails.get(execPhase).push(parseData);
                      } else {
                        // this.instructionDetails.set(execPhase, [inst]);
                        this.instructionDetails.set(execPhase, [parseData]);
                      }
                    }
                  }
                }
              });

              // save row level rules -
              optionsData['rows'].forEach((row) => {
                row.forEach((r) => {
                  const parseStatus = r['parseStatus'];
                  if (parseStatus && parseStatus === 'Success') {
                    const inst = r['parsedInstruction'];
                    if (inst) {
                      const execPhase = r['ruleExecutionPhase'];
                      console.log('Row Level - ruleExecutionPhase: ' + execPhase + ', parsedInstruction: ' + inst);
                      //handle pipe rules  -
                      if (inst.includes('pipe(') || inst.includes('pipe_conditional(')) {
                        const result = this.questionsPreviewService.ruleParser(inst, null);
                        if (result) {
                          const valueType = r['valueType'];
                          if (valueType) {
                            console.log('Row Level - result: ' + result + ', valueType: ' + valueType);
                            if (valueType === 'Label') {
                              r['value'] = r['value'] + ' ' + result;
                            }
                            console.log('Row Level - value: ' + r['value'] + ', number: ' + r['number'] + ', percent: ' + r['percent']);
                          }
                        }
                      } else {
                        const parseData = { instdet: inst, errmsg: null };
                        if (this.instructionDetails.has(execPhase)) {
                          // this.instructionDetails.get(execPhase).push(inst);
                          this.instructionDetails.get(execPhase).push(parseData);
                        } else {
                          // this.instructionDetails.set(execPhase, [inst]);
                          this.instructionDetails.set(execPhase, [parseData]);
                        }
                      }
                    }
                  }
                });
              });
            }

            // console.log(optionsData);
            // set current question and option values in service
            this.questionsPreviewService.currentQuestion = this.current_question;
            this.questionsPreviewService.currentQuestionOptions = optionsData;
            this.questionsPreviewService.questionType = this.questionDetails['questionType'];

            // execute onload rules here
            const onloadRules = this.instructionDetails.get('ONLOAD');
            if (onloadRules) {
              console.log('onloadRules: ' + onloadRules);
              const Field_Precision_List = [];
              onloadRules.forEach((rule) => {
                const response = this.questionsPreviewService.ruleParser(rule['instdet'], rule['errmsg']);
                if (response) {
                  if (this.questionsPreviewService.askRulesMap.has(this.current_question.toUpperCase())) {
                    console.log(this.questionsPreviewService.askRulesMap);
                    const showQuestion = this.questionsPreviewService.askRulesMap.get(this.current_question.toUpperCase());
                    console.log('ASK rule status for question - ' + this.current_question + ', status - ' + showQuestion + ', action - ' + this.action);
                    // if (!showQuestion) {
                    //   skipAndMove = true;
                    //   if (this.action === this.NEXT_QUESTION) {
                    //     this.getNextQuestion();
                    //   } else if (this.action === this.PREV_QUESTION) {
                    //     this.getPrevQuestion();
                    //   }
                    // }
                  }
                  if (response.type === 'Field_precision') {
                    Field_Precision_List.push(response);
                  }
                }
              });
              if (Field_Precision_List.length > 0) {
                this._session.api.local.save('Field_precision', Field_Precision_List);
              }
            }
          }, 100);

          // if (this.action === this.NEXT_QUESTION) {
          //   this.displayQuestionIndex++;
          // } else if (this.action !== 'JumpTo') {
          //   this.displayQuestionIndex--;
          // }
          if (this.action === this.NEXT_QUESTION) {
            if (this.currentQuestionType === 'Message_drag_and_drop_type' || this.currentQuestionType === 'Message_single_type') {
              this.displayQuestionIndex = this.currentMessageIndex === 0 ? this.displayQuestionIndex + 1 : this.displayQuestionIndex;
            } else {
              this.displayQuestionIndex++;
            }
          } else if (this.action !== 'JumpTo') {
            this.displayQuestionIndex--;
          }

          const QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
          QuestionNumberMap[this.current_question.toUpperCase()] = this.displayQuestionIndex;
          this._session.api.local.save('QuestionNumberMap', QuestionNumberMap);
        }
        this.progressPercentage = (result['total_completed_questions'] / result['total_survey_questions']) * 100;
        this.progressPercentage = Math.floor(this.progressPercentage);
      }
    }
  });
}

}

