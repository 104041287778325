import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string, format: string): any {
    let date: Date, newDate: string;
    date = new Date(value);
    switch (format) {
      case 'DD/MM':
        newDate = this.dateToString(date.getDate()) + '/' + this.transformMonth(date.getMonth());
        break;
      case 'MM/DD':
        newDate = this.transformMonth(date.getMonth()) + '/' + this.dateToString(date.getDate());
        break;
      case 'DD/MM/YYYY':
        newDate = this.dateToString(date.getDate()) + '/' + this.transformMonth(date.getMonth()) + '/' + date.getFullYear();
        break;
      case 'MM/DD/YYYY':
        newDate = this.transformMonth(date.getMonth()) + '/' + this.dateToString(date.getDate()) + '/' + date.getFullYear();
        break;
      case 'YYYY/MM/DD':
        newDate = date.getFullYear() + '/' + this.transformMonth(date.getMonth()) + '/' + this.dateToString(date.getDate());
        break;
      default:
        newDate = this.dateToString(date.getDate()) + '/' + this.transformMonth(date.getMonth()) + '/' + date.getFullYear();
        break;
    }
    return newDate;
  }

  /**
   * Transform month index (getting from Date.getMonth) to 2 digit month (03 for March)
   * @param month month index
   */
  transformMonth(month: number): string {
    let newMonth = '';
    month++;
    if (month < 10) {
      newMonth = '0' + month;
    } else {
      newMonth = month.toString();
    }
    return newMonth;
  }

  /**
   * Convert date number to 2 digit date (eg. 1 -> 01)
   * @param date date value
   */
  dateToString(date: number): string {
    let newDate: string;
    if (date < 10) {
      newDate = '0' + date;
    } else {
      newDate = date.toString();
    }
    return newDate;
  }

}
