import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.css']
})
export class MessagePopupComponent implements OnInit {
  @Input() questionDetails: any;
  @Input() isLiveMessage: boolean;
  @Input() messageQuestions: any;
  @Input() popUpContent: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  @Output('isOkOrCancel') isOkOrCancel=new EventEmitter();
  public todo        = [];
  public done        = [];
  public todoMessage = [];
  public response    = [];
  messageSet         = [];
  bestOrder          = true;
  doneLength;

  constructor() {}

  ngOnInit() {
    if(this.isLiveMessage){
      if (this.messageQuestions && this.messageQuestions.data.length > 0 && this.popUpContent.length > 0 ) {
        this.messageSet  = this.messageQuestions.data;
        this.todoMessage = [];
        this.done        = [];
        let doneArray    = [];
        this.doneLength  = this.messageSet[0].length;
        let orderedData  = this.popUpContent;
        this.messageSet[0].forEach((item, index) => { this.todoMessage.push([this.messageSet[0][index]]) });
        this.messageSet[1].forEach((item, index) => { this.todoMessage.push([this.messageSet[1][index]]) });
        orderedData.forEach((item) => {
          this.todoMessage.forEach((todoItem) => {
            if (item === todoItem[0].messageID) {
              doneArray.push([todoItem[0]])
            }
          })
        });
        this.done = Object.assign([], doneArray);
        this.prepareRespData();
      }
    }
  }

  /**
   * Trigger when opt 'No' [ Messages not in best order ]
   */
  changeOrder() {
    this.bestOrder = false;
  }

  /**
   * Trigger when opt 'Yes' [ Messages in best order ]
   */
  isBestOrder() {
    this.prepareRespData();
    this.isOkOrCancel.emit(true);
  }

  /**
   * Triggered on drop of an option
   * @param event dropped event
   */
   dropPreferredStory(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if (event.container.data.length < this.doneLength) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.prepareRespData();
  }

  /**
   * Emit back the response to live survey to get saved
   */
   dataEmitter() {
    const data = {"res": this.response, "id": this.questionDetails.questionId}
    this.emitData.emit(data);
  }

  /**
   * To create response json
   */
   prepareRespData() {
    this.response     = [];
    let selectedValue = [];
    // Selected values are sending as a list to prevent auto sorting
    selectedValue = this.done.map(x => {
      let a = {};
      a[x[0].messageKey] = x[0].messageID;
      return a
    })
    this.response.push({
       selected_value: selectedValue,
       selected_item: "First",
       selected_row: "", 
       option_type: "Message_drag_and_drop_type",
       selected_header: "MESSAGE SET",
      })
    this.dataEmitter();
  }
}